
import { Component, Vue } from 'vue-property-decorator'
import PartouCheckBox from '@/components/PartouComponents/Input/PartouCheckBox/PartouCheckBox.vue'
import PartouDayField from '@/components/InputFields/DaySelector/PartouDayField.vue'

@Component({
  components: { PartouCheckBox, PartouDayField }
})
export default class MultiStateDaySelectorLegend extends Vue {

}

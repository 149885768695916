
import i18n from '@/plugins/i18n'
import { Component, Vue } from 'vue-property-decorator'
import Page from '@/pages/Page'

@Component({
  metaInfo () {
    return {
      title: (this as any).pageTitle, // eslint-disable-line @typescript-eslint/no-explicit-any
      meta: [
        { property: 'title', content: (this as any).pageTitle }, // eslint-disable-line @typescript-eslint/no-explicit-any
        { name: 'robots', content: 'noindex, nofollow' }
      ]
    }
  }
})
export default class WaitingListFlowBasePage extends Vue implements Page {
  pageTitle = i18n.t('pageTitles.waitingList').toString()
}

import { Proposition } from '@/models'
import PropositionSubscription from '@/models/PropositionSubscription'
import IState from '@/store/IState'
import { GetterTree } from 'vuex'
import IPropositionState from './IPropositionState'

export const GETTERS = {
  getPropositionById: 'getPropositionById',
  getPropositionBySlug: 'getPropositionBySlug',
  getPropositionSubscriptionsForOffer: 'getPropositionSubscriptionsForOffer'
}

export function getters (): GetterTree<IPropositionState, IState> {
  return {
    getPropositionById: (state: IPropositionState) => (id: string) : Proposition | undefined => {
      const proposition = state.propositions?.find(x => x.id === id) ?? state.propositionHistory?.find(x => x.id === id)
      return proposition
    },
    getPropositionBySlug: (state: IPropositionState) => (serviceSlug: string) : Proposition | undefined =>
      state.propositions?.find(x => x.slug === serviceSlug) ?? state.propositionHistory?.find(x => x.slug === serviceSlug),
    getPropositionSubscriptionsForOffer: (state: IPropositionState) => (offerId: string): PropositionSubscription[] | undefined => {
      let propositionSubscriptions : PropositionSubscription[] | undefined
      if (state && state.propositions) {
        const proposition = state.propositions.find((prop) => prop.id === offerId)
        propositionSubscriptions = proposition?.propositionSubscriptions
      }

      return propositionSubscriptions
    }
  }
}

import { Loader } from '@googlemaps/js-api-loader'

export default class MapLoader extends Loader {
  constructor () {
    super({
      apiKey: process.env.VUE_APP_GOOGLE_API_KEY ?? '',
      version: 'weekly'
    })
  }
}

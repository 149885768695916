// Import these dependencies first. For some other imports rely on them!
import 'reflect-metadata' // for inversify
import '@/utils/directives'
import '@/utils/filters'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import Vue from 'vue'

import App from '@/pages/App/App.vue'
import i18n from '@/plugins/i18n'
import initVuetify from '@/plugins/vuetify'
import { createStore } from '@/plugins/vuex'
import router from '@/router/router'

import VueCookies from 'vue-cookies'
import { eventBus } from '@/EventBus'
import VueGtag, { PluginOptions } from 'vue-gtag'
import { initGoogleTagManager } from '@/plugins/googleAnalytics/gtm'
import { initDependencies } from '@/init/dependencies'
import { initApolloService } from '@/init/apollo'
import icons from '@/init/icons'
import IState from './store/IState'
import { Store } from 'vuex'
import { configureSentryScope, initSentry } from './plugins/sentry/Sentry'
import * as Sentry from '@sentry/browser'

Vue.use(VueCookies)
Vue.config.productionTip = false

const GOOGLE_ANALYTICS_TRACKING_ID = process.env.VUE_APP_GOOGLE_ANALYTICS_TRACKING_ID

export default class Main {
  async load (): Promise<void> {
    eventBus.$on('onCookieAccepted', this.initOptionalServices)
    this.initNecessaryServices()
    const store = createStore()
    this.initOptionalServices(store)
    this.loadPage(App, store)
  }

  loadPage (app: typeof Vue, store : Store<IState>): void {
    new Vue({
      router,
      vuetify: initVuetify(icons),
      i18n,
      store,
      render: h => h(app)
    }).$mount('#app')
  }

  initNecessaryServices (): void {
    initApolloService()
    initDependencies()
    initSentry()

    try {
      initGoogleTagManager(process.env.VUE_APP_GOOGLE_ANALYTICS_TRACKING_ID ?? '', router)
    } catch (exception) {
      Sentry.captureException(exception)
    }
  }

  initOptionalServices (store: Store<IState>): void {
    const cookiesConsent : string = Vue.$cookies.get('CookieConsent')
    if (cookiesConsent?.toLowerCase().includes('statistics:true')) {
      configureSentryScope(store)
    }
  }

  initVueGtag () : void {
    if (!GOOGLE_ANALYTICS_TRACKING_ID) {
      console.warn('Couldn\'t initialize Google analytics tracker: no tracking ID found!')
      return
    }

    const options : PluginOptions = {
      config: {
        id: GOOGLE_ANALYTICS_TRACKING_ID,
        params: {
          send_page_view: false
        }
      }
    }

    Vue.use(VueGtag, options)
  }
}
new Main().load()

import { inject, injectable } from 'inversify'
import ApolloClient from 'apollo-client'
import { QueryRoot, GetCurrentAndFuturePricingChangesQueryVariables, GetCurrentAndFuturePricingChangesDocument, ServicePricing, GetChildBenefitCalculatorProductsQueryVariables, GetChildBenefitCalculatorProductsQuery, GetChildBenefitCalculatorProductsDocument, ServiceProduct, ServiceVarietyName } from '@/models'
import IChildBenefitCalculatorService from './IChildBenefitCalculatorService'
import moment from 'moment'
import { ChildBenefitCalculatorProducts } from '@/models/BenefitCalculatorProduct'

@injectable()
export default class ChildBenefitCalculatorService implements IChildBenefitCalculatorService {
  private readonly apollo: ApolloClient<unknown>

  constructor (@inject(ApolloClient) apollo: ApolloClient<unknown>) {
    this.apollo = apollo
  }

  public removeRedundantPriceChange (servicePricings: ServicePricing[]): ServicePricing[] {
    if (servicePricings.length > 1 && moment(servicePricings[1].validFrom).month() - moment(new Date()).month() <= 1) {
      servicePricings.shift()
    }
    return servicePricings
  }

  public sortChronologically (servicePricings: ServicePricing[]): ServicePricing[] {
    if (servicePricings) {
      return servicePricings.sort((a, b) => (a.validFrom > b.validFrom) ? 1 : -1)
    }
    return []
  }

  public async fetchCurrentAndFuturePricingChangesAsync (variables: GetCurrentAndFuturePricingChangesQueryVariables): Promise<ServicePricing[]> {
    const result = await this.apollo.query<QueryRoot>({
      query: GetCurrentAndFuturePricingChangesDocument,
      variables,
      fetchPolicy: 'no-cache'
    })

    result.data.service_pricing = this.sortChronologically(result?.data.service_pricing)
    result.data.service_pricing = this.removeRedundantPriceChange(result.data.service_pricing)

    return result.data.service_pricing
  }

  async getChildBenefitCalculatorProductsAsync (variables: GetChildBenefitCalculatorProductsQueryVariables): Promise<ChildBenefitCalculatorProducts> {
    const result = await this.apollo.query<GetChildBenefitCalculatorProductsQuery>({
      query: GetChildBenefitCalculatorProductsDocument,
      variables
    })

    const serviceProducts = result.data.service_product as ServiceProduct[]
    const selectedProducts = serviceProducts.reduce((products: ChildBenefitCalculatorProducts, sp: ServiceProduct) => {
      if (sp.product) {
        const serviceVariety: ServiceVarietyName = ServiceVarietyName[sp.product.serviceVariety as keyof typeof ServiceVarietyName]
        products[serviceVariety] = sp.product.id
      }
      return products
    }, {})

    return selectedProducts
  }
}

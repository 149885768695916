import moment from 'moment'

export enum unitOfTime {
  year = 'year',
  week = 'week',
  month = 'month',
  day = 'day',
  hour = 'hour',
  minute = 'minute'
}

export function timeBetween (start : Date, end : Date, unit : unitOfTime) : number {
  const startMoment = moment(start)
  const endMoment = moment(end)

  return endMoment.diff(startMoment, unit)
}

/**
 * Test if a given date is between 2 nullable dates
 * If a date is null, the timeperiod is considered to have no start or no end
 * @param date Date to check
 * @param startDate Nullable startdate
 * @param endDate Nullable enddate
 * @param inclusivity Whether to include start- and endDate
 */
export function isBetween (date: Date, startDate?: Date, endDate?: Date, isStartDateIncluded = true, isEndDateIncluded = false): boolean {
  if (startDate && endDate) {
    return moment(date).isBetween(startDate, endDate, undefined, getInclusivity(isStartDateIncluded, isEndDateIncluded))
  } else if (startDate && !endDate) {
    return moment(date).isSameOrAfter(startDate)
  } else if (!startDate && endDate) {
    return moment(date).isSameOrBefore(endDate)
  } else {
    return true
  }
}

export function getInclusivity (isStartDateIncluded : boolean, isEndDateIncluded : boolean) : '()' | '[)' | '(]' | '[]' {
  return `${isStartDateIncluded ? '[' : '('}${isEndDateIncluded ? ']' : ')'}` as '()' | '[)' | '(]' | '[]'
}

export function addBusinessDays (date: Date, daysToAdd: number) : Date {
  let daysRemaining = daysToAdd

  const newDate = moment(date)

  while (daysRemaining > 0) {
    newDate.add(1, unitOfTime.day)
    // moment day 0 = sunday, 6 = saturday
    if (newDate.day() !== 0 && newDate.day() !== 6) {
      daysRemaining--
    }
  }

  return newDate.toDate()
}

const minimalDateOfBirthInYears = 13
const maximalStartDateOfCareInYears = 13
export const minimalDaysBetweenDateOfBirthAndStartDateOfCare = 70
export const minimalBusinessDaysBeforeCareStartDate = 10

export function getDateOfBirthMinDate (startDateOfDayCare : Date | undefined) : Date {
  return moment(startDateOfDayCare).subtract(minimalDateOfBirthInYears, unitOfTime.year).toDate()
}

export function getStartDateOfCareMinDate (dateOfBirth : Date | undefined) : Date | undefined {
  const minimumStartDate = addBusinessDays(new Date(), minimalBusinessDaysBeforeCareStartDate)
  return dateOfBirth ? moment.max(moment(minimumStartDate), moment(dateOfBirth).add(minimalDaysBetweenDateOfBirthAndStartDateOfCare, unitOfTime.day)).toDate() : undefined
}

export function getStartDateOfCareMaxDate (dateOfBirth : Date | undefined) : Date | undefined {
  return dateOfBirth ? moment(dateOfBirth).add(maximalStartDateOfCareInYears, unitOfTime.year).toDate() : undefined
}

export function calculateAge (dateOfBirth: Date, startDateOfDayCare: Date) : number {
  dateOfBirth = moment(dateOfBirth).startOf('day').toDate()
  startDateOfDayCare = moment(startDateOfDayCare).startOf('day').toDate()
  return Math.floor(timeBetween(dateOfBirth, startDateOfDayCare, unitOfTime.year))
}

import { Module } from 'vuex'
import IState from '@/store/IState'
import ICalculatorState, { STATE } from './ICalculatorState'
import { mutations, MUTATIONS } from './childBenefitCalculatorMutations'
import { actions, ACTIONS } from './childBenefitCalculatorActions'
import { getters, GETTERS } from './childBenefitCalculatorGetters'

export function state (): ICalculatorState {
  return {
    formState: {
      formIsValid: false,
      cumulativeIncomePerYear: undefined
    },
    constants: {
      isLoaded: false,
      maximumCompensatedHourlyRateKDV: undefined,
      maximumCompensatedHourlyRateBSO: undefined,
      defaultCumulativeIncome: undefined,
      defaultMinimumWorkingHours: undefined,
      validFrom: undefined,
      validUntil: undefined,
      maximumDeclarableHoursPerMonths: 0,
      workingHoursMultiplierBSO: 0,
      workingHoursMultiplierKDV: 0,
      compensatedPercentageTresholds: []
    }
  }
}

export function module (): Module<ICalculatorState, IState> {
  return {
    namespaced: true,
    state: state(),
    mutations: mutations(),
    actions: actions(),
    getters: getters()
  }
}
export const NAMESPACE = 'childBenefitCalculator'
export { ACTIONS }
export { MUTATIONS }
export { GETTERS }
export { STATE }

export { ICalculatorGetters } from './ICalculatorGetters'
export { GetMaximumHourlyRateInput, GetCostsInput, GetBenefitsInput, GetDeclarableHourlyRateInput, GetDeclarableHoursInput } from './ICalculatorGetterInputs'

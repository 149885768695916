
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import DayCheckboxState from './DayCheckboxState'
import DayCheckboxType from '@/components/InputFields/DaySelector/DayCheckboxType'

@Component({
  components: { }
})
export default class PartouDayField extends Vue {
  @Prop({ required: true })
  day!: DayCheckboxState

  @Prop({ required: true })
  isSelected!: boolean

  @Prop({ required: false, default: false })
  disabled! : boolean

  @Prop({ required: false, default: false })
  isReadOnly! : boolean

  @Prop({ required: false, default: false })
  isWaitingListOnly?: boolean

  @Prop({ required: false, default: false })
  showLabel! : boolean

  @Prop({ required: false, default: true })
  showDayName! : boolean

  @Prop({ required: false, default: false })
  renderAsCheckbox! : boolean

  message?: string
  animateMessage = false
  dayCheckboxType = DayCheckboxType

  get ariaLabel () : string {
    let value = this.$t(`daySelector.ariaLabel.${this.day.day.toLowerCase()}`).toString()
    value += ' '
    value += this.$t(`daySelector.ariaLabel.${this.day.isChecked ? 'checked' : 'unchecked'}`)

    if (this.day.type === DayCheckboxType.Wait) {
      value += ' ' + this.$t('daySelector.ariaLabel.waitinglist')
    }
    return value
  }

  firstToUpper (dayLabel: string):string {
    return dayLabel[0].toUpperCase() + dayLabel.substring(1)
  }

  getIconName (): string {
    return this.isSelected ? '$vuetify.icons.partouCheckNew' : ''
  }

  getMessage (dayCheckboxType: DayCheckboxType): string {
    switch (dayCheckboxType) {
    case DayCheckboxType.Check:
      return this.$t('daySelector.placeAvailable').toString()
    case DayCheckboxType.Closed:
      return this.$t('daySelector.closed').toString()
    default:
      return ''
    }
  }

  @Watch('isSelected', { immediate: true })
  onIsSelectedChanged (newValue: boolean): void {
    // If it is not selected the messsage must fade away
    if (!newValue) {
      this.animateMessage = false
    }
  }

  onClick (): void {
    if (this.disabled) {
      return
    }

    if (this.isReadOnly) {
      return
    }

    // If the type is closed it will not be emitted because it can never be selected
    if (this.day.type !== DayCheckboxType.Closed) {
      this.$emit('onChange', this.day)
    }
  }
}

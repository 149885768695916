
import { Component, Vue, Prop, Watch, Emit, Ref } from 'vue-property-decorator'

// function that's being called by vuetify rules
type ruleFunction = (arg0: string) => (Promise<boolean | string>) | boolean | string;

@Component({})
export default class PartouTextField extends Vue {
  @Ref('text-field')
  textField! : HTMLInputElement

  @Prop({ required: false, default: undefined })
  maxWidth? : string

  @Prop({ required: false, default: '' })
  label!: string

  @Prop({ required: false, default: '' })
  autocomplete!: string

  @Prop({ required: false, default: false })
  showLabel!: boolean

  @Prop({ required: false, default: 'normal' })
  size!: 'small' | 'normal'

  @Prop({ required: false, default: 'none' })
  iconPosition!: 'none' | 'left' | 'right'

  @Prop({ required: false, default: '' })
  iconName!: string

  @Prop({ required: false, default: false })
  iconFocusable!: boolean

  @Prop({ required: false, default: false })
  isRequired!: boolean

  @Prop({ required: false, default: false })
  isReadOnly!: boolean

  @Prop({ required: false, default: false })
  disabled!: boolean

  @Prop({ required: false, default: () => [] })
  rules! : ruleFunction[]

  @Prop({ required: false })
  isComponentValid? : boolean

  @Prop({ required: false })
  errorMessage? : string

  @Prop({ required: false, default: 0 })
  validateOnNumberOfChar?: number

  @Prop({ required: false })
  suffix?: string

  @Prop({ required: false, default: 'text' })
  type?: string

  @Prop({ required: false })
  placeholder?: string

  @Prop({ required: false, default: true })
  labelAsPlaceholder!: boolean

  get labelString () : string | undefined {
    let label = this.label
    if (label && this.isRequired) {
      label += ' *'
    }
    return label
  }

  get placeholderString () : string | undefined {
    if (this.showLabel) {
      return undefined
    }

    if (this.placeholder) {
      return this.placeholder
    }

    if (this.labelAsPlaceholder) {
      let label = this.label
      if (label && this.isRequired) {
        label += ' *'
      }
      return label
    }
  }

  internalIsComponentValid = this.isComponentValid ?? false
  internalErrorMessage = this.errorMessage ?? null
  initial = true

  focus () : void {
    this.textField.focus()
  }

  @Emit('onIconClicked')
  onIconClicked () : void {
    // emits click event
  }

  @Emit('onValidatedRules')
  validationRule (value: string) : boolean | string {
    let isValid = this.isValid(value)
    if (this.initial && !value) {
      this.initial = false
      return true
    }

    if (isValid === true && this.errorMessage) {
      isValid = this.errorMessage
    }

    if (isValid === true) {
      this.internalIsComponentValid = true
      this.internalErrorMessage = null
    } else {
      this.internalIsComponentValid = false
      if (typeof isValid === 'string') {
        this.internalErrorMessage = isValid as string
      }
    }
    return isValid
  }

  isValid (value: string): boolean | string {
    const ruleFunctions: ruleFunction[] = [...this.rules]

    if (this.isRequired) {
      ruleFunctions.unshift(this.checkRequiredRule)
    }

    for (const func of ruleFunctions) {
      const resp = func(value)
      if (resp === false) {
        return 'Validation failed'
      } else if (typeof resp === 'string') {
        return resp.toString()
      }
    }

    return true
  }

  checkRequiredRule (value: string): boolean | string {
    const errorMsgIfNotValid = this.$t('form.required').toString()
    return !!value || errorMsgIfNotValid
  }

  @Watch('isComponentValid', { immediate: true })
  onIsComponentValidChange () : void {
    this.internalIsComponentValid = this.isComponentValid ?? true
  }

  @Watch('errorMessage', { immediate: true })
  onErrorMessageChange () : void {
    this.internalErrorMessage = this.errorMessage ?? null
  }
}

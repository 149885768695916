import Timespan from '@/models/Timespan'
import { DayOfWeek } from './enums'
import { ServiceVarietyOpening as ServiceVarietyOpeningModel } from './generated/graphql'

type ServiceVarietyOpening = ServiceVarietyOpeningModel & {
  monday?: Timespan[]
  tuesday?: Timespan[]
  wednesday?: Timespan[]
  thursday?: Timespan[]
  friday?: Timespan[]
  saturday?: Timespan[]
  sunday?: Timespan[]
}

export default ServiceVarietyOpening

export function getServiceVarietyOpeningHoursForDay (opening: ServiceVarietyOpening, day: DayOfWeek) : Timespan[] {
  switch (day) {
  case DayOfWeek.Monday: return opening.monday
  case DayOfWeek.Tuesday: return opening.tuesday
  case DayOfWeek.Wednesday: return opening.wednesday
  case DayOfWeek.Thursday: return opening.thursday
  case DayOfWeek.Friday: return opening.friday
  case DayOfWeek.Saturday: return opening.saturday
  case DayOfWeek.Sunday: return opening.sunday
  default: return []
  }
}


import { Component, Vue } from 'vue-property-decorator'
import PartouButton from '@/components/PartouComponents/Buttons/PartouButton.vue'
import WaitingListHeader from '@/pages/WaitingListFlow/WaitingListComponents/WaitingListHeader.vue'
import WaitingListFooter from '@/pages/WaitingListFlow/WaitingListComponents/WaitingListFooter.vue'
import { ROUTES } from '@/router/routes'

@Component({
  components: { PartouButton, WaitingListHeader, WaitingListFooter }
})
export default class WaitingListUnavailableStatusContent extends Vue {
  OnSeeAvailableLocationClicked () : void {
    this.$router.push({ name: ROUTES.selectionGuide })
  }
}


import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class PartouFloatingCircleButton extends Vue {
  @Prop({ required: false, default: '' })
  iconName? : string

  @Prop({ required: false, default: '18px' })
  iconSize?: string
}

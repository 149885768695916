
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import PartouTextButton from '@/components/PartouComponents/Buttons/PartouTextButton.vue'
import Debounce from '@/utils/decorators/debounceDecorator'

@Component({
  components: { PartouTextButton }
})
export default class EmailVerificationContent extends Vue {
  disableRetryButton = false

  @Prop({ required: true })
  emailAddress! : string

  @Emit('onResendEmailClicked')
  onResendEmailClicked () : void {
    this.disableRetryButton = true
    this.startShowRetryButtonTimeout()
  }

  @Debounce(60000)
  startShowRetryButtonTimeout () : void {
    this.disableRetryButton = false
  }
}


import DayCheckboxState from '@/components/InputFields/DaySelector/DayCheckboxState'
import PartouCard from '@/components/PartouComponents/PartouCard.vue'
import { DayOfWeek, ServiceVarietyName } from '@/models'
import { NAMESPACES, STATE } from '@/store'
import IUserSelectionFormState from '@/store/modules/userSelection/IUserSelectionFormState'
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

const userSelectionModule = namespace(NAMESPACES.userSelection)

@Component({
  components: { PartouCard }
})
export default class ChildCard extends Vue {
  @userSelectionModule.State(STATE.userSelection.formState)
  userSelectionFormState!: IUserSelectionFormState

  orderedDaysOfWeekList: Array<DayOfWeek> = [DayOfWeek.Monday, DayOfWeek.Tuesday, DayOfWeek.Wednesday, DayOfWeek.Thursday, DayOfWeek.Friday]

  dayIsInOffer (serviceVarietyName: ServiceVarietyName, dayOfWeek: DayOfWeek): boolean {
    return this.userSelectionFormState.daysPerServiceVariety[serviceVarietyName].some(x => x.day === dayOfWeek && x.isChecked)
  }

  getStartDate (serviceVarietyName: ServiceVarietyName, dayOfWeek: DayOfWeek): string {
    const dayState: Partial<DayCheckboxState>[] = this.userSelectionFormState.daysPerServiceVariety[serviceVarietyName]
    const day = dayState.find(x => x.day === dayOfWeek && x.isChecked)
    return day!.startDate! // eslint-disable-line @typescript-eslint/no-non-null-assertion
  }

  get orderedServiceVarieties (): Array<ServiceVarietyName> {
    const sortedEnumValues = this.userSelectionFormState.selectedServiceVarieties.sort((a, b) => b.localeCompare(a))
    return sortedEnumValues
  }
}

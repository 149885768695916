import IUserSelectionFormState from '@/store/modules/userSelection/IUserSelectionFormState'
import { ServiceKind, ServiceVarietyName } from '@/models'

export function isFormStateSpecifiedForServiceKind (formState: IUserSelectionFormState, validateDays = false, validateSchool = false, serviceKind?: ServiceKind) : boolean {
  if (!serviceKind) {
    serviceKind = formState.selectedServiceKind
  }
  let serviceVarieties: Array<ServiceVarietyName>
  if (!serviceKind) {
    serviceVarieties = [ServiceVarietyName.KDV, ServiceVarietyName.VSO, ServiceVarietyName.NSO]
  } else if (serviceKind === ServiceKind.DayCare) {
    serviceVarieties = [ServiceVarietyName.KDV]
  } else {
    serviceVarieties = [ServiceVarietyName.VSO, ServiceVarietyName.NSO]
  }

  if (serviceKind === ServiceKind.SchoolCare && !formState.daysPerServiceVariety.NSO?.some(x => x.isChecked) && !formState.isAcceptingWaitinglist) {
    return false
  }

  let isSpecified = (!validateDays || serviceVarieties.some(sv => formState.daysPerServiceVariety[sv]?.some(x => x.isChecked))) &&
      !!formState.postalCode?.postcode &&
      formState.postalCode?.postcode.length > 0 &&
      !!formState.dateOfBirth &&
      !!formState.startDateOfDayCare

  if (isSpecified && validateSchool && serviceKind === ServiceKind.SchoolCare) {
    isSpecified = !!formState.selectedSchool
  }

  return isSpecified
}

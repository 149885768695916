
import { Component, Emit, Prop, VModel, Vue } from 'vue-property-decorator'
import PartouButton from '@/components/PartouComponents/Buttons/PartouButton.vue'
import PartouNotify from '@/components/PartouComponents/PartouNotify.vue'

@Component({
  components: { PartouButton, PartouNotify }
})
export default class PartouDialog extends Vue {
  @VModel({ required: true })
  showDialog!: boolean

  @Prop({ required: false, default: 'notification.cancel' })
  cancelTranslationString!: string

  @Prop({ required: false, default: 'notification.ok' })
  confirmTranslationString!: string

  @Prop({ required: false, default: '296' })
  width! : string

  @Emit('accepted')
  onNotifyAccept () : void {
    this.showDialog = false
  }

  @Emit('cancelled')
  onNotifyCancel () : void {
    this.showDialog = false
  }
}

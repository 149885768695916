import { Maybe } from 'graphql/jsutils/Maybe'
import PostalCodeServiceError from '../PostalCodeServiceErrors'
import PostalCodeInfo from '@/models/PostalCodeInfo'

export class GetPostalCodeResponse {
  result?: Maybe<PostalCodeInfo>
  errorCode?: PostalCodeServiceError

  constructor (result?: Maybe<PostalCodeInfo>, errorCode? :PostalCodeServiceError) {
    this.result = result
    this.errorCode = errorCode
  }
}

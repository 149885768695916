
import container, { SERVICE_IDENTIFIERS } from '@/init/container'
import { ROUTES } from '@/router/routes'
import IEmailService from '@/services/EmailService/IEmailService'
import { Component, Prop, Vue } from 'vue-property-decorator'
import BookingStatusErrorContent from '@/pages/BookingFlow/StepperContent/BookingStatusErrorContent.vue'
import BookingStatusError from '@/pages/BookingFlow/StepperContent/BookingStatusError'
import WaitingListHeader from '@/pages/WaitingListFlow/WaitingListComponents/WaitingListHeader.vue'
import PartouLoader from '@/components/PartouComponents/PartouLoader.vue'
import Page from '../Page'
import i18n from '@/plugins/i18n'

@Component({
  components: { WaitingListHeader, PartouLoader, BookingStatusErrorContent },
  metaInfo () {
    return {
      title: (this as any).pageTitle, // eslint-disable-line @typescript-eslint/no-explicit-any
      meta: [
        { property: 'title', content: (this as any).pageTitle }, // eslint-disable-line @typescript-eslint/no-explicit-any
        { name: 'robots', content: 'noindex, nofollow' }
      ]
    }
  }
})
export default class EmailConfirmationPage extends Vue implements Page {
  pageTitle = i18n.t('pageTitles.emailConfirm').toString()

  @Prop({ required: true })
  confirmationHash!: string

  @Prop({ required: true })
  bookingHash!: string

  emailConfirmed?: boolean | null = null
  bookingStatusError = BookingStatusError.emailConfirmUrlNotFound

  async mounted () : Promise<void> {
    await this.confirmEmailAndReroute()
  }

  async confirmEmailAndReroute () : Promise<void> {
    this.emailConfirmed = await this.confirmEmail()
    if (this.emailConfirmed) {
      this.$router.replace({ name: ROUTES.bookingStatus, params: { bookingHash: this.bookingHash } })
    }
  }

  async confirmEmail () : Promise<boolean> {
    const emailService = container.get<IEmailService>(SERVICE_IDENTIFIERS.IEmailService)
    let emailConfirmed = false
    await emailService.confirmEmail({ confirmationHash: this.confirmationHash })
      .then(confirmed => { emailConfirmed = confirmed })
      .catch(e => console.error(e))

    return emailConfirmed
  }

  goToBookingPage () : void {
    this.$router.replace({ name: ROUTES.bookingStatus, params: { bookingHash: this.bookingHash } })
  }
}

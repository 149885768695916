import { timeBetween, unitOfTime } from '@/utils/dateUtils'

enum ServiceKind {
  DayCare = 'DayCare',
  SchoolCare = 'SchoolCare'
}

export default ServiceKind

export function determineTypeOfCare (dateOfBirth: Date, startDateOfDayCare: Date) : ServiceKind {
  const age = Math.floor(timeBetween(dateOfBirth, startDateOfDayCare, unitOfTime.year))
  if (age >= 4) {
    return ServiceKind.SchoolCare
  } else { return ServiceKind.DayCare }
}

import { inject, injectable } from 'inversify'
import IPropositionService from './IPropositionService'
import ApolloClient, { ApolloQueryResult } from 'apollo-client'
import { Maybe, GetPropositionsOutput, QueryRoot, Proposition, GetPropositionsQueryVariables, GetPropositionsDocument, GetPropositionSubscriptionsQueryVariables, GetPropositionSubscriptionsDocument, GetPropositionSubscriptionsOutput, GetProductPriceAgreementChangeDatesQuery, GetProductPriceAgreementChangeDatesDocument, GetProductPriceAgreementChangeDatesQueryVariables } from '@/models'
import PropositionSubscription from '@/models/PropositionSubscription'
import { StatusCodes } from 'http-status-codes'
import IOperationContext from '../HasuraService/OperationContext'
import UnprocessablePropositionSubscriptionError from './UnprocessablePropositionSubscriptionError'

@injectable()
export default class PropositionService implements IPropositionService {
  private readonly apollo: ApolloClient<unknown>
  constructor (@inject(ApolloClient) apollo: ApolloClient<unknown>) {
    this.apollo = apollo
  }

  public async getPropositionsAsync (variables: GetPropositionsQueryVariables): Promise<Maybe<Proposition[]>> {
    const result = await this.apollo.query<QueryRoot>({
      query: GetPropositionsDocument,
      variables,
      fetchPolicy: 'no-cache'
    })

    const response: Maybe<GetPropositionsOutput[]> = result?.data?.getPropositions
    // Note: offers are returned as nested object, since they are enriched by Hasura. So these will have to be transformed (see GET_PROPOSITIONS query)
    const propositions: Maybe<Proposition[]> = response?.map(item => {
      const gqlProposition = { ...item, ...item.offer } as Partial<GetPropositionsOutput>
      delete gqlProposition.offer
      return gqlProposition as Proposition
    })

    return propositions
  }

  public async getPropositionSubscriptionsAsync (variables: GetPropositionSubscriptionsQueryVariables): Promise<Maybe<PropositionSubscription[]>> {
    const context: IOperationContext = {
      expectedErrorCodes: [StatusCodes.UNPROCESSABLE_ENTITY]
    }

    let priceChangeDates: Date[] | undefined
    if (variables.requestedProductIds) {
      priceChangeDates = await this.getPriceAgreementChangeDatesAsync({
        serviceId: variables.offerId,
        productIds: variables.requestedProductIds,
        dateStart: variables.startDateOfCare
      })
    }

    if (priceChangeDates) {
      let allPropositionSubscriptions: PropositionSubscription[] = []
      for (let index = 0; index < priceChangeDates.length; index++) {
        const priceChangeDate = priceChangeDates[index]
        variables.startDateOfCare = priceChangeDate.toISOString()
        const propositionSubscriptions = await this.RetrievePropositionSubscriptions(variables, context, priceChangeDate)
        if (propositionSubscriptions) {
          allPropositionSubscriptions = allPropositionSubscriptions.concat(propositionSubscriptions)
        }
      }

      return allPropositionSubscriptions
    }

    return await this.RetrievePropositionSubscriptions(variables, context, undefined)
  }

  private async RetrievePropositionSubscriptions (variables: GetPropositionSubscriptionsQueryVariables, context: IOperationContext, priceChangeDates: Date | undefined): Promise<Maybe<PropositionSubscription[]>> {
    return await this.apollo.query<QueryRoot>({
      query: GetPropositionSubscriptionsDocument,
      variables,
      context,
      fetchPolicy: 'no-cache'
    }).then((result: ApolloQueryResult<QueryRoot>) => {
      const response: Maybe<GetPropositionSubscriptionsOutput> = result?.data?.getPropositionSubscriptions
      const propositionSubscriptions: Maybe<PropositionSubscription[]> = response.propositionSubscriptions?.map(item => {
        const gqlPropositionSubscription = { ...item }
        if (priceChangeDates) {
          gqlPropositionSubscription.priceAgreementValidFrom = priceChangeDates
        }

        return gqlPropositionSubscription as PropositionSubscription
      })

      return propositionSubscriptions
    }).catch((error) => {
      const errorMessage = error.message
      if (errorMessage?.includes('NO_SCHOOL_GROUP_OPENING_HOURS')) {
        throw new UnprocessablePropositionSubscriptionError()
      } else {
        return []
      }
    })
  }

  private async getPriceAgreementChangeDatesAsync (variables: GetProductPriceAgreementChangeDatesQueryVariables): Promise<Date[]> {
    return await this.apollo.query<GetProductPriceAgreementChangeDatesQuery>({
      query: GetProductPriceAgreementChangeDatesDocument,
      variables
    }).then((result) => {
      return result.data.product_price_agreement.map((priceAgreement, i) => {
        return new Date(i === 0 ? variables.dateStart : priceAgreement.validFrom)
      })
    }).catch((error) => {
      console.warn(error)
      return []
    })
  }
}

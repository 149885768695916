import { Module } from 'vuex'
import IState from '@/store/IState'
import container, { SERVICE_IDENTIFIERS } from '@/init/container'
import IFeatureState, { STATE } from './IFeatureState'
import { mutations, MUTATIONS } from './FeatureMutations'
import { actions, ACTIONS } from './FeatureActions'
import IFeatureService from '@/services/FeatureService/IFeatureService'

export function state (): IFeatureState {
  return {
    defaultFeatures: []
  }
}

export function module (): Module<IFeatureState, IState> {
  const featureService = container.get<IFeatureService>(SERVICE_IDENTIFIERS.IFeatureService)

  return {
    namespaced: true,
    state,
    mutations: mutations(),
    actions: actions(featureService)
  }
}

export const NAMESPACE = 'defaultFeature'
export { ACTIONS }
export { MUTATIONS }
export { STATE }

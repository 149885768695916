
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import PartouButton from '@/components/PartouComponents/Buttons/PartouButton.vue'
import { ACTIONS, GETTERS, NAMESPACES } from '@/store'
import { namespace } from 'vuex-class'
import { ProductViewData } from '@/services/ProductService/ProductViewData'
import { ServiceVarietyName } from '@/models'
const userSelectionModule = namespace(NAMESPACES.userSelection)

  @Component({
    components: { PartouButton }
  })
export default class ProductTimeSelect extends Vue {
  @Prop({ required: true, default: () => [] })
  products!: ProductViewData[]

  @Prop({ required: true, default: false })
  displayVSOProducts!: boolean

  @userSelectionModule.Action(ACTIONS.userSelection.setSelectedProductIdsForServiceAsync)
  setSelectedProductIdsForServiceAsync!: (selectableProductIds : string[]) => Promise<void>

  @userSelectionModule.Getter(GETTERS.userSelection.getSelectedProductIds)
  getSelectedProductIds!: () => string[]

  @userSelectionModule.Getter(GETTERS.userSelection.getSelectedServiceVarieties)
  getSelectedServiceVarieties!: () => string[]

  isKDVVariety = true

  activeProductIds: Set<string> = new Set()

  NSOproducts: ProductViewData[] = []
  VSOproducts: ProductViewData[] = []

  mounted (): void {
    this.activeProductIds = new Set(this.getSelectedProductIds())
  }

  @Watch('products')
  onProductsChanged () {
    this.activeProductIds = new Set(this.getSelectedProductIds())
    this.isKDVVariety = this.getSelectedServiceVarieties().indexOf(ServiceVarietyName.KDV) !== -1

    if (!this.isKDVVariety) {
      this.setNSOAndVSOProductsFilterAndSorted()
    }
    if (!this.products.some(p => this.activeProductIds.has(p.id) && p.serviceVariety !== ServiceVarietyName.VSO)) {
      if (!this.isKDVVariety) {
        this.activeProductIds.clear()
        this.onProductTimeClicked(this.NSOproducts[0].id)
      } else {
        this.onProductTimeClicked(this.products[0].id)
      }
    }
    this.checkAnyActiveVSOProducts()
  }

  getTextualAddition () {
    const textualAdditions = []
    if (this.isKDVVariety) {
      const product = this.products.find(product => product.id === [...this.activeProductIds][0] && product.textualAddition !== undefined && product.textualAddition !== '')
      if (product) {
        textualAdditions.push(product.textualAddition)
      }
      return textualAdditions
    }
    const sortedActiveProductIds = [...this.activeProductIds].sort((a, b) => {
      const productA = this.products.find(product => product.id === a)
      const productB = this.products.find(product => product.id === b)
      if (productA?.serviceVariety === ServiceVarietyName.NSO) {
        return -1
      }
      return productB?.serviceVariety === ServiceVarietyName.VSO ? 1 : 0
    })

    for (const id of sortedActiveProductIds) {
      const product = this.products.find(product => product.id === id && product.textualAddition !== undefined && product.textualAddition !== '')
      if (product) {
        const notitionType = this.$t(`orderOverviewPage.productSelection.textualAddition${product.serviceVariety}`)
        const formattedDescription = `<b>${notitionType}</b> ${product.textualAddition}`
        textualAdditions.push(formattedDescription)
      }
    }
    return textualAdditions
  }

  checkAnyActiveVSOProducts () {
    if (!this.VSOproducts.some(p => this.activeProductIds.has(p.id))) {
      if (this.displayVSOProducts && this.VSOproducts.length > 0) {
        this.onProductTimeClicked(this.VSOproducts[0].id)
      }
    } else if (!this.displayVSOProducts) {
      const vsoProductId = this.VSOproducts.find(p => this.activeProductIds.has(p.id))?.id
      if (vsoProductId) {
        this.activeProductIds.delete(vsoProductId)
        this.setSelectedProductIdsForServiceAsync([...this.activeProductIds])
      }
    }
  }

  setNSOAndVSOProductsFilterAndSorted () {
    this.NSOproducts = this.products.filter(p => p.serviceVariety === ServiceVarietyName.NSO).sort((a, b) => this.parseTimeToAmountOfSeconds(a.endTime) - this.parseTimeToAmountOfSeconds(b.endTime))
    this.VSOproducts = this.products.filter(p => p.serviceVariety === ServiceVarietyName.VSO).sort((a, b) => this.parseTimeToAmountOfSeconds(a.startTime) - this.parseTimeToAmountOfSeconds(b.startTime))
  }

  parseTimeToAmountOfSeconds (timeString : string) {
    const [hours, minutes, seconds] = timeString.split(':').map(Number)
    return hours * 3600 + minutes * 60 + seconds // convert to seconds
  }

  onProductTimeClicked (productId: string) {
    if (this.isKDVVariety) {
      this.activeProductIds.clear()
    } else {
      const NSOproductIds = this.NSOproducts.map(e => e.id)
      const VSOproductIds = this.VSOproducts.map(e => e.id)
      if (NSOproductIds.includes(productId)) {
        NSOproductIds.forEach(p => this.activeProductIds.delete(p))
      } else if (VSOproductIds.includes(productId)) {
        VSOproductIds.forEach(p => this.activeProductIds.delete(p))
      }
    }
    this.activeProductIds.add(productId)
    this.setSelectedProductIdsForServiceAsync([...this.activeProductIds])

    this.$forceUpdate()
  }

  calculateSelectedHourlyRate (serviceVariety : string) : string {
    const activeProducts = this.products.filter(p => p.serviceVariety === serviceVariety && [...this.activeProductIds].includes(p.id))
    const hourlyRate = activeProducts[0]?.pricePerHour ?? 0

    return `€ ${(hourlyRate / 100).toFixed(2).replace('.', ',')} `
  }

  getButtonColour (productId: string) : string {
    return this.checkIfActive(productId) ? 'partou-secondary-bright-green' : 'partou-black-eighty'
  }

  checkIfActive (productId: string) : boolean {
    return this.activeProductIds.has(productId)
  }

  formatTime (time : string) : string {
    return time.slice(0, 5)
  }
}

import { DayOfWeek } from '@/models'
import IUserSelectionState from '../IUserSelectionState'

export function getUniqueSelectedDays (state : IUserSelectionState) : DayOfWeek[] {
  let uniqueDays : DayOfWeek[] = []
  if (state.formState.daysPerServiceVariety.KDV && state.formState.daysPerServiceVariety.VSO && state.formState.daysPerServiceVariety.NSO) {
    uniqueDays = [...new Set([...state.formState.daysPerServiceVariety.KDV.filter(x => x.isChecked).flatMap(x => x.day), ...state.formState.daysPerServiceVariety.VSO.filter(x => x.isChecked).flatMap(x => x.day), ...state.formState.daysPerServiceVariety.NSO.filter(x => x.isChecked).flatMap(x => x.day)] as DayOfWeek[])]
  }
  return uniqueDays
}

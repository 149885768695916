import * as OpenPgp from 'openpgp'
import { Md5 } from 'ts-md5'
import { randomFloat } from './numberUtils'

export function ParseToPascalCase (s : string): string {
  return s.replace(/(\w)(\w*)/g, (g0, g1, g2) => { return g1.toUpperCase() + g2.toLowerCase() })
}

export function capitalizeFirstLetters (input: string) : string {
  return input?.split(' ').map(x => x.charAt(0).toUpperCase() + x.slice(1).toLowerCase()).join(' ')
}

export async function encryptStringAsync (stringToEncrypt: string) : Promise<string> {
  let armoredKey = process.env.VUE_APP_OPENPGP_ENCRYPT_PUBLIC_KEY
  if (!armoredKey || armoredKey.length === 0) {
    console.warn('armored key is not set, using a generated key (for testing purposes only!')
    const generatedKey = await OpenPgp.generateKey({ userIDs: [{ name: 'user' }] })
    armoredKey = generatedKey.privateKey
  }

  const publicKey = await OpenPgp.readKey({ armoredKey })
  const message = await OpenPgp.createMessage({ text: stringToEncrypt })

  return (await OpenPgp.encrypt({
    encryptionKeys: publicKey,
    message
  })) as string
}

export async function encryptObjectAsync<T> (objectToEncrypt: T) : Promise<{ key: string, encryptedString: string }> {
  const key = Md5.hashStr(randomFloat().toString() + new Date().toISOString())
  const encryptedString = (await OpenPgp.encrypt({ message: await OpenPgp.createMessage({ text: JSON.stringify(objectToEncrypt) }), passwords: [key] })) as string

  return { key, encryptedString }
}

export async function decryptObjectAsync<T> (stringToDecrypt: string, key: string) : Promise<T> {
  const message = await OpenPgp.readMessage({ armoredMessage: stringToDecrypt })
  const decrypted = await OpenPgp.decrypt({ message, passwords: [key] })

  return JSON.parse(decrypted.data as string) as T
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function OnPasteRemoveInvalidCharacters (value : any, currentStringLength : number, maxLength : number) : string {
  value.preventDefault()

  if (!value.clipboardData) {
    return ''
  }

  let modifiedData = value.clipboardData.getData('text').replace(/[^A-Za-z0-9]/g, '')
  const input = value.target
  const startPos = input?.selectionStart
  const endPos = input?.selectionEnd
  if (startPos === endPos) {
    modifiedData = modifiedData.substring(0, maxLength - currentStringLength)
  } else if (startPos !== endPos) {
    modifiedData = modifiedData.substring(0, endPos - startPos)
  }
  input.value = input.value.substring(0, startPos) + modifiedData + input.value.substring(endPos, input.value.length)
  if (input.value.length > maxLength) {
    input.value = input.value.substring(0, maxLength)
  }
  const newCursorPosition = startPos + modifiedData.length
  value.target.setSelectionRange(newCursorPosition, newCursorPosition)
  return input.value
}


import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({ components: { } })
export default class PartouNotify extends Vue {
  @Prop({ required: true })
  text! : string

  @Prop({ required: false, default: '$vuetify.icons.partouInfo' })
  icon! : string

  @Prop({ required: false, default: '' })
  className? : string

  get formattedText () {
    return this.text.replace(/\n/g, '<br/>')
  }
}

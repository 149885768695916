
import { Component, Prop, Vue } from 'vue-property-decorator'
import PartouButton from '@/components/PartouComponents/Buttons/PartouButton.vue'
import WaitingListHeader from '@/pages/WaitingListFlow/WaitingListComponents/WaitingListHeader.vue'
import WaitingListFooter from '@/pages/WaitingListFlow/WaitingListComponents/WaitingListFooter.vue'
import { ROUTES } from '@/router/routes'

@Component({
  components: { PartouButton, WaitingListHeader, WaitingListFooter }
})
export default class OfferExpiredStatusContent extends Vue {
  @Prop({ required: false })
  spotAvailableDurationInMinutes!: string

  spotAvailableDurationInDaysString?: string | null

  beforeMount () : void {
    if (this.spotAvailableDurationInMinutes) {
      sessionStorage.setItem('spotAvailableDurationInMinutes', this.spotAvailableDurationInMinutes)
    }

    this.spotAvailableDurationInDaysString = (parseInt(sessionStorage.getItem('spotAvailableDurationInMinutes')!) / 60 / 24).toString() // eslint-disable-line @typescript-eslint/no-non-null-assertion
  }

  onSeeAvailableLocationClicked () : void {
    this.$router.push({ name: ROUTES.selectionGuide })
  }
}

import { ProductPriceAgreement, ServiceProduct } from '@/models'
import { ProductViewData } from './ProductViewData'

export class ProductViewMapper {
  constructor (serviceProducts: Partial<ServiceProduct>[]) {
    this.products = serviceProducts.map(product => {
      const startTime = product.product?.productDayInfos[0]?.startTime ?? ''
      const endTime = product.product?.productDayInfos[0]?.endTime ?? ''

      const productPriceAgreement: Partial<ProductPriceAgreement> = product.productPriceAgreement?.at(0) ?? {}
      const amountsArray: number[] = Object.values(productPriceAgreement)
        .filter((amount): amount is number => amount !== undefined && amount !== 0)

      const serviceVariety = product.product?.serviceVariety ?? ''
      const textualAddition = product.showTextualAddition ? product.textualAddition : undefined
      return new ProductViewData(product.product?.id ?? '', startTime, endTime, amountsArray[0] ?? 0, serviceVariety, textualAddition)
    })
  }

  products: ProductViewData[] = []
}


import { Vue, Component, Watch, Prop, Emit } from 'vue-property-decorator'
import container, { SERVICE_IDENTIFIERS } from '@/init/container'
import IPostalCodeService from '@/services/PostalCodeService/IPostalCodeService'
import { Maybe, PostalCode } from '@/models'
import PartouTextField from '@/components/PartouComponents/Input/PartouTextField/PartouTextField.vue'
import { inputRegexDirective } from '@/utils/directives'
import { regexes } from '@/definitions'
import { namespace } from 'vuex-class'
import { ACTIONS, NAMESPACES, STATE } from '@/store'
import IUserSelectionFormState from '@/store/modules/userSelection/IUserSelectionFormState'
import PostalCodeServiceError from '@/services/PostalCodeService/PostalCodeServiceErrors'
import PostalCodeInfo from '@/models/PostalCodeInfo'
import { OnPasteRemoveInvalidCharacters } from '@/utils/stringUtils'

const userSelectionModule = namespace(NAMESPACES.userSelection)

@Component({
  components: { PartouTextField },
  directives: { inputRegexDirective },
  data: () => ({ regexes })
})
export default class PostalCodeInput extends Vue {
  @Prop({ required: true, default: {} })
  value!: PostalCode

  @Prop({ required: false, default: false })
  showLabel!: boolean

  @Prop()
  isValid?: boolean

  @userSelectionModule.Action(ACTIONS.userSelection.setPostalCodeValidationPending) setPostalCodeValidationPending!: (pending: boolean) => void
  @userSelectionModule.Action(ACTIONS.userSelection.setPostalCodeValidationIsValid) setPostalCodeValidationIsValid!: (isValid: boolean) => void
  @userSelectionModule.Action(ACTIONS.userSelection.setPostalCodeAsync) setPostalCodeAsync!: (postalCode?: PostalCode) => Promise<void>

  @userSelectionModule.State(STATE.userSelection.formState)
  userSelectionFormState!: IUserSelectionFormState

  errorMessage: string | null = null
  emittedValue: PostalCodeInfo | null = null
  postalCodeString = ''
  postalCodeIsValid = false
  loading = false
  initial = true
  isComponentValid = false
  postalCodeService!: IPostalCodeService
  newPostalCode?: Maybe<PostalCodeInfo>
  validateAlways = false
  maxChars = 6

  created () : void {
    this.postalCodeService = container.get<IPostalCodeService>(SERVICE_IDENTIFIERS.IPostalCodeService)
  }

  @Watch('value', { immediate: true, deep: true })
  onValueChanged () : void {
    this.loading = this.value?.isLoading ?? false
    this.postalCodeString = this.value?.postcode ?? this.postalCodeString
  }

  @Watch('isValid')
  onisValidChanged () : void {
    this.postalCodeIsValid = this.isValid ?? this.postalCodeIsValid
  }

  @Watch('postalCodeString')
  onPostalCodeStringChanged (newVal: string) : void {
    this.postalCodeString = newVal.toUpperCase()
  }

  isPostalCodeFormatValid (postalCode: string): boolean {
    const dutchValidationRegex = regexes.validation.postalcodeRegex
    const regex = new RegExp(dutchValidationRegex)
    const isValid = regex.test(postalCode)
    return isValid
  }

  checkPostalCodeFormat (postalCode: string): boolean | string {
    const errorMsgIfNotValid = this.$t('selectionGuidePage.selectionGuideForm.postalCodeInput.error.msgPostalCodeInvalidFormat').toString()
    const postalCodeFormatIsValid = this.isPostalCodeFormatValid(postalCode)
    this.postalCodeIsValid = postalCodeFormatIsValid ?? false
    this.validateAlways = true
    return this.postalCodeIsValid || errorMsgIfNotValid
  }

  async checkPostalCodeExistanceAsync (): Promise<void> {
    if (this.postalCodeString && this.checkPostalCodeFormat(this.postalCodeString) === true) {
      this.errorMessage = null
      this.loading = true
      this.setPostalCodeValidationPending(true)
      const response = await this.postalCodeService.getPostalCodeInfo({ postalCode: this.postalCodeString })
      this.newPostalCode = response?.result
      this.postalCodeIsValid = !!this.newPostalCode && response?.errorCode === undefined
      await this.setPostalCodeAsync({})

      if (this.postalCodeIsValid) {
        await this.setPostalCodeAsync(this.newPostalCode as PostalCode)
      }

      if (response?.errorCode === PostalCodeServiceError.notFound) {
        this.errorMessage = this.$t('selectionGuidePage.selectionGuideForm.postalCodeInput.error.msgPostalCodeDoesntExist').toString()
      }

      if (response?.errorCode === PostalCodeServiceError.isPostOfficeBox) {
        this.errorMessage = this.$t('selectionGuidePage.selectionGuideForm.postalCodeInput.error.postalCodeisPOBox').toString()
      }

      if (response?.errorCode === PostalCodeServiceError.serviceUnavailable) {
        this.postalCodeIsValid = false
        this.errorMessage = this.$t('selectionGuidePage.selectionGuideForm.postalCodeInput.error.APIError').toString()
      }

      this.isComponentValid = this.postalCodeIsValid

      this.setPostalCodeValidationIsValid(this.postalCodeIsValid)
      this.setPostalCodeValidationPending(false)

      this.loading = false
    }
  }

  @Emit('input')
  onPostalCodeChanged (emitValue: PostalCodeInfo | null): PostalCodeInfo | null {
    return emitValue
  }

  async onBlurAsync (event: FocusEvent) : Promise<void> {
    await this.checkPostalCodeExistanceAsync()
    let emitValue: PostalCodeInfo | null = null
    if (this.postalCodeIsValid && this.newPostalCode) {
      emitValue = this.newPostalCode
    }

    if (emitValue !== this.emittedValue) {
      this.emittedValue = emitValue
      this.onPostalCodeChanged(emitValue)
    }

    if (event && event.target) {
      (event.target as HTMLInputElement).blur()
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onPasteRemoveInvalidCharacters (value : any) : void {
    this.postalCodeString = OnPasteRemoveInvalidCharacters(value, this.postalCodeString.length, this.maxChars)
  }

  onPostalCodeValueChanged () : void {
    this.errorMessage = null
    this.setPostalCodeValidationIsValid(false)
  }
}

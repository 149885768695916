
import { Prop, Component, Vue } from 'vue-property-decorator'
import PartouFloatingShieldButton from '@/components/PartouComponents/Buttons/PartouFloatingShieldButton.vue'
import PartouSocials from '@/components/PartouComponents/PartouSocials.vue'
import { ContactInfo, Offer } from '@/models'

@Component({
  components: { PartouFloatingShieldButton, PartouSocials }
})
export default class ContactContent extends Vue {
  @Prop({ required: true })
  offer! : Offer

  @Prop({ required: true })
  contactInfo!: ContactInfo
}

import { render, staticRenderFns } from "./OrderOverviewPage.vue?vue&type=template&id=38ba1d32&scoped=true&"
import script from "./OrderOverviewPage.vue?vue&type=script&lang=ts&"
export * from "./OrderOverviewPage.vue?vue&type=script&lang=ts&"
import style0 from "./OrderOverviewPage.vue?vue&type=style&index=0&id=38ba1d32&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38ba1d32",
  null
  
)

export default component.exports
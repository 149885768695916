import CryptoJS from 'crypto-js'
import { Md5 } from 'ts-md5'
import { randomFloat } from './numberUtils'
import { Buffer } from 'buffer'

export const DEFAULT_AES_OPTIONS = {
  mode: CryptoJS.mode.CBC,
  keySize: 256
}

export function encryptObjectAes<T> (objectToEncrypt: T, base64Key: string, iv: string): string {
  return encryptAes(JSON.stringify(objectToEncrypt), base64Key, iv)
}

export function encryptAes (messageToEncrypt: string, base64Key: string, iv: string): string {
  const base64KeyByteArray = CryptoJS.enc.Base64.parse(base64Key)
  const ivByteArray = iv === undefined ? undefined : CryptoJS.enc.Utf8.parse(iv)

  const ciphertext = CryptoJS.AES.encrypt(messageToEncrypt, base64KeyByteArray, {
    ...DEFAULT_AES_OPTIONS,
    iv: ivByteArray
  })

  return ciphertext.toString()
}

export function decryptObjectAes<T> (ciphterText: string, base64Key: string, iv?: string): T {
  const decryptedMessage = decryptAes(ciphterText, base64Key, iv)
  return JSON.parse(decryptedMessage) as T
}

export function decryptAes (ciphterText: string, base64Key: string, iv?: string): string {
  const base64KeyByteArray = CryptoJS.enc.Base64.parse(base64Key)
  const ivByteArray = !iv ? undefined : CryptoJS.enc.Utf8.parse(iv)

  const bytes = CryptoJS.AES.decrypt(ciphterText, base64KeyByteArray, {
    ...DEFAULT_AES_OPTIONS,
    iv: ivByteArray
  })

  return bytes.toString(CryptoJS.enc.Utf8)
}

export function toBase64String (key: string): string {
  return Buffer.from(key, 'binary').toString('base64')
}

export function generateHash (): string {
  return Md5.hashStr(randomFloat().toString() + new Date().toISOString())
}

export function generateKey (hash?: string): string {
  hash = !hash ? generateHash() : hash
  return toBase64String(hash)
}


import { Dictionary } from 'lodash'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { RawLocation } from 'vue-router'

@Component
export default class FakeLink extends Vue {
  @Prop({ required: true })
  to!: string

  @Prop({ required: false })
  params?: Dictionary<string>

  get target () : RawLocation {
    return { name: this.to, params: this.params }
  }
}

enum DayOfWeek {
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
  Sunday = 'Sunday'
}

export default DayOfWeek

export function parseStringToDayOfWeek (value : string) : DayOfWeek {
  switch (value.toLowerCase()) {
  case 'sunday': return DayOfWeek.Sunday
  case 'monday': return DayOfWeek.Monday
  case 'tuesday': return DayOfWeek.Tuesday
  case 'wednesday': return DayOfWeek.Wednesday
  case 'thursday': return DayOfWeek.Thursday
  case 'friday': return DayOfWeek.Friday
  case 'saturday': return DayOfWeek.Saturday
  }
  throw new Error(`given string: ${value} cannot be parsed to day of week`)
}

export function parseDayOfWeekToNumber (value : DayOfWeek) : number {
  switch (value) {
  case DayOfWeek.Sunday : return 0
  case DayOfWeek.Monday : return 1
  case DayOfWeek.Tuesday : return 2
  case DayOfWeek.Wednesday : return 3
  case DayOfWeek.Thursday : return 4
  case DayOfWeek.Friday : return 5
  case DayOfWeek.Saturday : return 6
  }
}

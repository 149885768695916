import HttpResponseError from '@/Errors/HttpResponseError'
import { ConfirmEmailDocument, ConfirmEmailMutationVariables, ResendEmailConfirmationDocument, ResendEmailConfirmationMutationVariables } from '@/models'
import ApolloClient, { ApolloError } from 'apollo-client'
import { StatusCodes } from 'http-status-codes'
import { inject, injectable } from 'inversify'
import IOperationContext from '../HasuraService/OperationContext'
import IEmailService from './IEmailService'

@injectable()
export default class EmailService implements IEmailService {
  private readonly apollo: ApolloClient<unknown>
  constructor (@inject(ApolloClient) apollo: ApolloClient<unknown>) {
    this.apollo = apollo
  }

  /**
   * Confirm email verification
   *
   * @returns True if email is confirmed, otherwise false
   */
  public async confirmEmail (variables: ConfirmEmailMutationVariables): Promise<boolean> {
    const context: IOperationContext = {
      expectedErrorCodes: [StatusCodes.UNPROCESSABLE_ENTITY, StatusCodes.GONE]
    }

    try {
      await this.apollo.mutate({
        mutation: ConfirmEmailDocument,
        variables,
        context
      })
      return true
    } catch (e) {
      if (e instanceof ApolloError && e.networkError instanceof HttpResponseError) {
        switch (e.networkError.statusCode) {
        case StatusCodes.GONE: // Already verified or expired
          return true
        case StatusCodes.UNPROCESSABLE_ENTITY:
          return false
        }
      }
      throw e
    }
  }

  /**
   * Resend verification email
   *
   * @returns False if email already confirmed, otherwise true
   */
  public async resendEmailConfirmation (variables: ResendEmailConfirmationMutationVariables): Promise<boolean> {
    const context: IOperationContext = {
      expectedErrorCodes: [StatusCodes.GONE]
    }

    try {
      await this.apollo.mutate({
        mutation: ResendEmailConfirmationDocument,
        variables,
        context
      })
      return true
    } catch (e) {
      if (e instanceof ApolloError && e.networkError instanceof HttpResponseError) {
        if (e.networkError.statusCode === StatusCodes.GONE) {
          return false
        }
      }
      throw e
    }
  }
}


import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import BirthAndStartDateSelectionForm from '@/components/AvailabilitySelector/BirthAndStartDateSelectionForm.vue'
import MultiStateDaySelector from '@/components/MultiStateDaySelector/MultiStateDaySelector.vue'
import { getDateOfBirthMinDate, getStartDateOfCareMinDate, getStartDateOfCareMaxDate } from '@/utils/dateUtils'
import IUserSelectionFormState from '@/store/modules/userSelection/IUserSelectionFormState'
import { NAMESPACES, STATE, GETTERS } from '@/store'
import { namespace } from 'vuex-class'
import IContactDetailsFormState from '@/store/modules/contactDetails/IContactDetailsFormState'
import DayCheckboxState from '@/components/InputFields/DaySelector/DayCheckboxState'
import { ServiceKind, ServiceVarietyName } from '@/models'
import DateValidationPopups from '@/components/AvailabilitySelector/DateValidationPopups.vue'

const userSelectionModule = namespace(NAMESPACES.userSelection)
const contactDetailModule = namespace(NAMESPACES.contactDetails)

@Component({
  components: {
    BirthAndStartDateSelectionForm,
    MultiStateDaySelector,
    DateValidationPopups
  }
})
export default class SelectionGuideForm extends Vue {
  @Ref('selection-guide-form')
  SelectionGuideForm! : HTMLFormElement

  @Ref('selectionGuideDatesForm')
  SelectionGuideDatesForm! : HTMLFormElement

  @Ref('daySelector')
  DaySelector! : HTMLFormElement

  @userSelectionModule.State(STATE.userSelection.formState)
  userSelectionFormState!: IUserSelectionFormState

  @contactDetailModule.State(STATE.contactDetails.formState)
  contactDetailFormState!: IContactDetailsFormState

  @userSelectionModule.Getter(GETTERS.userSelection.getDayCheckboxState)
  getDayCheckboxState!: (withAvailability: boolean) => Record<ServiceVarietyName, DayCheckboxState[]>

  @userSelectionModule.Getter(GETTERS.userSelection.isFormStateSpecifiedForServiceKind)
  isFormStateSpecifiedForServiceKind!: (validateDays: boolean, validateSchool: boolean, serviceKind?: ServiceKind) => boolean

  @Prop({ required: true })
  showNoDaySelectionError! : boolean

  get dateOfBirthMinDate () : Date | undefined {
    return getDateOfBirthMinDate(this.userSelectionFormState.startDateOfDayCare)
  }

  get startDateOfCareMinDate () : Date | undefined {
    return getStartDateOfCareMinDate(this.userSelectionFormState.dateOfBirth)
  }

  get startDateOfCareMaxDate (): Date | undefined {
    return getStartDateOfCareMaxDate(this.userSelectionFormState.dateOfBirth)
  }

  isValid = false
  isValidDates = false
  isValidatingForm = false
  ServiceKind = ServiceKind

  mounted () : void {
    this.validateFormAsync()
  }

  @Watch('userSelectionFormState', { deep: true, immediate: true })
  onUserSelectionFormStateChanged (): void {
    this.$nextTick(() => this.validateFormAsync())
  }

  validate (force = true) : boolean {
    if (force) {
      this.SelectionGuideDatesForm.validateFormAsync(force)
    }
    return this.isValid && this.isValidDates && this.isFormStateSpecifiedForServiceKind(true, false)
  }

  // Form validates async, don't make this method sync without testing!
  async validateFormAsync () : Promise<void> {
    if (this.isValidatingForm) {
      return
    }

    this.validate(false)
  }

  async onFormDataChangedAsync (areDatesValid: boolean) : Promise<void> {
    this.isValidDates = areDatesValid
    this.userSelectionFormState.isAcceptingWaitinglist = false
  }
}

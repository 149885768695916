import NotFoundPage from '@/pages/statuspages/NotFoundPage.vue'
import SharedPage from '@/pages/Share/SharedPage.vue'
import ServiceOverviewPage from '@/pages/SelectionFlow/ServiceOverviewPage/ServiceOverviewPage.vue'
import SchoolSelectionPage from '@/pages/SelectionFlow/SchoolSelectionPage/SchoolSelectionPage.vue'
import ContactInfoCheckPage from '@/pages/OrderFlow/ContactInfoCheckPage/ContactInfoCheckPage.vue'
import SelectionGuidePage from '@/pages/SelectionFlow/SelectionGuidePage/SelectionGuidePage.vue'
import ServiceDetailPage from '@/pages/SelectionFlow/ServiceDetailPage/ServiceDetailPage.vue'
import OrderContactInfoPage from '@/pages/OrderFlow/OrderContactInfoPage/OrderContactInfoPage.vue'
import BookingStatusPage from '@/pages/BookingFlow/BookingStatusPage.vue'
import SignContractPage from '@/features/SignContract/SignContractPage.vue'
import EmailConfirmationPage from '@/pages/BookingFlow/EmailConfirmationPage.vue'
import WaitingListCancellationContent from '@/pages/WaitingListFlow/WaitingListContent/WaitingListCancellationContent.vue'
import WaitingListExtendContent from '@/pages/WaitingListFlow/WaitingListContent/WaitingListExtendContent.vue'
import WaitingListBookingRequiresPlanner from '@/pages/WaitingListFlow/WaitingListStatusContent/WaitinglistsBookingRequiresPlanner.vue'
import WaitingListFlowBasePage from '@/pages/WaitingListFlow/WaitingListFlowBasePage.vue'
import CancelSuccessStatusContent from '@/pages/WaitingListFlow/WaitingListStatusContent/CancelSuccessStatusContent.vue'
import WaitingListsBookedContent from '@/pages/WaitingListFlow/WaitingListStatusContent/WaitingListsBookedContent.vue'
import OfferExpiredStatusContent from '@/pages/WaitingListFlow/WaitingListStatusContent/OfferExpiredStatusContent.vue'
import ReactionReceivedStatusContent from '@/pages/WaitingListFlow/WaitingListStatusContent/ReactionReceivedStatusContent.vue'
import WaitingListUnavailableStatusContent from '@/pages/WaitingListFlow/WaitingListStatusContent/WaitingListUnavailableStatusContent.vue'
import { RouteConfig } from 'vue-router'
import { store } from '@/plugins/vuex'
import container, { SERVICE_IDENTIFIERS } from '@/init/container'
import PostalCodeService from '@/services/PostalCodeService/PostalCodeService'
import moment from 'moment'
import SchoolServiceService from '@/services/SchoolServiceService/SchoolServiceService'
import { getStartDateOfCareMinDate, getStartDateOfCareMaxDate } from '@/utils/dateUtils'
import { GETTERS, NAMESPACES } from '@/store'
import { ServiceKind } from '@/models'
import i18n from '@/plugins/i18n'
import ComponentsPage from '@/pages/Components/ComponentsPage.vue'
import PostalCodeServiceError from '@/services/PostalCodeService/PostalCodeServiceErrors'
import { LocationDetailMdmidGuard } from './routeGuards'
import PostalCodeInfo from '@/models/PostalCodeInfo'
import OrderOverviewWrapperPage from '@/pages/OrderFlow/OrderOverviewPage/OrderOverviewWrapperPage.vue'

/**
 * publicPages do not require authentication when loaded
 */
export const publicPages = {
  components: 'components',
  selectionGuide: 'selectionGuide',
  schoolSelectionPage: 'schoolSelectionPage',
  locations: 'locations',
  locationsWithPostalCode: 'locationsWithPostalCode',
  locationDetail: 'locationDetail',
  contactInfoCheckPage: 'contactInfoCheckPage',
  orderOverview: 'orderOverview',
  orderContactInfo: 'orderContactInfo',
  bookingStatus: 'bookingStatus',
  signContract: 'signContract',
  waitingListStatus: 'waitingListStatus',
  confirmEmail: 'confirmEmail',
  waitingListCancel: 'waitingListCancel',
  waitingListExtend: 'waitingListExtend',
  waitingListDayOverview: 'waitingListDayOverview',
  waitingListCancelSuccess: 'waitingListCancelSuccess',
  waitingListBooked: 'waitingListBooked',
  waitingListReactionReceived: 'waitingListReactionReceived',
  waitingListOfferExpired: 'waitingListOfferExpired',
  waitingListUnavailable: 'waitingListUnavailable',
  waitingListFlowBase: 'waitingListFlowBase',
  waitinglistRequiresPlanner: 'waitinglistRequiresPlanner',
  notFound: 'notFound',
  bookWaitingListPlacesCompleted: 'bookWaitingListPlacesCompleted',
  share: 'share',
  RegisterEnglish1: 'RegisterEnglish1',
  RegisterEnglish2: 'RegisterEnglish2',
  RegisterDutch1: 'RegisterDutch1',
  RegisterDutch2: 'RegisterDutch2',
  locatieMdmId: 'locatieMdmId',
  locationMdmId: 'locationMdmId',
  external: {
    getTourPageUrl: () => {
      if (i18n.locale === 'en') {
        return store.state.configuration.configuration?.externalUrls.tourPage.en
      } else {
        return store.state.configuration.configuration?.externalUrls.tourPage.nl
      }
    },
    getBookPageUrl: () => {
      if (i18n.locale === 'en') {
        return store.state.configuration.configuration?.externalUrls.bookPage.en
      } else {
        return store.state.configuration.configuration?.externalUrls.bookPage.nl
      }
    },
    getLocationPage: () => {
      if (i18n.locale === 'en') {
        return store.state.configuration.configuration?.externalUrls.locationPage.en
      } else {
        return store.state.configuration.configuration?.externalUrls.locationPage.nl
      }
    }
  }
}

/**
 * List of the endpoints of all available pages
 */
export const ROUTES = {
  ...publicPages
}

function initRoutes (): Array<RouteConfig> {
  return [
    {
      path: i18n.t('pageUrls.selectionGuide').toString(),
      name: ROUTES.selectionGuide,
      component: SelectionGuidePage,
      beforeEnter: (to, from, next) => {
        if (to.query && Object.keys(to.query).length > 0) {
          validateAndSaveQueryParameters({
            postalCode: to.query.postalcode as string,
            dateOfBirth: to.query.dateofbirth as string,
            startDateOfDayCare: to.query.startdateofdaycare as string,
            schoolLocality: to.query.schoollocality as string
          })
          next({ path: to.path, query: undefined })
        } else {
          next()
        }
      }
    },
    {
      path: i18n.t('pageUrls.selectionGuideWithPostalCode').toString(),
      name: ROUTES.selectionGuide,
      component: SelectionGuidePage,
      beforeEnter: (to, from, next) => {
        const postalCode = to.params.postalCode
        validatePostalCode(postalCode)
          .then(isValidPostalCode => {
            if (isValidPostalCode) {
              next({ name: ROUTES.selectionGuide })
            } else {
              const postalCodeService = container.get<PostalCodeService>(SERVICE_IDENTIFIERS.IPostalCodeService)
              const isValidFormat = postalCodeService.isPostalCodeFormatValid(postalCode)
              if (isValidFormat) {
                next({ name: ROUTES.selectionGuide })
              } else {
                next({ name: ROUTES.notFound })
              }
            }
          })
          .catch(() => next({ name: ROUTES.selectionGuide }))
      }
    },
    {
      path: i18n.t('pageUrls.schoolSelectionPage').toString(),
      name: ROUTES.schoolSelectionPage,
      component: SchoolSelectionPage,
      beforeEnter: (to, from, next) => {
        if (!store.state.userSelection.formState.postalCode?.postcode) {
          next({ name: ROUTES.selectionGuide })
        } else {
          next()
        }
      }
    },
    {
      path: `/${i18n.t('pageUrls.locations').toString()}/:postalCode/:serviceKind`,
      name: ROUTES.locationsWithPostalCode,
      component: ServiceOverviewPage,
      beforeEnter: (to, from, next) => {
        const postalCode = to.params.postalCode
        validatePostalCode(postalCode)
          .then(isValidPostalCode => {
            if (isValidPostalCode) {
              const params = isValidServiceKind(to.params.serviceKind) ? { serviceKind: to.params.serviceKind } : undefined
              if (!params) {
                store.state.userSelection.formState.selectedServiceKind = undefined
              }
              next()
            } else {
              next({ name: ROUTES.notFound })
            }
          })
          .catch(() => next({ name: ROUTES.selectionGuide }))
      },
      props: route => ({ serviceKind: getServiceKindFromParam(route.params.serviceKind), locality: store.state.userSelection.formState.postalCode?.city })
    },
    {
      path: `/${i18n.t('pageUrls.locations').toString()}/:serviceKind?`,
      name: ROUTES.locations,
      component: ServiceOverviewPage,
      beforeEnter: (to, from, next) => {
        if (to.params.serviceKind && !isValidServiceKind(to.params.serviceKind)) {
          next({ name: ROUTES.notFound })
          return
        }

        if (!store.state.userSelection.formState.postalCode?.postcode) {
          next({ name: ROUTES.selectionGuide })
        }

        if (!store.state.userSelection.formState.postalCodeValidationState && store.state.userSelection.formState.postalCode && store.state.userSelection.formState.postalCode.postcode) {
          validatePostalCode(store.state.userSelection.formState.postalCode.postcode)
        }

        next()
      },
      props: route => ({ serviceKind: getServiceKindFromParam(route.params.serviceKind), locality: store.state.userSelection.formState.postalCode?.city })
    },
    {
      path: i18n.t('pageUrls.locationDetail').toString(),
      name: ROUTES.locationDetail,
      component: ServiceDetailPage,
      props: true
    },
    {
      path: '/location/:mdmId/:careType',
      name: ROUTES.locationMdmId,
      component: ServiceDetailPage,
      props: true,
      beforeEnter: async (to, from, next) => {
        // KF-3844 TODO: set this locale back to 'en' after english translations have been added
        i18n.locale = 'nl'
        LocationDetailMdmidGuard(to, from, next)
      }
    },
    {
      path: '/locatie/:mdmId/:careType',
      name: ROUTES.locatieMdmId,
      component: ServiceDetailPage,
      props: true,
      beforeEnter: async (to, from, next) => {
        i18n.locale = 'nl'
        LocationDetailMdmidGuard(to, from, next)
      }
    },
    {
      path: `${i18n.t('pageUrls.orderOverview').toString()}/:bookingHash?`,
      name: ROUTES.orderOverview,
      component: OrderOverviewWrapperPage,
      beforeEnter: (to, from, next) => {
        if ((store.getters[`${NAMESPACES.userSelection}/${GETTERS.userSelection.isFormStateSpecifiedForServiceKind}`]() && !store.state.userSelection.formState.isAcceptingWaitinglist) ||
            (to.params.bookingHash && store.state.userSelection.formState.isAcceptingWaitinglist)) {
          next()
        } else {
          next({ name: ROUTES.locationDetail, params: { serviceSlug: to.params.serviceSlug } })
        }
      },
      props: true
    },
    {
      path: `${i18n.t('pageUrls.orderContactInfo').toString()}/:bookingHash?`,
      name: ROUTES.orderContactInfo,
      component: OrderContactInfoPage,
      beforeEnter: (to, from, next) => {
        if (store.getters[`${NAMESPACES.userSelection}/${GETTERS.userSelection.isFormStateSpecifiedForServiceKind}`]() || to.params.bookingHash) {
          next()
        } else {
          next({ name: ROUTES.orderOverview, params: { serviceSlug: to.params.serviceSlug } })
        }
      },
      props: true
    },
    {
      path: `${i18n.t('pageUrls.orderContactInfoCheck').toString()}/:bookingHash?`,
      name: ROUTES.contactInfoCheckPage,
      component: ContactInfoCheckPage,
      beforeEnter: (to, from, next) => {
        if ((store.getters[`${NAMESPACES.contactDetails}/${GETTERS.contactDetails.isFormStateSpecified}`] && store.getters[`${NAMESPACES.userSelection}/${GETTERS.userSelection.isFormStateSpecifiedForServiceKind}`]) || to.params.bookingHash) {
          next()
        } else {
          next({ name: ROUTES.orderContactInfo, params: { serviceSlug: to.params.serviceSlug } })
        }
      },
      props: true
    },
    {
      path: i18n.t('pageUrls.bookingStatus').toString(),
      name: ROUTES.bookingStatus,
      component: BookingStatusPage,
      props: route => Object.assign({}, route.query, route.params)
    },
    {
      path: i18n.t('pageUrls.signContract').toString(),
      name: ROUTES.signContract,
      component: SignContractPage,
      props: route => Object.assign({}, route.query, route.params)
    },
    {
      path: i18n.t('pageUrls.emailConfirm').toString(),
      name: ROUTES.confirmEmail,
      component: EmailConfirmationPage,
      props: true
    },
    {
      path: i18n.t('pageUrls.waitingList').toString(),
      component: WaitingListFlowBasePage,
      props: true,
      children: [
        {
          path: '',
          redirect: { name: ROUTES.notFound }
        },
        {
          path: i18n.t('pageUrls.waitingListChildren.cancel').toString(),
          name: ROUTES.waitingListCancel,
          component: WaitingListCancellationContent,
          props: true
        },
        {
          path: i18n.t('pageUrls.waitingListChildren.cancelSuccess').toString(),
          name: ROUTES.waitingListCancelSuccess,
          component: CancelSuccessStatusContent
        },
        {
          path: i18n.t('pageUrls.waitingListChildren.reactionReceived').toString(),
          name: ROUTES.waitingListReactionReceived,
          component: ReactionReceivedStatusContent
        },
        {
          path: i18n.t('pageUrls.waitingListChildren.booked').toString(),
          name: ROUTES.waitingListBooked,
          component: WaitingListsBookedContent
        },
        {
          path: i18n.t('pageUrls.waitingListChildren.requiresPlanner').toString(),
          name: ROUTES.waitinglistRequiresPlanner,
          component: WaitingListBookingRequiresPlanner
        },
        {
          path: i18n.t('pageUrls.waitingListChildren.offerExpired').toString(),
          name: ROUTES.waitingListOfferExpired,
          component: OfferExpiredStatusContent,
          props: route => Object.assign({}, route.query, route.params)
        },
        {
          path: i18n.t('pageUrls.waitingListChildren.unavailable').toString(),
          name: ROUTES.waitingListUnavailable,
          component: WaitingListUnavailableStatusContent
        },
        {
          path: i18n.t('pageUrls.waitingListChildren.extend').toString(),
          name: ROUTES.waitingListExtend,
          component: WaitingListExtendContent,
          props: true
        }
      ]
    },
    {
      path: i18n.t('pageUrls.shareWithData').toString(),
      name: ROUTES.share,
      component: SharedPage,
      props: true
    },
    {
      path: '/dev/components',
      name: ROUTES.components,
      component: ComponentsPage
    },
    {
      path: '*',
      name: ROUTES.notFound,
      component: NotFoundPage
    }
  ]
}

export function validateAndSaveQueryParameters (queryParams: {postalCode: string, dateOfBirth: string, startDateOfDayCare: string, schoolLocality : string }) : void {
  const newParams = JSON.stringify(queryParams)
  const oldParams = sessionStorage.getItem('userSelectionParams')

  if (oldParams !== newParams) {
    sessionStorage.setItem('userSelectionParams', newParams)
    validatePostalCode(queryParams.postalCode)
    validateSchoolLocality(queryParams.schoolLocality)
    validateDates(queryParams.dateOfBirth, queryParams.startDateOfDayCare)
  }
}

async function validatePostalCode (postalCode: string) : Promise<boolean> {
  let isCorrectPostalCode = false

  if (postalCode && store.state.userSelection.formState.postalCode) {
    store.state.userSelection.formState.postalCode.isLoading = true

    const postalCodeService = container.get<PostalCodeService>(SERVICE_IDENTIFIERS.IPostalCodeService)
    const isValidFormat = postalCodeService.isPostalCodeFormatValid(postalCode)
    if (isValidFormat) {
      const postalCodeResponse = await postalCodeService.getPostalCodeInfo({ postalCode })
      if (postalCodeResponse.errorCode === PostalCodeServiceError.notFound || postalCodeResponse.errorCode === PostalCodeServiceError.isPostOfficeBox) {
        store.state.userSelection.formState.postalCode = {} as PostalCodeInfo
        store.state.userSelection.formState.postalCodeValidationState = { isValid: false, pending: false }
        return false
      }
      if (postalCodeResponse) {
        store.state.userSelection.formState.postalCode = postalCodeResponse.result as PostalCodeInfo
        store.state.userSelection.formState.postalCodeValidationState = { isValid: true, pending: false }
        isCorrectPostalCode = true
      }
    }

    store.state.userSelection.formState.postalCode.isLoading = false
  }

  return isCorrectPostalCode
}

async function validateSchoolLocality (schoolLocality: string) : Promise<void> {
  if (schoolLocality) {
    const schoolService = container.get<SchoolServiceService>(SERVICE_IDENTIFIERS.ISchoolServiceService)
    const schools = await schoolService.getSchoolByLocality(schoolLocality.toUpperCase())
    if (schools.length > 0) {
      store.state.userSelection.formState.selectedSchoolLocality = schoolLocality.toUpperCase()
    }
  }
}

function validateDates (dateOfBirthString: string, startDateOfDayCareString: string) : void {
  const dateOfBirth = moment(dateOfBirthString, 'DD-MM-YYYY')
  const startDateOfDayCare = moment(startDateOfDayCareString, 'DD-MM-YYYY')

  if (dateOfBirth.isValid()) {
    if (startDateOfDayCare.isValid()) {
      if (moment(getStartDateOfCareMinDate(dateOfBirth.toDate())).isSameOrBefore(startDateOfDayCare) && moment(getStartDateOfCareMaxDate(dateOfBirth.toDate())).isSameOrAfter(startDateOfDayCare)) {
        store.state.userSelection.formState.startDateOfDayCare = startDateOfDayCare.toDate()
      } else {
        store.state.userSelection.formState.startDateOfDayCare = undefined
      }
    }
    store.state.userSelection.formState.dateOfBirth = dateOfBirth.toDate()
  } else if (startDateOfDayCare.isValid() && startDateOfDayCareString) {
    const min = getStartDateOfCareMinDate(store.state.userSelection.formState.dateOfBirth)
    const max = getStartDateOfCareMaxDate(store.state.userSelection.formState.dateOfBirth)

    if ((!min || moment(min).isSameOrBefore(startDateOfDayCare)) && (!max || moment(max).isSameOrAfter(startDateOfDayCare))) {
      store.state.userSelection.formState.startDateOfDayCare = startDateOfDayCare.toDate()
    }
  }
}

const serviceKindDayCareText = i18n.t('pageUrls.serviceKindDayCareSuffix').toString()
const serviceKindSchoolCareText = i18n.t('pageUrls.serviceKindSchoolCareSuffix').toString()

function isValidServiceKind (value: string) : boolean {
  return value === serviceKindDayCareText || value === serviceKindSchoolCareText
}

function getServiceKindFromParam (value: string) : ServiceKind | undefined {
  switch (value) {
  case serviceKindDayCareText: return ServiceKind.DayCare
  case serviceKindSchoolCareText: return ServiceKind.SchoolCare
  default: return undefined
  }
}

export function getServiceKindParamFromServiceKind (serviceKind: ServiceKind) : string {
  switch (serviceKind) {
  case ServiceKind.DayCare: return serviceKindDayCareText
  case ServiceKind.SchoolCare: return serviceKindSchoolCareText
  default: return ''
  }
}

const routes = initRoutes()
export default routes

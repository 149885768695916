import Vue from 'vue'
import { MutationTree } from 'vuex'
import ICalculatorConstants from './ICalculatorConstants'
import ICalculatorFormState from './ICalculatorFormState'
import ICalculatorState from './ICalculatorState'

export const MUTATIONS = {
  setFormState: 'setFormState',
  setConstants: 'setConstants'
}

export function mutations (): MutationTree<ICalculatorState> {
  return {
    setFormState (state: ICalculatorState, formState: Partial<ICalculatorFormState>) {
      const formIsValid = Boolean(state.formState.cumulativeIncomePerYear)
      Vue.set(state, 'formState', { ...state.formState, ...{ ...formState, formIsValid } })
    },
    setConstants (state: ICalculatorState, constants: Partial<ICalculatorConstants>) {
      Vue.set(state, 'constants', { ...state.constants, ...constants })
    }
  }
}

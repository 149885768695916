
import { Component, Prop, VModel, Vue } from 'vue-property-decorator'

@Component
export default class PartouButtonToggle extends Vue {
  @VModel({ required: true })
  vModel!: string

  @Prop({ required: false, default: true })
  isRequired? : boolean

  checkRequiredRule (value: string): boolean | string {
    const errorMsgIfNotValid = this.$t('form.required').toString()
    return !!value || errorMsgIfNotValid
  }
}

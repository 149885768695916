
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({})
export default class ChildBenefitSummaryHeader extends Vue {
  @Prop({ required: false })
  showDescription!: string

  @Prop({ required: false })
  showDisclaimer!: boolean
}

import Vue from 'vue'
import { MutationTree } from 'vuex'
import IContactDetailsFormState from './IContactDetailsFormState'
import IContactDetailsState from './IContactDetailsState'
import { state as createDefaultState } from './index'

export const MUTATIONS = {
  setFormState: 'setFormState',
  setState: 'setState',
  resetState: 'resetState',
  setEmail: 'setEmail',
  setChildDateOfBirth: 'setChildDateOfBirth',
  setChildsBsn: 'setChildsBsn'
}

export function mutations (): MutationTree<IContactDetailsState> {
  return {
    setFormState (state: IContactDetailsState, formState: Partial<IContactDetailsFormState>) {
      Vue.set(state, 'formState', { ...state.formState, ...formState })
    },
    setState (state: IContactDetailsState, newState: IContactDetailsState) {
      Object.assign(state, { ...state, ...newState })
    },
    resetState (state: IContactDetailsState) {
      Object.assign(state, createDefaultState())
    },
    setEmail: (state : IContactDetailsState, email : string) => {
      const careTakers = [...state.formState.careTakers]
      if (careTakers[0]) {
        careTakers[0].emailAddress = email
      }
      Vue.set(state.formState, 'careTakers', careTakers)
    },
    setChildsBsn: (state : IContactDetailsState, bsn : string) => {
      Vue.set(state.formState.child, 'bsn', bsn)
    },
    setChildDateOfBirth (state : IContactDetailsState, dateOfBirth : Date) {
      if (state.formState.child) {
        Vue.set(state.formState.child, 'dateOfBirth', dateOfBirth)
      }
    }
  }
}


import { Component, Emit, Prop, VModel, Vue } from 'vue-property-decorator'
import PartouFloatingCircleButton from '@/components/PartouComponents/Buttons/PartouFloatingCircleButton.vue'
import PartouBasicDialog from '@/components/PartouComponents/PartouBasicDialog.vue'

@Component({ components: { PartouFloatingCircleButton, PartouBasicDialog } })
export default class PartouNotify extends Vue {
  @VModel({ required: true })
  showDialog! : boolean

  @Prop({ required: false, default: false })
  persistent! : boolean

  @Prop({ required: false, default: '306' })
  width! : string

  @Prop({ required: false, default: '' })
  className? : string

  @Prop({ required: false, default: true })
  scroll!: boolean

  @Prop({ required: false, default: true })
  showCloseButton!: boolean

  closeOnClickOutside = false

  @Emit('closeDialog')
  closeDialog () : void {
    this.showDialog = false
  }
}

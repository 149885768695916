
import DayCheckboxState from '@/components/InputFields/DaySelector/DayCheckboxState'
import DaySelector from '@/components/InputFields/DaySelector/DaySelector.vue'
import PartouCheckBox from '@/components/PartouComponents/Input/PartouCheckBox/PartouCheckBox.vue'
import PartouLoader from '@/components/PartouComponents/PartouLoader.vue'
import { ServiceKind, ServiceVarietyName } from '@/models'
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { NAMESPACES, ACTIONS, STATE } from '@/store'
import DaySelectionState from '@/models/types/DaySelectionState'
import PartouNotify from '@/components/PartouComponents/PartouNotify.vue'
import IUserSelectionFormState from '@/store/modules/userSelection/IUserSelectionFormState'
import MultiStateDaySelectorComponent from './Components/MultiStateDaySelectorComponent.vue'

const userSelectionModule = namespace(NAMESPACES.userSelection)

@Component({
  components: { PartouCheckBox, DaySelector, PartouLoader, PartouNotify, MultiStateDaySelectorComponent }
})
export default class MultiStateDaySelector extends Vue {
  @Prop({ required: true, default: () => [] })
  value! : Record<ServiceVarietyName, DayCheckboxState[]>

  @Watch('value')
  onValueChanged () : void {
    this.createInitialCheckboxState()
    this.checkRequired()
  }

  @Prop({ required: true })
  serviceKind!: ServiceKind

  @Prop({ required: false })
  serviceVarieties?: ServiceVarietyName[]

  @Prop({ required: false })
  title? : string

  @Prop({ required: false, default: false })
  isReadOnly! : boolean

  @Prop({ required: false, default: false })
  showCheckboxesBasedOnServiceKind! : boolean

  @Prop({ required: false, default: false })
  isRequired! : boolean

  @Prop({ required: false, default: false })
  mustSelectOne!: boolean

  @Prop({ required: false, default: false })
  isLoading!: boolean

  @Prop({ required: false, default: false })
  withPriority! : boolean

  @Prop({ required: false, default: false })
  isWaitingListOnly!: boolean

  @Prop({ required: false, default: false })
  validateOnRender! : boolean

  @Prop({ required: false, default: false })
  showNoSelectionError! : boolean

  @Prop({ required: false, default: true })
  alignCenter! : boolean

  @userSelectionModule.Action(ACTIONS.userSelection.setServiceVarietyDaysAsync)
  setServiceVarietyDaysAsync!: (input : { serviceVariety : ServiceVarietyName, days : DaySelectionState[] }) => Promise<void>

  @userSelectionModule.State(STATE.userSelection.formState)
  userSelectionFormState!: IUserSelectionFormState

  checkboxesStates : Record<ServiceVarietyName, DayCheckboxState[]> = { KDV: [], NSO: [], VSO: [] }
  ServiceKind = ServiceKind
  rules = [this.checkRequired]
  textValue = ''
  isValid = false
  errorTranslationString?: string
  numberOfSelectedDays = 0
  multipleServiceVarietyDaysSelected = false
  firstRender = true
  isInfoVisible = false
  serviceVarietiesInfo: { serviceVariety: ServiceVarietyName, notLoadingText: string }[] = []
  infoServiceVarietyName: ServiceVarietyName = ServiceVarietyName.KDV
  ServiceVarietyName = ServiceVarietyName

  mounted () : void {
    this.serviceVarietiesInfo = [
      { serviceVariety: ServiceVarietyName.NSO, notLoadingText: this.$t('serviceDetailPage.multiStateDaySelector.afterSchoolCare').toString() },
      { serviceVariety: ServiceVarietyName.VSO, notLoadingText: this.$t('serviceDetailPage.multiStateDaySelector.beforeSchoolCare').toString() },
      { serviceVariety: ServiceVarietyName.KDV, notLoadingText: this.$t('serviceDetailPage.multiStateDaySelector.dayCare').toString() }
    ]
    if (this.getServiceVarieties.some(x => x === ServiceVarietyName.KDV)) {
      this.firstRender = (this.checkboxesStates.KDV?.filter(d => d.isChecked).length === 0)
    } else {
      this.firstRender = (this.checkboxesStates.NSO?.filter(d => d.isChecked).length === 0 && this.checkboxesStates.VSO?.filter(d => d.isChecked).length === 0)
    }
  }

  checkRequired (): boolean | string {
    if ((!this.isRequired || (this.firstRender && !this.validateOnRender)) && !this.showNoSelectionError) {
      this.isValid = true
    } else if (this.serviceKind === ServiceKind.SchoolCare) {
      this.isValid = this.checkboxesStates?.NSO?.some(d => d.isChecked) ?? false
    } else if (this.serviceKind === ServiceKind.DayCare) {
      this.isValid = this.checkboxesStates?.KDV?.some(d => d.isChecked) ?? false
    }
    return this.isValid
  }

  beforeMount () : void {
    this.createInitialCheckboxState()
  }

  getNrOfChecked (serviceVarietyName: ServiceVarietyName) : number {
    if (!this.checkboxesStates) {
      return 0
    }

    return this.checkboxesStates[serviceVarietyName]?.filter(d => d.isChecked).length ?? 0
  }

  createInitialCheckboxState () : void {
    this.checkboxesStates = {
      KDV: this.createNewDayArray(this.value.KDV),
      NSO: this.createNewDayArray(this.value.NSO),
      VSO: this.createNewDayArray(this.value.VSO)
    }

    if (this.serviceKind === ServiceKind.SchoolCare) {
      const nrOfCheckedVSO = this.getNrOfChecked(ServiceVarietyName.VSO)
      const nrOfCheckedNSO = this.getNrOfChecked(ServiceVarietyName.NSO)
      this.numberOfSelectedDays = nrOfCheckedNSO
      this.multipleServiceVarietyDaysSelected = nrOfCheckedVSO > 0 && nrOfCheckedNSO > 0
    } else if (this.serviceKind === ServiceKind.DayCare) {
      this.numberOfSelectedDays = this.getNrOfChecked(ServiceVarietyName.KDV)
    }
  }

  @Emit('onDaySelectorChanged')
  onDaySelectorChanged (serviceVariety: ServiceVarietyName, dayCheckboxState: DayCheckboxState[]) : Partial<Record<ServiceVarietyName, DayCheckboxState[]>> | undefined {
    this.setServiceVarietyDaysAsync({ serviceVariety, days: dayCheckboxState })
    this.checkboxesStates[serviceVariety] = dayCheckboxState
    return this.checkboxesStates
  }

  onDayChange (numberOfDays : number) : void {
    this.numberOfSelectedDays = numberOfDays
    this.firstRender = false
    this.checkRequired()
  }

  createNewDayArray (oldDayArray: DayCheckboxState[]) : DayCheckboxState[] {
    return oldDayArray && Array.from(oldDayArray.map(checkbox => Object.assign({}, checkbox)))
  }

  onInfoClicked (serviceVarietyName: ServiceVarietyName) : void {
    this.infoServiceVarietyName = serviceVarietyName
    this.isInfoVisible = true
  }

  get getServiceVarieties () : Array<ServiceVarietyName> {
    if (!this.showCheckboxesBasedOnServiceKind) {
      return this.serviceVarieties ?? []
    } else if (this.serviceKind === ServiceKind.DayCare) {
      return [ServiceVarietyName.KDV]
    } else {
      return [ServiceVarietyName.NSO, ServiceVarietyName.VSO]
    }
  }

  get isUnknownServiceVariety () : boolean {
    return !this.showCheckboxesBasedOnServiceKind && this.serviceVarieties?.length === 0
  }

  getFilteredServiceVarietiesInfo (serviceVarietyNames: ServiceVarietyName[]) {
    return this.serviceVarietiesInfo.filter(variety => this.getServiceVarieties.includes(variety.serviceVariety) && serviceVarietyNames.includes(variety.serviceVariety))
  }

  getHeaderText (serviceVarietyName: ServiceVarietyName) : string {
    switch (serviceVarietyName) {
    case ServiceVarietyName.KDV:
      return 'serviceDetailPage.multiStateDaySelector.popup.kdvHeader'
    case ServiceVarietyName.NSO:
      return 'serviceDetailPage.multiStateDaySelector.popup.nsoHeader'
    case ServiceVarietyName.VSO:
      return 'serviceDetailPage.multiStateDaySelector.popup.vsoHeader'
    }
  }

  getMessage (serviceVarietyName: ServiceVarietyName) : string {
    switch (serviceVarietyName) {
    case ServiceVarietyName.KDV:
      return 'serviceDetailPage.multiStateDaySelector.popup.kdvMessage'
    case ServiceVarietyName.NSO:
      return 'serviceDetailPage.multiStateDaySelector.popup.nsoMessage'
    case ServiceVarietyName.VSO:
      return 'serviceDetailPage.multiStateDaySelector.popup.vsoMessage'
    }
  }
}

import { Module } from 'vuex'
import IState from '@/store/IState'
import IContactDetailsState, { STATE } from './IContactDetailsState'
import { mutations, MUTATIONS } from './contactDetailsMutations'
import { actions, ACTIONS } from './contactDetailsActions'
import { getters, GETTERS } from './contactDetailsGetters'

export function state (): IContactDetailsState {
  return {
    formState: {
      careTakers: [{
        initials: '',
        firstName: '',
        middleName: '',
        lastName: '',
        postalCode: '',
        houseNumber: '',
        houseNumberAddition: '',
        street: '',
        locality: '',
        emailAddress: '',
        phoneNumber: '',
        bsn: ''
      }],
      child: { initials: '', firstName: '', middleName: '', lastName: '', bsn: '', gender: '', dateOfBirth: new Date() },
      agreedToTerms: false
    }
  }
}

export function module (): Module<IContactDetailsState, IState> {
  return {
    namespaced: true,
    state: state(),
    mutations: mutations(),
    actions: actions(),
    getters: getters()
  }
}
export const NAMESPACE = 'contactDetails'
export { ACTIONS }
export { MUTATIONS }
export { GETTERS }
export { STATE }


import { Component, Vue } from 'vue-property-decorator'
import PartouSocials from '@/components/PartouComponents/PartouSocials.vue'
import { namespace } from 'vuex-class'
import { NAMESPACES, STATE } from '@/store'

const configurationModule = namespace(NAMESPACES.configuration)

@Component({
  components: { PartouSocials }
})
export default class WaitingListFooter extends Vue {
  @configurationModule.State(STATE.configuration.configuration)
  configuration! : Record<string, any> /* Disabled because of any */// eslint-disable-line
}

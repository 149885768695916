import ApolloClient from 'apollo-client'
import { inject, injectable } from 'inversify'
import IProductService from './IProductService'
import { GetActivatedServiceProductsDocument, GetActivatedServiceProductsQuery, ServiceProduct } from '@/models'
import { ProductCatagoryViewMapper } from './ProductCatagoryViewMapper'
import { ProductCategoryViewData } from './ProductCategoryViewData'

@injectable()
export default class ProductService implements IProductService {
  private readonly apollo: ApolloClient<unknown>
  constructor (@inject(ApolloClient) apollo: ApolloClient<unknown>) {
    this.apollo = apollo
  }

  async getActivatedServiceProducts (serviceId: string, startDate: Date): Promise<ProductCategoryViewData[]> {
    const result = await this.apollo.query<GetActivatedServiceProductsQuery>({
      query: GetActivatedServiceProductsDocument,
      variables: { serviceId, startDate }
    })

    const serviceProducts = result.data.service_product as Partial<ServiceProduct>[]
    return serviceProducts.length !== 0 ? new ProductCatagoryViewMapper(serviceProducts).productCategories : []
  }
}


import PartouCard from '@/components/PartouComponents/PartouCard.vue'
import IContactDetailCareTaker from '@/store/modules/contactDetails/IContactDetailCareTaker'
import moment from 'moment'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: { PartouCard }
})
export default class CareTakerCard extends Vue {
  @Prop({ required: true })
  careTaker! : IContactDetailCareTaker

  formatDateOfBirth (dateOfBirth : string) : string {
    return moment(dateOfBirth).format('DD - MM - YYYY')
  }
}

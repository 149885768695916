
import Page from '@/pages/Page'
import PageHeader from '@/components/PageHeader/PageHeader.vue'
import PartouButton from '@/components/PartouComponents/Buttons/PartouButton.vue'
import ChildBenefitSummaryTable from '@/components/ChildBenefitCalculator/ChildBenefitSummaryTable.vue'
import ChildBenefitCalculatorInputForm from '@/components/ChildBenefitCalculator/ChildBenefitCalculatorInputForm/ChildBenefitCalculatorInputForm.vue'
import { ServiceKind } from '@/models'
import { determineTypeOfCare } from '@/models/enums/ServiceKind'
import SelectionGuideForm from '@/pages/SelectionFlow/SelectionGuidePage/SelectionGuideForm/SelectionGuideForm.vue'
import { ROUTES } from '@/router/routes'
import { MUTATIONS, NAMESPACES, STATE } from '@/store'
import IUserSelectionFormState from '@/store/modules/userSelection/IUserSelectionFormState'
import Vue from 'vue'
import { Component, Ref, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import RawFakeLink from '@/components/Generic/RawFakeLink.vue'
import i18n from '@/plugins/i18n'
import { RawLocation } from 'vue-router'
import ChildBenefitCalculatorWithoutProposition from '@/components/ChildBenefitCalculator/ChildBenefitCalculatorWithoutProposition.vue'

const userSelectionModule = namespace(NAMESPACES.userSelection)

@Component({
  components: { ChildBenefitCalculatorWithoutProposition, ChildBenefitSummaryTable, ChildBenefitCalculatorInputForm, SelectionGuideForm, PartouButton, PageHeader, RawFakeLink },
  metaInfo () {
    return {
      title: (this as any).pageTitle, // eslint-disable-line @typescript-eslint/no-explicit-any
      meta: [
        { property: 'title', content: (this as any).pageTitle }, // eslint-disable-line @typescript-eslint/no-explicit-any
        { name: 'description', content: (this as any).metaDescription }, // eslint-disable-line @typescript-eslint/no-explicit-any
        { name: 'robots', content: 'index, follow' }
      ]
    }
  }
})
export default class SelectionGuidePage extends Vue implements Page {
  @Ref('form')
  SelectionGuideForm! : HTMLFormElement

  pageTitle = i18n.t('pageTitles.selectionGuide').toString()
  metaDescription = i18n.t('pageDescriptions.selectionGuide').toString()

  @userSelectionModule.State(STATE.userSelection.formState)
  userSelectionFormState!: IUserSelectionFormState

  @userSelectionModule.Mutation(MUTATIONS.userSelection.setCurrentStep)
  setCurrentStep!: (currentStep: number) => void

  isValid = false
  isValidatingPostalCode = false
  showNoDaySelectionError = false
  afterValidation? : (isValid: boolean) => void

  onIsValidUpdated (isValid : boolean) : void {
    this.isValid = isValid
  }

  getNextRoute (serviceKind : ServiceKind | undefined) : RawLocation {
    switch (serviceKind) {
    case ServiceKind.SchoolCare:
      return { name: ROUTES.schoolSelectionPage }
    case ServiceKind.DayCare:
      if (!this.userSelectionFormState.postalCode?.postcode) {
        return ''
      }
      return { name: ROUTES.locations, params: { postalCode: this.userSelectionFormState.postalCode.postcode, serviceKind: i18n.t('pageUrls.serviceKindDayCareSuffix').toString() } }
    default:
      return ''
    }
  }

  get serviceKind () : ServiceKind | undefined {
    let selectedServiceKind: ServiceKind | undefined
    if (this.userSelectionFormState.dateOfBirth && this.userSelectionFormState.startDateOfDayCare) {
      selectedServiceKind = determineTypeOfCare(this.userSelectionFormState.dateOfBirth, this.userSelectionFormState.startDateOfDayCare)
    }
    return selectedServiceKind
  }

  @Watch('userSelectionFormState', { deep: true })
  onUserSelectionFormStateChanged (): void {
    if (this.isValidatingPostalCode && this.userSelectionFormState.postalCodeValidationState && !this.userSelectionFormState.postalCodeValidationState.pending) {
      this.isValidatingPostalCode = false
      this.onMyLocationsClicked()
    }
  }

  onMyLocationsClicked (): void {
    if (this.userSelectionFormState.postalCodeValidationState?.pending) {
      this.isValidatingPostalCode = true
    }

    const isValid = this.SelectionGuideForm.validate()
    if (isValid && this.userSelectionFormState.postalCodeValidationState?.isValid) {
      if (this.serviceKind === ServiceKind.DayCare) {
        this.setCurrentStep(2)
      }
      this.$router.push(this.getNextRoute(this.serviceKind))
    } else {
      this.showNoDaySelectionError = true
      this.scrollToInvalidElement(this.SelectionGuideForm)
    }
  }

  scrollToInvalidElement (form: HTMLFormElement): boolean {
    if (form) {
      const formElements = form.$children as HTMLFormElement[]
      const invalidFormElements = formElements.filter(x => x.isComponentValid !== undefined ? !x.isComponentValid : !x.inputValue && (x.$vnode.componentOptions.tag === 'PartouTextField' || x.$vnode.componentOptions.tag === 'DateInputField'))
      if (invalidFormElements.length > 0) {
        invalidFormElements[0]?.$el.scrollIntoView({ behavior: 'auto', block: 'center', inline: 'center' })
        return true
      }
    }

    return false
  }
}

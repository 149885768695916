import { Commit } from 'vuex'
import IUserSelectionState from '../IUserSelectionState'
import { ServiceKind, ServiceVarietyName } from '@/models'
import DaySelectionState from '@/models/types/DaySelectionState'
import DayCheckboxState, { mergeDaySelectionStateIntoDayCheckboxState } from '@/components/InputFields/DaySelector/DayCheckboxState'
import { MUTATIONS } from '../userSelectionMutations'
import { resetOrCopySelectedServiceVarietyDays } from '../userSelectionActions'

export function setDayCheckboxStateWaitingListProposition (commit : Commit, state : IUserSelectionState, getters : any, input : { serviceVariety : ServiceVarietyName, days : DaySelectionState[] }) {  /* Disabled because of any: cant get correct class type for GetterTree */// eslint-disable-line
  const waitinglistPropostionDays = getters.getDayCheckboxStateWaitingListProposition() as Record<ServiceVarietyName, DayCheckboxState[]>
  waitinglistPropostionDays[input.serviceVariety] = mergeDaySelectionStateIntoDayCheckboxState(waitinglistPropostionDays[input.serviceVariety], input.days)

  if (state.formState.selectedServiceKind === ServiceKind.SchoolCare) {
    waitinglistPropostionDays.NSO.forEach((x, index) => {
      if (!x.isChecked && !x.isDisabled) {
        waitinglistPropostionDays.VSO[index].isChecked = false
      }
    })
  }
  commit(MUTATIONS.setServiceVarietyDaysAsync, waitinglistPropostionDays)

  const selectedServiceVarieties = []
  if (waitinglistPropostionDays.KDV.some(x => x.isChecked)) {
    selectedServiceVarieties.push(ServiceVarietyName.KDV)
  }

  if (waitinglistPropostionDays.VSO.some(x => x.isChecked)) {
    selectedServiceVarieties.push(ServiceVarietyName.VSO)
  }

  if (waitinglistPropostionDays.NSO.some(x => x.isChecked)) {
    selectedServiceVarieties.push(ServiceVarietyName.NSO)
  }

  commit(MUTATIONS.setFormState, resetOrCopySelectedServiceVarietyDays(state, selectedServiceVarieties as ServiceVarietyName[]))
}


import { Component, Prop, Vue } from 'vue-property-decorator'
import PartouButton from '@/components/PartouComponents/Buttons/PartouButton.vue'

@Component({
  components: { PartouButton }
})
export default class PartouCarousel extends Vue {
  @Prop({})
  items! : string[]

  @Prop({ required: false, default: true })
  showArrows!: boolean

  disableArrows = false

  mounted () : void {
    if (this.items.length === 1) {
      this.disableArrows = true
    }
  }
}

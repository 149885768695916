export class ProductViewData {
  constructor (id: string, startTime: string, endTime: string, pricePerHour: number, serviceVariety: string, textualAddition?: string) {
    this.id = id
    this.startTime = startTime
    this.endTime = endTime
    this.pricePerHour = pricePerHour
    this.serviceVariety = serviceVariety
    this.textualAddition = textualAddition
  }

    id!: string
    startTime!: string
    endTime!: string
    pricePerHour!: number
    serviceVariety!: string
    textualAddition?: string
}

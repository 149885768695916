import DaySelectionState from '@/models/types/DaySelectionState'
import DayCheckboxType from './DayCheckboxType'

type DayCheckboxState = DaySelectionState & {
  label?: string;
  type: DayCheckboxType;
  isReadOnly?: boolean;
  isDisabled?: boolean;
  startDate?: string;
}

export default DayCheckboxState

export function parseDaySelectionStateToDayCheckboxState (dayselectionState : DaySelectionState) : DayCheckboxState {
  return {
    priority: dayselectionState.priority,
    day: dayselectionState.day,
    type: DayCheckboxType.Check,
    isChecked: dayselectionState.isChecked
  }
}

export function parseDaySelectionStatesToDayCheckboxStates (dayselectionStates : DaySelectionState[]) : DayCheckboxState[] {
  return dayselectionStates.map((x : DaySelectionState) => {
    return {
      priority: x.priority,
      day: x.day,
      type: DayCheckboxType.Check,
      isChecked: x.isChecked
    }
  })
}

export function mergeDaySelectionStateIntoDayCheckboxState (daycheckboxState: DayCheckboxState[], dayselectionState : DaySelectionState[]) : DayCheckboxState[] {
  return daycheckboxState.map(x => {
    dayselectionState.forEach(y => {
      if (x.day === y.day) {
        x.isChecked = y.isChecked
        x.priority = y.priority
      }
    })
    return x
  })
}


import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import PageHeader from '@/components/PageHeader/PageHeader.vue'
import PartouFloatingCircleButton from '@/components/PartouComponents/Buttons/PartouFloatingCircleButton.vue'
import PartouButton from '@/components/PartouComponents/Buttons/PartouButton.vue'
import PartouNotify from '@/components/PartouComponents/PartouNotify.vue'
import PartouCard from '@/components/PartouComponents/PartouCard.vue'
import PartouTerms, { Term } from '@/components/PartouComponents/PartouTerms.vue'
import NewDaysCard from '@/features/ContactInfoCheckPage/NewDaysCard.vue'
import CareTakerCard from './CareTakerCard.vue'
import ChildCard from './ChildCard.vue'
import { ACTIONS, GETTERS, NAMESPACES, STATE } from '@/store'
import { namespace } from 'vuex-class'
import IContactDetailsFormState from '@/store/modules/contactDetails/IContactDetailsFormState'
import { ROUTES } from '@/router/routes'
import OfferNoLongerAvailableError from '@/services/BookingService/OfferNoLongerAvailableError'
import IUserSelectionFormState from '@/store/modules/userSelection/IUserSelectionFormState'
import { DayOfWeek, GetWaitingListStatusOutput, Proposition, ServiceKind, ServiceVarietyName } from '@/models'
import DayCheckboxState from '@/components/InputFields/DaySelector/DayCheckboxState'
import Page from '@/pages/Page'
import i18n from '@/plugins/i18n'
import SharingMenu from '@/components/SharingMenu/SharingMenu.vue'
import IBookPropositionState from '@/store/modules/proposition/IBookPropositionState'
import DaySelectionState from '@/models/types/DaySelectionState'
import IAcceptingWaitinglistPropositionState from '@/store/modules/proposition/IAcceptProposedWaitingListPropositionState'
import { sendConfirmRegistrationAnalyticsEvent } from '@/plugins/googleAnalytics/gtagFunctions'
import UnprocessableWaitingListError from '@/services/WaitingListService/UnprocessableWaitingListError'

const contactDetailsModule = namespace(NAMESPACES.contactDetails)
const userSelectionModule = namespace(NAMESPACES.userSelection)
const propositionModule = namespace(NAMESPACES.proposition)

@Component({
  components: { PartouTerms, NewDaysCard, ChildCard, CareTakerCard, PageHeader, PartouFloatingCircleButton, PartouButton, PartouCard, PartouNotify, SharingMenu },
  metaInfo () {
    return {
      title: (this as any).pageTitle, // eslint-disable-line @typescript-eslint/no-explicit-any
      meta: [
        { property: 'title', content: (this as any).pageTitle }, // eslint-disable-line @typescript-eslint/no-explicit-any
        { name: 'robots', content: 'noindex, nofollow' }
      ]
    }
  }
})
export default class ContactInfoCheckPage extends Vue implements Page {
  pageTitle = i18n.t('pageTitles.orderContactInfoCheck').toString()

  @userSelectionModule.State(STATE.userSelection.formState)
  userSelectionFormState!: IUserSelectionFormState

  @contactDetailsModule.State(STATE.contactDetails.formState)
  contactDetailsFormState!: IContactDetailsFormState

  @propositionModule.State(STATE.proposition.bookPropositionState)
  bookPropositionState?: IBookPropositionState

  @propositionModule.State(STATE.proposition.acceptProposedWaitingListPropositionState)
  acceptProposedWaitingListPropositionState?: IAcceptingWaitinglistPropositionState

  @propositionModule.State(STATE.proposition.waitinglistPropositionStatus)
  waitinglistPropositionStatus?: GetWaitingListStatusOutput

  @userSelectionModule.Action(ACTIONS.userSelection.resetStateAsync) resetUserSelectionStateAsync!: () => Promise<void>
  @contactDetailsModule.Action(ACTIONS.contactDetails.resetStateAsync) resetContactDetailStateAsync!: () => Promise<void>
  @propositionModule.Action(ACTIONS.proposition.acceptProposedWaitingListPropositionAsync) acceptProposedWaitingListPropositionAsync!: (payload: {bookingHash: string, days: Partial<Record<ServiceVarietyName, DaySelectionState[]>>, removeFromWaitingListDaysPerServiceVariety: Partial<Record<ServiceVarietyName, DayOfWeek[]>> | undefined, causedByReasonType: string | undefined, causedByReasonComment: string | undefined, userSelectionFormState: IUserSelectionFormState, contactDetailsFormState: IContactDetailsFormState}) => Promise<void>
  @propositionModule.Action(ACTIONS.proposition.bookPropositionAsync) bookPropositionAsync!: (payload: {proposition: Proposition, checkBoxDays: Record<ServiceVarietyName, DayCheckboxState[]>}) => Promise<void>
  @propositionModule.Action(ACTIONS.proposition.getBookPropositionResponseAsync) getBookPropositionResponseAsync!: () => Promise<void>
  @propositionModule.Action(ACTIONS.proposition.getAcceptProposedWaitingListPropositionResponseAsync) getAcceptProposedWaitingListPropositionResponseAsync!: () => Promise<void>

  @propositionModule.Getter(GETTERS.proposition.getPropositionById)
  getPropositionById!: (id: string) => Proposition | undefined

  @userSelectionModule.Getter(GETTERS.userSelection.getDayCheckboxState)
  getDayCheckboxState!: (withAvailability: boolean, withOpeningHours : boolean) => Record<ServiceVarietyName, DayCheckboxState[]>

  @Prop({ required: false })
  bookingHash!: string

  @Prop({ required: true })
  serviceSlug!: string

  formTerms: Term[] = []
  isValid = false
  showDialog = false
  messageTranslationString = ''

  get propositionCreationInProgress () : boolean {
    return (!!this.bookPropositionState?.pending || !!this.acceptProposedWaitingListPropositionState?.pending) ?? false
  }

  onAcceptCallback? : () => void

  created () : void {
    const termsLink = process.env.VUE_APP_TERMS_URL
    const privacyLink = process.env.VUE_APP_PRIVACY_URL
    this.formTerms = this.formTerms.concat([
      { label: this.$t('orderContactInfoFormPage.form.informationIsCorrect').toString(), isAccepted: false, isMandatory: true },
      { label: this.$t('orderContactInfoFormPage.form.agreedToPrivacyPolicy', { privacyLink }).toString(), isAccepted: false, isMandatory: true },
      { label: this.$t('orderContactInfoFormPage.form.agreedToTermsText', { termsLink }).toString(), isAccepted: false, isMandatory: true }
    ])

    if (this.userSelectionFormState.isExpansionWaitinglist) {
      this.formTerms.push(
        { label: this.$t('orderContactInfoFormPage.form.agreedToExpansionText').toString(), isAccepted: false, isMandatory: true }
      )
    }
  }

  mounted () : void {
    if (this.propositionCreationInProgress) {
      this.onOrderClickedAsync()
    }
  }

  async onOrderClickedAsync () : Promise<void> {
    try {
      if (this.propositionCreationInProgress) {
        await this.fetchPropositionResponseAsync()
      } else {
        if (this.userSelectionFormState.isAcceptingWaitinglist) {
          await this.tryAcceptProposedWaitingListPropositionAsync()
        } else {
          sendConfirmRegistrationAnalyticsEvent()
          await this.createReservationAsync()
        }
      }
    } catch (e) {
      if (e instanceof OfferNoLongerAvailableError || e instanceof UnprocessableWaitingListError) {
        const serviceKind = this.userSelectionFormState.selectedServiceKind === ServiceKind.DayCare
          ? i18n.t('pageUrls.serviceKindDayCareSuffix').toString()
          : i18n.t('pageUrls.serviceKindSchoolCareSuffix').toString()
        const postalCode = this.userSelectionFormState.postalCode?.postcode ?? ''
        this.showNotification('orderContactInfoFormPage.notification.errorSeatAlreadyTaken', () => {
          this.$router.push({ name: ROUTES.locations, params: { postalCode, serviceKind } })
        })
      } else {
        console.error('error', e)
      }
    }
  }

  private async tryAcceptProposedWaitingListPropositionAsync (): Promise<void> {
    await this.acceptProposedWaitingListPropositionAsync({
      bookingHash: this.bookingHash,
      days: this.userSelectionFormState.daysPerServiceVariety,
      removeFromWaitingListDaysPerServiceVariety: this.userSelectionFormState.removeFromWaitingListDaysPerServiceVariety,
      causedByReasonType: this.userSelectionFormState.causedByReasonType,
      causedByReasonComment: this.userSelectionFormState.causedByReasonComment,
      userSelectionFormState: this.userSelectionFormState,
      contactDetailsFormState: this.contactDetailsFormState
    })
    await this.awaitAcceptWaitingListResponse()
  }

  showNotification (translationString: string, callback?: () => void) : void {
    this.showDialog = true
    this.messageTranslationString = translationString
    this.onAcceptCallback = callback
  }

  @Watch('showDialog')
  onNotificationCancelled () : void {
    if (!this.showDialog && this.onAcceptCallback) {
      this.onAcceptCallback()
    }
  }

  async awaitAcceptWaitingListResponse () : Promise<void> {
    const response = await this.acceptProposedWaitingListPropositionState?.responseAsync
    if (response) {
      if (!response.isBookingProcessableByWhyPlan) {
        this.$router.push({ name: ROUTES.waitinglistRequiresPlanner })
        return
      }

      if (response.needsToSignExpansionContract) {
        this.$router.push({ name: ROUTES.signContract, params: { bookingHash: response.hash } })
      } else if (response.isBookingCompleted) {
        this.$router.push({ name: ROUTES.waitingListBooked })
      } else {
        this.$router.push({ name: ROUTES.bookingStatus, params: { bookingHash: response.hash } })
      }
    } else {
      this.showNotification('orderContactInfoFormPage.notification.error')
      console.error('Something went wrong when trying to get awaitAcceptWaitingListResponse response! response is undefined')
    }
  }

  async awaitUrlHashAsync () : Promise<void> {
    const urlHash = await this.bookPropositionState?.responseAsync
    if (!urlHash) {
      this.showNotification('orderContactInfoFormPage.notification.error')
      console.error('Something went wrong when trying to get createBooking response! urlHash is undefined')
      return
    }

    await this.resetUserSelectionStateAsync()
    this.$router.push({ name: ROUTES.bookingStatus, params: { bookingHash: urlHash } })
  }

  async fetchPropositionResponseAsync () : Promise<void> {
    if (this.userSelectionFormState.isAcceptingWaitinglist) {
      await this.getAcceptProposedWaitingListPropositionResponseAsync()
      await this.awaitAcceptWaitingListResponse()
    } else {
      await this.getBookPropositionResponseAsync()
      await this.awaitUrlHashAsync()
    }
  }

  async createReservationAsync () : Promise<void> {
    if (this.userSelectionFormState.selectedPropositionId) {
      const proposition = this.getPropositionById(this.userSelectionFormState.selectedPropositionId)
      if (proposition?.offerId && !this.propositionCreationInProgress) {
        await this.bookPropositionAsync({
          proposition,
          checkBoxDays: this.getDayCheckboxState(true, true)
        })

        await this.awaitUrlHashAsync()
      }
    }
  }

  onBackButtonClicked () : void {
    this.$router.push({ name: ROUTES.orderContactInfo, params: { bookingHash: this.bookingHash } })
  }

  onAnchorLinkClicked (anchor : string) : void {
    this.$router.push({ name: ROUTES.orderContactInfo, params: { anchor } })
  }

  get sharingTitle () : string {
    if (this.userSelectionFormState.selectedPropositionId) {
      return this.getPropositionById(this.userSelectionFormState.selectedPropositionId)?.name ?? ''
    } else {
      return ''
    }
  }
}


import { Component, Vue } from 'vue-property-decorator'
import PartouButton from '@/components/PartouComponents/Buttons/PartouButton.vue'

@Component({
  components: { PartouButton }
})
export default class CompletedContent extends Vue {
  onNavigateBackToHomeClicked () : void {
    this.$emit('onNavigateBackToHomeClicked')
  }
}


import { Component, Emit, Prop, Ref, VModel, Vue, Watch } from 'vue-property-decorator'

@Component({ })
export default class PartouBasicDialog extends Vue {
  @Ref('partou-custom-dialog')
  partouDialog!: HTMLElement

  @VModel({ required: true })
  showDialog! : boolean

  @Prop({ required: false, default: false })
  persistent! : boolean

  closeOnClickOutside = false

  @Watch('showDialog')
  toggleDialog () : void {
    if (this.showDialog) {
      setTimeout(() => {
        this.closeOnClickOutside = true
      }, 10)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if ((this.partouDialog as any)?.showModal) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this.partouDialog as any)?.showModal()
      }
    } else {
      this.closeOnClickOutside = false
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if ((this.partouDialog as any)?.close) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this.partouDialog as any)?.close()
      }
    }
  }

  @Emit('closeDialog')
  closeDialog () : void {
    this.showDialog = false
  }

  clickOutside () : void {
    if (!this.persistent && this.closeOnClickOutside) {
      this.closeDialog()
    }
  }
}


import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

@Component
export default class PartouIconButton extends Vue {
  @Prop({ required: true })
  icon!: string

  @Emit('click')
  onKeyEnter () : void {
    // emit
  }
}

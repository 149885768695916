import IState from '@/store/IState'
import { ActionTree } from 'vuex'
import { MUTATIONS } from './ConfigurationMutations'
import IConfigurationService from '@/services/ConfigurationService/IConfigurationService'
import IConfigurationState from './IConfigurationState'

export const ACTIONS = {
  getAllNecessaryConfigurations: 'getAllNecessaryConfigurations'
}

export function actions (ConfigurationService : IConfigurationService): ActionTree<IConfigurationState, IState> {
  return {
    async getAllNecessaryConfigurations ({ commit }) {
      const customerSupportContactInfo = process.env.VUE_APP_CUSTOMER_SUPPORT_CONTACT_INFO ? JSON.parse(process.env.VUE_APP_CUSTOMER_SUPPORT_CONTACT_INFO) : undefined
      const externalUrls = await ConfigurationService.getConfigurationByKeyAsync({ key: 'external_urls', date: new Date() })
      const kotSettings = await ConfigurationService.getConfigurationByKeyAsync({ key: 'kot_settings', date: new Date() })
      const planSettings = await ConfigurationService.getConfigurationByKeyAsync({ key: 'plan_settings', date: new Date() })

      commit(MUTATIONS.setConfiguration, { customerSupportContactInfo, kotSettings, externalUrls })
      commit(MUTATIONS.setPlanSettings, planSettings)
    }
  }
}

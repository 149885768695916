
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import PartouStepper, { PartouStepperStep } from '@/components/PartouComponents/PartouStepper.vue'
import EmailVerificationContent from './StepperContent/EmailVerificationContent.vue'
import ContractSignContent from './StepperContent/ContractSignContent.vue'
import ContractCannotYetBeSignedContent from './StepperContent/ContractCannotYetBeSignedContent.vue'
import MandateSignContent from './StepperContent/MandateSignContent.vue'
import { GetBookingStatusOutput } from '@/models'

@Component({
  components: { PartouStepper, ContractSignContent, ContractCannotYetBeSignedContent, MandateSignContent, EmailVerificationContent }
})
export default class ReservationBookingContent extends Vue {
  @Prop({ required: true })
  bookingHash!: string

  @Prop({ required: true })
  currentBookingStatus! : GetBookingStatusOutput

  @Prop({ required: false })
  emailAddress?: string

  currentStep = 1
  steps : PartouStepperStep[] = []

  created () : void {
    if (this.currentBookingStatus.bookingType !== 'External') {
      this.steps.push({ translationId: 'bookingStatusPage.stepper.email', stepLabel: 'Email', backgroundIconString: '$vuetify.icons.partouShield', isCompletedIconString: '$vuetify.icons.partouCheck' })
    }

    this.steps = this.steps.concat([
      { translationId: 'bookingStatusPage.stepper.contract', stepLabel: 'Overeenkomst', backgroundIconString: '$vuetify.icons.partouShield', isCompletedIconString: '$vuetify.icons.partouCheck' },
      { translationId: 'bookingStatusPage.stepper.SEPA', stepLabel: 'SEPA', backgroundIconString: '$vuetify.icons.partouShield', isCompletedIconString: '$vuetify.icons.partouCheck' }
    ])
  }

  @Watch('currentBookingStatus', { immediate: true })
  onBookingStatusChanged () : void {
    this.currentStep = this.calculateCurrentStep(this.currentBookingStatus)
  }

  calculateCurrentStep (bookingStatus : GetBookingStatusOutput) : number {
    // Can't initialize in created because this method gets triggered before the created
    if (!bookingStatus.isEmailVerified && bookingStatus.bookingType !== 'External') {
      return 1
    } else if (!bookingStatus.isContractSigned) {
      if (bookingStatus.bookingType !== 'External') {
        return 2
      } else {
        return 1
      }
    } else if (!bookingStatus.isMandateSigned) {
      if (bookingStatus.bookingType !== 'External') {
        return 3
      } else {
        return 2
      }
    }
    return -1
  }

  onStartSignClicked () : void {
    if (this.currentBookingStatus && this.currentBookingStatus.inviteUrl && !this.currentBookingStatus.isContractSigned) {
      window.open(this.currentBookingStatus.inviteUrl, '_self')
    }
  }

  onStartMandateClicked () : void {
    if (this.currentBookingStatus && this.currentBookingStatus.inviteUrl && !this.currentBookingStatus.isMandateSigned) {
      window.open(this.currentBookingStatus.inviteUrl, '_self')
    }
  }

  onResendEmailClicked () : void {
    this.$emit('onResendEmailClicked')
  }
}

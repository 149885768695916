import hasuraService from '@/services/HasuraService/HasuraService'
import { DefaultOptions } from 'apollo-client'
import Vue from 'vue'

const HASURA_HTTP_URI = process.env.VUE_APP_HASURA_HTTP_URI
const HASURA_WEBSOCKET_URI = process.env.VUE_APP_HASURA_WEBSOCKET_URI

export function initApolloService () : void {
  const hasuraOptions = {
    httpUrl: HASURA_HTTP_URI ?? '',
    websocketUrl: HASURA_WEBSOCKET_URI ?? ''
  }

  const apolloOptions : DefaultOptions = {}

  hasuraService.init(hasuraOptions, apolloOptions)
  Vue.mixin({ apolloProvider: hasuraService.getProvider() })
}

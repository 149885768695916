
import { Proposition } from '@/models'
import Availability from '@/models/enums/Availability'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class StreetDistanceIndicator extends Vue {
  @Prop({ required: true })
  value! : Proposition

  onStreetIndicatorClicked () : void {
    this.$emit('click')
  }

  get indicatorClass () : string {
    switch (this.value.availability) {
    case undefined:
      return 'unkown-availability-indicator'
    case Availability.Available:
      return 'available-indicator'
    case Availability.WaitingList:
      return 'waiting-list-indicator'
    case Availability.NotBookable:
      return 'on-request-indicator'
    default:
      return 'partly-available-indicator'
    }
  }

  get showDistance () : boolean {
    return this.value.distance !== undefined
  }

  get distance () : number {
    return Math.round(this.value.distance ?? 0)
  }
}

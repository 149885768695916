import Vue from 'vue'
import Vuex, { StoreOptions, Store } from 'vuex'
import IState from '@/store/IState'
import { getModules, getSingleModule, moduleNames } from '@/store'
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex)

let store : Store<IState>

export function createStore (): Store<IState> {
  let storage: Storage | null = null

  try {
    storage = window.sessionStorage
  } catch (error) {
    console.error('SessionStorage is not accessible:', error)
  }

  const vuexPersistence = new VuexPersistence<IState>({
    storage: storage || undefined
  })
  const storeOptions: StoreOptions<IState> = {
    modules: getModules(),
    plugins: [vuexPersistence.plugin]
  }

  store = new Store<IState>(storeOptions)

  return store
}

export function createPartialStore (withModules : moduleNames[]): Store<IState> {
  const vuexPersistence = new VuexPersistence<IState>({
    storage: window.sessionStorage
  })

  const modules = { }
  const generatedModules = withModules.map(x => getSingleModule(x))
  for (let i = 0; i < withModules.length; i++) {
    const keyName = withModules[i].toString()
    Object.assign(modules, { [keyName]: generatedModules[i] })
  }

  const storeOptions: StoreOptions<IState> = {
    modules,
    plugins: [vuexPersistence.plugin]
  }

  store = new Store<IState>(storeOptions)

  return store
}

export { store }

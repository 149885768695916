import { School, SchoolGroup, SubscriptionService, ServiceKind, PostalCode, Service } from '@/models'
import IState from '@/store/IState'
import { ActionTree } from 'vuex'
import IUserSelectionFormState from './IUserSelectionFormState'
import IUserSelectionState from './IUserSelectionState'
import { MUTATIONS } from './userSelectionMutations'
import { MUTATIONS as CONTACT_DETAIL_MUTATIONS } from '../contactDetails/contactDetailsMutations'

import DaySelectionState, { getInitialDaySelectionState } from '@/models/types/DaySelectionState'
import { determineTypeOfCare } from '@/models/enums/ServiceKind'
import { timeBetween, unitOfTime } from '@/utils/dateUtils'
import { setDayCheckboxStateWaitingListProposition } from './setters/setDayCheckboxStateWaitingListProposition'
import { setDayCheckboxState } from './setters/setDayCheckboxState'
import ServiceVarietyName, { sortedServiceVarietyNames } from '@/models/enums/ServiceVarietyName'

export const ACTIONS = {
  resetStateAsync: 'resetStateAsync',
  setFormStateAsync: 'setFormStateAsync',
  setSelectedServiceAsync: 'setSelectedServiceAsync',
  setSelectedServiceVarietiesAsync: 'setSelectedServiceVarietiesAsync',
  setSelectedSchoolAsync: 'setSelectedSchoolAsync',
  setSelectedSchoolGroupAsync: 'setSelectedSchoolGroupAsync',
  setSelectedSubscriptionServiceAsync: 'setSelectedSubscriptionServiceAsync',
  setSelectedProductIdsForServiceAsync: 'setSelectedProductIdsForServiceAsync',
  setProposedSubscriptionServiceAsync: 'setProposedSubscriptionServiceAsync',
  setSelectedPropositionId: 'setSelectedPropositionId',
  setSelectedServiceVarietyDaysAsync: 'setSelectedServiceVarietyDaysAsync',
  setSelectedSchoolLocalityAsync: 'setSelectedSchoolLocalityAsync',
  setPostalCodeAsync: 'setPostalCodeAsync',
  setServiceVarietyDaysAsync: 'setServiceVarietyDaysAsync',
  emptySelectedServiceVarietyDaysForServiceKindAsync: 'emptySelectedServiceVarietyDaysForServiceKindAsync',
  setSelectedServiceKindAsync: 'setSelectedServiceKindAsync',
  setPostalCodeValidationPending: 'setPostalCodeValidationPending',
  setPostalCodeValidationIsValid: 'setPostalCodeValidationIsValid',
  setDateOfBirth: 'setDateOfBirth',
  setStartDate: 'setStartDate'
}

export function resetOrCopySelectedServiceVarietyDays (state : IUserSelectionState, selectedServiceVarieties : ServiceVarietyName[]) : IUserSelectionFormState {
  const newFormState = { ...state.formState, selectedServiceVarieties }

  if (!selectedServiceVarieties.some(x => x === ServiceVarietyName.KDV)) {
    state.formState.daysPerServiceVariety?.KDV?.forEach(x => { x.isChecked = false })
  }

  if (!selectedServiceVarieties.some(x => x === ServiceVarietyName.NSO) && !selectedServiceVarieties.some(x => x === ServiceVarietyName.VSO)) {
    state.formState.daysPerServiceVariety?.NSO?.forEach(x => { x.isChecked = false })
    state.formState.daysPerServiceVariety?.VSO?.forEach(x => { x.isChecked = false })
  }

  if (state.formState.dateOfBirth && state.formState.startDateOfDayCare) {
    newFormState.selectedServiceKind = determineTypeOfCare(state.formState.dateOfBirth, state.formState.startDateOfDayCare)
  }
  return newFormState
}

export function actions (): ActionTree<IUserSelectionState, IState> {
  return {
    async setSelectedServiceAsync ({ commit }, selectedService: Service | undefined | null) {
      commit(MUTATIONS.setSelectedService, selectedService)
    },
    async setSelectedServiceKindAsync ({ commit }, selectedServiceKind: ServiceKind) {
      commit(MUTATIONS.setSelectedServiceKind, selectedServiceKind)
    },
    async setSelectedPropositionId ({ commit }, setSelectedPropositionId: string) {
      commit(MUTATIONS.setSelectedPropositionId, setSelectedPropositionId)
    },
    async setSelectedSubscriptionServiceAsync ({ commit }, selectedSubscription: SubscriptionService) {
      commit(MUTATIONS.setSelectedSubscriptionService, selectedSubscription)
    },
    async setSelectedProductIdsForServiceAsync ({ commit }, selectedProductIds: string[]) {
      commit(MUTATIONS.setSelectedProductIdsForService, selectedProductIds)
    },
    async setProposedSubscriptionServiceAsync ({ commit }, proposededSubscription: SubscriptionService) {
      commit(MUTATIONS.setProposedSubscriptionService, proposededSubscription)
    },
    async setSelectedSchoolLocalityAsync ({ commit }, selectedSchoolLocality: string) {
      commit(MUTATIONS.setSelectedSchoolLocality, selectedSchoolLocality)
    },
    async setSelectedSchoolAsync ({ commit, state }, selectedSchool: School | undefined) {
      if (state.formState.isUsingChildbenefitCalculator) {
        commit(MUTATIONS.setSelectedService, null)
      }
      commit(MUTATIONS.setSelectedSchool, selectedSchool)
    },
    async setSelectedSchoolGroupAsync ({ commit }, selectedSchoolGroup: SchoolGroup | undefined) {
      commit(MUTATIONS.setSelectedSchoolGroup, selectedSchoolGroup)
    },
    async setSelectedServiceVarietiesAsync ({ commit, state }, selectedServiceVarieties: ServiceVarietyName[]) {
      commit(MUTATIONS.setFormState, resetOrCopySelectedServiceVarietyDays(state, selectedServiceVarieties))
    },
    async setPostalCodeAsync ({ commit }, postalCode : PostalCode) {
      commit(MUTATIONS.setPostalCode, postalCode)
    },
    async setFormStateAsync ({ commit }, formState: Partial<IUserSelectionFormState>) {
      commit(MUTATIONS.setFormState, formState)
    },
    async resetStateAsync ({ commit }) {
      commit(MUTATIONS.resetState)
    },
    async setServiceVarietyDaysAsync ({ commit, state, getters }, input : { serviceVariety : ServiceVarietyName, days : DaySelectionState[] }) {
      if (state.formState.isAcceptingWaitinglist) {
        setDayCheckboxStateWaitingListProposition(commit, state, getters, input)
      } else {
        setDayCheckboxState(commit, state, input)
      }
    },
    async setSelectedServiceVarietyDaysAsync ({ commit, state }, daysPerServiceVariety: Record<ServiceVarietyName, DaySelectionState[]>) {
      const days = getInitialDaySelectionState()
      sortedServiceVarietyNames.forEach(serviceVarietyName => {
        const checkedDays = daysPerServiceVariety[serviceVarietyName].filter(x => x.isChecked)

        for (const checkedDay of checkedDays) {
          const day = days.find(x => x.day === checkedDay.day)
          if (day) {
            day.isChecked = true
            day.priority = checkedDay.priority
          }
        }
      })

      if (state.formState.selectedServiceKind === ServiceKind.SchoolCare && !state.formState.isAcceptingWaitinglist) {
        daysPerServiceVariety.NSO.forEach((x, index) => {
          if (!x.isChecked) {
            daysPerServiceVariety.VSO[index].isChecked = false
          }
        })
      }

      const newFormState = { ...state.formState, daysPerServiceVariety, days }
      commit(MUTATIONS.setFormState, newFormState)
    },
    async emptySelectedServiceVarietyDaysForServiceKindAsync ({ commit }, serviceKind : ServiceKind) {
      if (serviceKind === ServiceKind.SchoolCare) {
        commit(MUTATIONS.emptySelectedServiceVarietyDaysForServiceVariety, ServiceVarietyName.VSO)
        commit(MUTATIONS.emptySelectedServiceVarietyDaysForServiceVariety, ServiceVarietyName.NSO)
      } else {
        commit(MUTATIONS.emptySelectedServiceVarietyDaysForServiceVariety, ServiceVarietyName.KDV)
      }
    },
    setPostalCodeValidationPending ({ commit }, pending : boolean) {
      commit(MUTATIONS.setPostalCodeValidationPending, pending)
    },
    setPostalCodeValidationIsValid ({ commit }, isValid : boolean) {
      commit(MUTATIONS.setPostalCodeValidationIsValid, isValid)
    },
    setDateOfBirth ({ commit, state }, date : Date) {
      commit(MUTATIONS.setDateOfBirth, date)
      if (state.formState.startDateOfDayCare) {
        const age = Math.floor(timeBetween(date, state.formState.startDateOfDayCare, unitOfTime.year))
        if (age >= 4) {
          commit(MUTATIONS.setSelectedServiceKind, ServiceKind.SchoolCare)
          commit(MUTATIONS.setSelectedServiceVarieties, [ServiceVarietyName.NSO])
        } else {
          commit(MUTATIONS.setSelectedServiceKind, ServiceKind.DayCare)
          commit(MUTATIONS.setSelectedServiceVarieties, [ServiceVarietyName.KDV])
        }
      }
      commit(MUTATIONS.setFormState, resetOrCopySelectedServiceVarietyDays(state, state.formState.selectedServiceVarieties))
      commit(`contactDetails/${CONTACT_DETAIL_MUTATIONS.setChildsBsn}`, null, { root: true })
    },
    setStartDate ({ commit, state }, date : Date) {
      commit(MUTATIONS.setStartDate, date)
      if (state.formState.dateOfBirth) {
        const age = Math.floor(timeBetween(state.formState.dateOfBirth, date, unitOfTime.year))
        if (age >= 4) {
          commit(MUTATIONS.setSelectedServiceKind, ServiceKind.SchoolCare)
          commit(MUTATIONS.setSelectedServiceVarieties, [ServiceVarietyName.NSO])
        } else {
          commit(MUTATIONS.setSelectedServiceKind, ServiceKind.DayCare)
          commit(MUTATIONS.setSelectedServiceVarieties, [ServiceVarietyName.KDV])
        }
      }
      commit(MUTATIONS.setFormState, resetOrCopySelectedServiceVarietyDays(state, state.formState.selectedServiceVarieties))
    }
  }
}


import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import PartouBottomSheet from '@/components/PartouComponents/PartouBottomSheet/PartouBottomSheet.vue'
import { GETTERS, ACTIONS, MUTATIONS, NAMESPACES, STATE } from '@/store'
import { namespace } from 'vuex-class'
import ICalculatorFormState from '@/store/modules/childBenefitCalculator/ICalculatorFormState'
import PartouButton from '@/components/PartouComponents/Buttons/PartouButton.vue'
import PartouNotify from '@/components/PartouComponents/PartouNotify.vue'
import PartouAutocomplete from '@/components/PartouComponents/Input/PartouAutoComplete/PartouAutoCompleteN.vue'
import { ICalculatorGetters } from '@/store/modules/childBenefitCalculator'
import ICalculatorConstants from '@/store/modules/childBenefitCalculator/ICalculatorConstants'
import { euroFilter } from '@/utils/filters'
import IUserSelectionFormState from '@/store/modules/userSelection/IUserSelectionFormState'
import { inputRegexDirective } from '@/utils/directives'
import PartouTextField from '@/components/PartouComponents/Input/PartouTextField/PartouTextField.vue'
import Debounce from '@/utils/decorators/debounceDecorator'
import PropositionSubscription from '@/models/PropositionSubscription'
import ChildBenefitSummaryHeader from './ChildBenefitSummaryHeader.vue'
import ChildBenefitSummaryTable from './ChildBenefitSummaryTable.vue'
import ChildBenefitCalculatorResult from './ChildBenefitCalculatorResult/ChildBenefitCalculatorResult.vue'
import { regexes } from '@/definitions'
import { sendUseCalculatorAnalyticsEvent } from '@/plugins/googleAnalytics/gtagFunctions'
import { ServiceVarietyName } from '@/models'
import ServicePricing from '@/models/ServicePricing'
import moment from 'moment'
import { capitalizeFirstLetters } from '@/utils/stringUtils'

const childBenefitCalculatorModule = namespace(NAMESPACES.childBenefitCalculator)
const userSelectionModule = namespace(NAMESPACES.userSelection)

@Component({
  components: { PartouBottomSheet, PartouButton, PartouNotify, PartouTextField, ChildBenefitSummaryHeader, ChildBenefitSummaryTable, ChildBenefitCalculatorResult, PartouAutocomplete },
  directives: { inputRegexDirective },
  data: () => ({ regexes })
})
export default class ChildBenefitCalculator extends Vue {
  @Prop({ required: true })
  propositionSubscription?: PropositionSubscription

  @Prop({ required: false, default: () => [] })
  allPropositionSubscriptions?: PropositionSubscription[]

  @Prop({ required: false })
  calculationStartDate!: Date

  @Prop({ required: false, default: false })
  displayCalculation! : boolean

  @Prop({ required: false, default: false })
  withWaitingListHours! : boolean

  @userSelectionModule.State(STATE.userSelection.formState)
  userSelectionFormState!: IUserSelectionFormState

  // #region store state
  @childBenefitCalculatorModule.State(STATE.childBenefitCalculator.formState)
  calculatorFormState! : ICalculatorFormState

  @childBenefitCalculatorModule.State(STATE.childBenefitCalculator.constants)
  calculatorConstants! : ICalculatorConstants

  @childBenefitCalculatorModule.Action(ACTIONS.childBenefitCalculator.fetchConstantsOnceAsync)
  fetchConstantsOnceAsync! : () => Promise<void>

  @childBenefitCalculatorModule.Mutation(MUTATIONS.childBenefitCalculator.setFormState)
  setFormState! : (formState: ICalculatorFormState) => void

  get serviceVarietyNames (): ServiceVarietyName[] { return this.userSelectionFormState?.selectedServiceVarieties || [] }
  get formattedCumulativeIncomePerYear (): string { return euroFilter(this.calculatorFormState?.cumulativeIncomePerYear) }

  @childBenefitCalculatorModule.Getter(GETTERS.childBenefitCalculator.getDeclarableHourlyRate)
  getDeclarableHourlyRate! : ICalculatorGetters['getDeclarableHourlyRate']

  get declarableHourlyRate (): number { return this.getDeclarableHourlyRate && this.getDeclarableHourlyRate({ serviceVarietyNames: this.serviceVarietyNames, offerHourlyRate: this.costsPerHour }) }
  get formattedDeclarableHourlyRate (): string { return euroFilter(this.declarableHourlyRate) }

  @childBenefitCalculatorModule.Getter(GETTERS.childBenefitCalculator.getOwnContributionPerMonth)
  getOwnContributionPerMonth! : ICalculatorGetters['getOwnContributionPerMonth']

  get ownContributionPerMonth (): number {
    return this.calculationProposition
      ? this.getOwnContributionPerMonth({ serviceVarietyNames: this.serviceVarietyNames, offerHourlyRate: this.costsPerHour, propositionSubscription: this.calculationProposition, withWaitingListHours: this.withWaitingListHours })
      : 0
  }

  @childBenefitCalculatorModule.Getter(GETTERS.childBenefitCalculator.getOwnContributionPerHour)
  getOwnContributionPerHour! : ICalculatorGetters['getOwnContributionPerHour']

  get ownContributionPerHour (): number {
    return this.calculationProposition
      ? this.getOwnContributionPerHour({ serviceVarietyNames: this.serviceVarietyNames, offerHourlyRate: this.costsPerHour, propositionSubscription: this.calculationProposition, withWaitingListHours: this.withWaitingListHours })
      : 0
  }

  @childBenefitCalculatorModule.Getter(GETTERS.childBenefitCalculator.getRemainingDeclarableCareHoursPerMonth)
  getRemainingDeclarableCareHoursPerMonth! : ICalculatorGetters['getRemainingDeclarableCareHoursPerMonth']

  get remainingDeclarableCareHoursPerMonth (): number {
    return this.calculationProposition
      ? this.getRemainingDeclarableCareHoursPerMonth({ serviceVarietyNames: this.serviceVarietyNames, propositionSubscription: this.calculationProposition, withWaitingListHours: this.withWaitingListHours })
      : 0
  }

  get careHoursPerMonth (): number {
    return this.calculationProposition
      ? this.getCareHoursPerMonth({ propositionSubscription: this.calculationProposition, withWaitingListHours: this.withWaitingListHours })
      : 0
  }

  @childBenefitCalculatorModule.Getter(GETTERS.childBenefitCalculator.getCareHoursPerMonth)
  getCareHoursPerMonth! : ICalculatorGetters['getCareHoursPerMonth']

  get costsPerHour (): number { return this.calculationProposition?.pricePerHour ?? 0 }

  get costsPerMonth (): number { return this.calculationProposition?.priceOfFirstFullMonth ?? 0 }

  @childBenefitCalculatorModule.Getter(GETTERS.childBenefitCalculator.getCompensatedPercentage)
  getCompensatedPercentage! : ICalculatorGetters['getCompensatedPercentage']

  get compensatedPercentage (): number { return this.getCompensatedPercentage && this.getCompensatedPercentage() }

  @childBenefitCalculatorModule.Getter(GETTERS.childBenefitCalculator.getBenefitsPerMonth)
  getBenefitsPerMonth! : ICalculatorGetters['getBenefitsPerMonth']

  get benefitsPerMonth (): number {
    return this.calculationProposition
      ? this.getBenefitsPerMonth({ serviceVarietyNames: this.serviceVarietyNames, offerHourlyRate: this.costsPerHour, propositionSubscription: this.calculationProposition, withWaitingListHours: this.withWaitingListHours })
      : 0
  }

  @childBenefitCalculatorModule.Getter(GETTERS.childBenefitCalculator.getBenefitsPerHour)
  getBenefitsPerHour! : ICalculatorGetters['getBenefitsPerHour']

  get benefitsPerHour (): number {
    return this.calculationProposition
      ? this.getBenefitsPerHour({ serviceVarietyNames: this.serviceVarietyNames, offerHourlyRate: this.costsPerHour, propositionSubscription: this.calculationProposition, withWaitingListHours: this.withWaitingListHours })
      : 0
  }

  get formattedBenefitsPerHour (): string { return euroFilter(this.benefitsPerHour) }

  get calculationProposition (): PropositionSubscription | undefined {
    if (!this.propositionSubscription || !this.selectedServicePricing) {
      return this.propositionSubscription
    }

    return this.selectedServicePricing.propositionSubscription
  }
  // #endregion

  isCalculatorVisible = false
  isExtraInformationVisible = false
  currentYear = new Date().getFullYear()

  // info popup toggles
  isGeneralInfoVisible = false
  isCumulativeIncomeInfoVisible = false
  isWorkingHoursInfoVisible = false
  isBenefitAmountInfoVisible = false
  isMaximumHoursInfoVisible = false
  inputToFocusOn = ''
  hoursInWeek = 168
  currentAndFutureServicePricings: ServicePricing[] = []
  selectedServicePricing: ServicePricing | null = null

  async mounted (): Promise<void> {
    await this.fetchConstantsOnceAsync()
    this.extractPriceChanges()
  }

  @Watch('propositionSubscription')
  onPropositionSubscriptionChange (): void {
    this.extractPriceChanges()
  }

  extractPriceChanges (): void {
    this.currentAndFutureServicePricings = []
    this.selectedServicePricing = null

    if (!this.allPropositionSubscriptions || this.allPropositionSubscriptions?.length === 0) {
      return
    }

    this.allPropositionSubscriptions.sort((a, b) => {
      const dateA = a.priceAgreementValidFrom ? a.priceAgreementValidFrom.getTime() : -Infinity
      const dateB = b.priceAgreementValidFrom ? b.priceAgreementValidFrom.getTime() : -Infinity
      return dateA - dateB
    })

    let lastPricing: ServicePricing | undefined
    for (let i = 0; i < this.allPropositionSubscriptions.length; i++) {
      const propositionSubscription = this.allPropositionSubscriptions[i]
      if (!lastPricing || lastPricing.hourRate !== propositionSubscription.pricePerHour) {
        const validFrom = propositionSubscription.priceAgreementValidFrom?.toISOString()
        lastPricing = {
          validFrom: validFrom ?? '',
          hourRate: propositionSubscription.pricePerHour ?? 0,
          monthlyRate: propositionSubscription.priceOfFirstFullMonth ?? 0,
          numberOfHours: propositionSubscription.averageAvailableHoursPerMonth ?? 0,
          propositionSubscription
        }

        this.currentAndFutureServicePricings.push(lastPricing)
      }
    }
  }

  async onPricingChangeDateChangedAsync (value: ServicePricing): Promise<void> {
    this.selectedServicePricing = value
    this.onFormDataChanged()
  }

  getServicePricingPeriodLabel (servicePricing: ServicePricing) : string {
    if (servicePricing.validFrom) {
      return 'vanaf ' + capitalizeFirstLetters(moment(servicePricing.validFrom).locale('nl').format('MMMM YYYY'))
    }

    return ''
  }

  @Debounce(300)
  onDataChanged (resizeCallback: () => void): void {
    this.onFormDataChanged()
    resizeCallback()
  }

  onFormDataChanged (): void {
    this.setFormState(this.calculatorFormState)
  }

  toggleIsCalculatorVisible (isLoading: boolean): void {
    this.isCalculatorVisible = !this.isCalculatorVisible && !isLoading
    if (this.isCalculatorVisible) {
      sendUseCalculatorAnalyticsEvent()
    }
  }

  toggleExtraInformationVisible (): void {
    this.isExtraInformationVisible = !this.isExtraInformationVisible
  }

  onCumulativeIncomeInfoClicked (): void {
    this.isCumulativeIncomeInfoVisible = true
  }

  onWorkingHoursInfoClicked (): void {
    this.isWorkingHoursInfoVisible = true
  }

  onBenefitAmountInfoClicked (): void {
    this.isBenefitAmountInfoVisible = true
  }

  onMaximumHoursInfoClicked (): void {
    this.isMaximumHoursInfoVisible = true
  }

  focusInput (inputToFocusOn: string | undefined) : void {
    // Handle it the next tick to wait for the resize to be completed
    Vue.nextTick(() => {
      const component = this.$refs[inputToFocusOn ?? this.inputToFocusOn] as Vue
      if (component) {
        const element = component.$refs['text-field'] as HTMLElement
        if (element) {
          element.focus()
        }
      }
    })
  }

  getPopupWidth () : number {
    const width = window.innerWidth - 96
    return width > 520 ? 520 : width
  }
}

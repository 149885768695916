
import PartouCard from '@/components/PartouComponents/PartouCard.vue'
import Gender from '@/models/enums/Gender'
import { NAMESPACES, STATE } from '@/store'
import IContactDetailChild from '@/store/modules/contactDetails/IContactDetailChild'
import IUserSelectionFormState from '@/store/modules/userSelection/IUserSelectionFormState'
import moment from 'moment'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

const userSelectionModule = namespace(NAMESPACES.userSelection)

@Component({
  components: { PartouCard }
})
export default class ChildCard extends Vue {
  @Prop({ required: true })
  child! : IContactDetailChild

  @userSelectionModule.State(STATE.userSelection.formState)
  userSelectionFormState!: IUserSelectionFormState

  formatDateOfBirth (dateOfBirth : string) : string {
    return moment(dateOfBirth).format('DD - MM - YYYY')
  }

  getGenderLabel (gender : Gender) : string {
    switch (gender) {
    case Gender.Male: {
      return 'orderContactInfoFormPage.form.genders.male'
    }
    case Gender.Female: {
      return 'orderContactInfoFormPage.form.genders.female'
    }
    case Gender.Other: {
      return 'orderContactInfoFormPage.form.genders.other'
    }
    }
  }

  isBsnRequired () : boolean {
    const requiredBeforeDate = moment().add(-4, 'weeks')
    const dateOfBirth = moment(this.userSelectionFormState.dateOfBirth)
    return dateOfBirth <= requiredBeforeDate
  }
}


import { Vue, Component, Prop } from 'vue-property-decorator'
import { ServiceVarietyOpening, Timespan } from '@/models'

@Component
export default class OpeningTimes extends Vue {
  @Prop({ required: true, default: '' })
  public openingTime!: ServiceVarietyOpening

  @Prop({ default: false })
  public showWeekend!: boolean

  @Prop({ required: false, default: null })
  private overrideFrom?: string

  @Prop({ required: false, default: null })
  private overrideUntil?: string

  get days () : string[] {
    const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday']
    if (this.showWeekend) {
      days.push('saturday', 'sunday')
    }

    return days
  }

  getTimespanString (timespans?: Timespan[]) : string {
    if (!timespans) {
      return this.$t('openingTimes.unknown').toString()
    } else if (timespans.length <= 0) {
      return this.$t('openingTimes.closed').toString()
    }

    // returns a string based on the earliest, till the latest openingHours
    const min = this.overrideFrom ?? timespans.reduce((a, b) => a.From < b.From ? a : b).From
    const max = this.overrideUntil ?? timespans.reduce((a, b) => a.Until > b.Until ? a : b).Until

    return !min || !max
      ? this.$t('openingTimes.unknown').toString()
      : `${min} - ${max}`
  }
}

import { GetEncryptedStateDocument, GetEncryptedStateQuery, GetEncryptedStateQueryVariables, InsertShareDocument, InsertShareMutation, InsertShareMutationVariables } from '@/models'
import ApolloClient from 'apollo-client'
import { inject, injectable } from 'inversify'
import IShareService from './IShareService'
import IOperationContext from '../HasuraService/OperationContext'
import { StatusCodes } from 'http-status-codes'

@injectable()
export default class ShareService implements IShareService {
  private readonly apollo: ApolloClient<unknown>
  constructor (@inject(ApolloClient) apollo: ApolloClient<unknown>) {
    this.apollo = apollo
  }

  async createShareUrlAsync (variables: InsertShareMutationVariables): Promise<boolean> {
    const result = await this.apollo.mutate<InsertShareMutation>({
      mutation: InsertShareDocument,
      variables
    })

    return result.data?.insert_share?.affected_rows !== 0 // eslint-disable-line
  }

  async getEncryptedStateAsync (variables: GetEncryptedStateQueryVariables): Promise<{ encryptedState: string, sharedFromUrl: string }> {
    const context: IOperationContext = {
      expectedErrorCodes: [StatusCodes.NOT_FOUND, StatusCodes.UNPROCESSABLE_ENTITY]
    }

    try {
      const result = await this.apollo.mutate<GetEncryptedStateQuery>({
        mutation: GetEncryptedStateDocument,
        variables,
        context
      })
      return { encryptedState: result.data?.getEncryptedState?.encryptedState ?? '', sharedFromUrl: result.data?.getEncryptedState?.sharedFromUrl ?? '' } // eslint-disable-line 
    } catch (e) {
      return { encryptedState: '', sharedFromUrl: '' }
    }
  }
}

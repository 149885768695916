
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import PartouButton from '@/components/PartouComponents/Buttons/PartouButton.vue'
import PartouCard from '@/components/PartouComponents/PartouCard.vue'
import PartouFloatingCircleButton from '@/components/PartouComponents/Buttons/PartouFloatingCircleButton.vue'
import { NAMESPACES } from '@/store'
import { State } from 'vuex-class'
import Debounce from '@/utils/decorators/debounceDecorator'
import IShareService from '@/services/ShareService/IShareService'
import container, { SERVICE_IDENTIFIERS } from '@/init/container'
import { ROUTES } from '@/router/routes'
import IUserSelectionState from '@/store/modules/userSelection/IUserSelectionState'
import IContactDetailsState from '@/store/modules/contactDetails/IContactDetailsState'
import IState from '@/store/IState'
import { Route } from 'vue-router'
import { encryptObjectAes, generateHash, generateKey } from '@/utils/aesCryptoUtils'

@Component({
  components: { PartouCard, PartouButton, PartouFloatingCircleButton }
})
export default class SharingMenu extends Vue {
  @State(NAMESPACES.userSelection)
  userSelectionState!: IUserSelectionState

  @State(NAMESPACES.contactDetails)
  contactDetailsState!: IContactDetailsState

  @Prop({ required: false, default: '' })
  sharingTitle! : string

  @Prop({ required: true })
  serviceSlug! : string

  @Prop({ required: false })
  className? : string

  @Prop({ required: false })
  shareWithDataRouteName?: string

  shareService! : IShareService

  getSharingUrl (name: string = ROUTES.locationDetail) : Route {
    return this.$router.resolve({ name, params: { serviceSlug: this.serviceSlug } }).route
  }

  showShareMenu = false
  showCopiedAnimation = false

  created () : void {
    this.shareService = container.get<IShareService>(SERVICE_IDENTIFIERS.IShareService)
  }

  @Emit('onShareIconClicked')
  onShareIconClicked () : void {
    // emits onShareIconClicked
  }

  onShareService () : void {
    this.shareThroughWebApiAsync(this.sharingTitle, this.getSharingUrl().fullPath)
  }

  async onShareServiceWithDataAsync () : Promise<void> {
    const stateToShare: Partial<IState> = {
      userSelection: this.userSelectionState,
      contactDetails: this.contactDetailsState
    }

    const base64Key = generateKey()
    const urlHash = generateHash()

    const encryptedState = encryptObjectAes(stateToShare, base64Key, urlHash)

    const shareUrl = this.$router.resolve({ name: ROUTES.share, params: { hash: urlHash, encryptionKey: base64Key } }).route.fullPath
    const sharedFromUrl = this.getSharingUrl(this.shareWithDataRouteName ?? ROUTES.orderOverview).path

    if (await this.shareService.createShareUrlAsync({ encryptedState, sharedFromIP: 'ip', sharedFromUrl, urlHash })) {
      await this.shareThroughWebApiAsync(this.sharingTitle, shareUrl)
    }
  }

  async shareThroughWebApiAsync (sharingtitle : string, sharingUrl : string) : Promise<void> {
    if ((navigator as any).share) { /* Disabled because of any: method named share not found by npm build but does exist */// eslint-disable-line
      try {
        await (navigator as any).share({ /* Disabled because of any: method named share not found by npm build but does exist */// eslint-disable-line
          title: sharingtitle,
          url: sharingUrl
        })
      } catch (e) {
        this.copyToClipboard(sharingUrl)
        console.error(e)
      }
    } else {
      this.copyToClipboard(sharingUrl)
    }
  }

  copyToClipboard (sharingUrl : string) : void {
    const canCopy = window.isSecureContext && navigator.clipboard && navigator.clipboard.writeText

    if (canCopy) {
      navigator.clipboard.writeText(window.location.host + sharingUrl)
      this.showCopiedAnimation = true
      this.resetCopyAnimation()
    } else {
      const textArea = document.createElement('textarea')
      textArea.value = window.location.host + sharingUrl
      textArea.style.position = 'fixed'
      document.body.appendChild(textArea)
      textArea.focus()
      textArea.select()
      try {
        document.execCommand('copy')
        this.showCopiedAnimation = true
      } catch (err) {
        console.log('Was not possible to copy the text: ', err)
      }
      document.body.removeChild(textArea)
      this.resetCopyAnimation()
    }
  }

  onToggleShareMenu () : void {
    this.showShareMenu = !this.showShareMenu
  }

  onCloseShareMenu () : void {
    this.showShareMenu = false
  }

  @Debounce(1000)
  resetCopyAnimation () : void {
    this.showCopiedAnimation = false
  }
}

import { GetWaitingListStatusOutput, Proposition } from '@/models'
import isAcceptingWaitinglistPropositionState from './IAcceptProposedWaitingListPropositionState'
import IBookPropositionState from './IBookPropositionState'

export const STATE = {
  propositions: 'propositions',
  propositionHistory: 'propositionHistory',
  waitinglistPropositionStatus: 'waitinglistPropositionStatus',
  bookPropositionState: 'bookPropositionState',
  acceptProposedWaitingListPropositionState: 'acceptProposedWaitingListPropositionState'
}

export default interface IPropositionState {
  propositions?: Array<Proposition>,
  propositionHistory?: Array<Proposition>,
  waitinglistPropositionStatus? : GetWaitingListStatusOutput
  bookPropositionState?: IBookPropositionState
  acceptProposedWaitingListPropositionState?: isAcceptingWaitinglistPropositionState // wat is dit voor waarde? isAccepting.. -> usAcceptingProposed?
}

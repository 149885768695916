import Vue, { DirectiveOptions, VNode } from 'vue'

const inputRegexDirective: DirectiveOptions = {
  bind: function (el: any, binding : any, vnode : VNode) { // eslint-disable-line
    el.validateKeyDown = function (e: KeyboardEvent) {
      const reg = binding.value
      reg.lastIndex = 0

      const isValid = reg.test(e.key)

      if (!isValid && !e.ctrlKey && !e.metaKey) {
        e.preventDefault()
      }
    }
    el.addEventListener('keydown', el.validateKeyDown)
  },
  unbind: function (el: any) { // eslint-disable-line
    el.removeEventListener('keydown', el.validateKeyDown)
  }
}

Vue.directive('input-regex', inputRegexDirective)
export default inputRegexDirective

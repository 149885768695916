// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface String {
  parseToPascalCase(): string;
  capitalizeFirstLetters() : string;
}

String.prototype.parseToPascalCase = function () { /* Disabled because function is extended on native object */// eslint-disable-line
  return this.replace(/(\w)(\w*)/g, (g0, g1, g2) => { return g1.toUpperCase() + g2.toLowerCase() })
}

String.prototype.capitalizeFirstLetters = function capitalizeFirstLetters () : string { /* Disabled because function is extended on native object */// eslint-disable-line
  return this.split(' ').map(x => x.charAt(0).toUpperCase() + x.slice(1).toLowerCase()).join(' ')
}

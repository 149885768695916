
import { Vue, Component, Emit, Watch, Prop } from 'vue-property-decorator'
import PartouBottomSheet from '@/components/PartouComponents/PartouBottomSheet/PartouBottomSheet.vue'
import { ACTIONS, GETTERS, MUTATIONS, NAMESPACES, STATE } from '@/store'
import { namespace, State } from 'vuex-class'
import LocationSearch from '@/components/LocationSearch/LocationSearch.vue'
import PartouButton from '@/components/PartouComponents/Buttons/PartouButton.vue'
import PartouRadio from '@/components/PartouComponents/Input/PartouRadio/PartouRadio.vue'
import SchoolSelectionForm from '@/components/AvailabilitySelector/SchoolSelectionForm.vue'
import { inputRegexDirective } from '@/utils/directives'
import PartouTextField from '@/components/PartouComponents/Input/PartouTextField/PartouTextField.vue'
import { regexes } from '@/definitions'
import ICalculatorFormState from '@/store/modules/childBenefitCalculator/ICalculatorFormState'
import { Proposition, Service, ServiceKind, ServiceVarietyName } from '@/models'
import DaySelectionState from '@/models/types/DaySelectionState'
import DayCheckboxState from '@/components/InputFields/DaySelector/DayCheckboxState'
import ICalculatorConstants from '@/store/modules/childBenefitCalculator/ICalculatorConstants'
import PartouNotify from '@/components/PartouComponents/PartouNotify.vue'
import ICalculatorState from '@/store/modules/childBenefitCalculator/ICalculatorState'
import IUserSelectionState from '@/store/modules/userSelection/IUserSelectionState'
import MultiStateDaySelector from '@/components/MultiStateDaySelector/MultiStateDaySelector.vue'

const childBenefitCalculatorModule = namespace(NAMESPACES.childBenefitCalculator)
const userSelectionModule = namespace(NAMESPACES.userSelection)

@Component({
  components: { PartouBottomSheet, PartouButton, PartouTextField, SchoolSelectionForm, MultiStateDaySelector, LocationSearch, PartouRadio, PartouNotify },
  directives: { inputRegexDirective },
  data: () => ({ regexes })
})
export default class ChildBenefitCalculatorInputForm extends Vue {
  @State(NAMESPACES.childBenefitCalculator)
  calculatorState! : ICalculatorState

  @State(NAMESPACES.userSelection)
  userSelectionState!: IUserSelectionState

  @Prop({ required: false, default: false })
  isCalculating!: boolean

  @childBenefitCalculatorModule.Mutation(MUTATIONS.childBenefitCalculator.setFormState)
  setCalculatorFormState! : (formState: ICalculatorFormState) => void

  @userSelectionModule.Mutation(MUTATIONS.userSelection.setIsUsingChildbenefitCalculator)
  setIsUsingChildbenefitCalculator! : (value: boolean) => void

  @userSelectionModule.Action(ACTIONS.userSelection.setSelectedServiceAsync)
  setSelectedServiceAsync!: (selectedService: Service | undefined) => Promise<void>

  @userSelectionModule.Action(ACTIONS.userSelection.setSelectedServiceVarietyDaysAsync)
  setSelectedServiceVarietyDaysAsync!: (daysPerServiceVariety: Record<ServiceVarietyName, DaySelectionState[]>, proposition?: Proposition) => Promise<void>

  @userSelectionModule.Action(ACTIONS.userSelection.setSelectedServiceKindAsync)
  setSelectedServiceKindAsync!: (selectedServiceKind: ServiceKind) => Promise<void>

  @userSelectionModule.Getter(GETTERS.userSelection.getDayCheckboxState)
  getDayCheckboxState!: (withAvailability : boolean, withOpeningHours : boolean) => Record<ServiceVarietyName, DayCheckboxState[]>

  @userSelectionModule.Getter(GETTERS.userSelection.getIsValidForm)
  getIsValidForm!: () => boolean

  @childBenefitCalculatorModule.State(STATE.childBenefitCalculator.constants)
  calculatorConstants! : ICalculatorConstants

  isSchoolselectionValid = false
  ServiceKind = ServiceKind
  serviceKindOptions = [{ serviceKind: ServiceKind.DayCare, name: 'Kinderdagopvang' }, { serviceKind: ServiceKind.SchoolCare, name: 'Buitenschoolse opvang' }]
  isValidForm = false
  hoursInWeek = 168

  isCumulativeIncomeInfoVisible = false

  get dayCheckBoxStates () : Record<ServiceVarietyName, DayCheckboxState[]> {
    return this.getDayCheckboxState(false, true)
  }

  get selectedServiceVarieties () : ServiceVarietyName[] {
    return this.userSelectionState.formState.selectedService?.serviceVarieties.map(x => x.name as ServiceVarietyName) ?? []
  }

  @Watch('calculatorState.formState')
  @Watch('userSelectionState.formState.selectedService')
  @Watch('userSelectionState.formState.selectedSchoolGroup')
  onFormChanged () : void {
    this.isValidForm = this.getIsValidForm()
  }

  mounted () : void {
    this.setIsUsingChildbenefitCalculator(true)
    this.isValidForm = this.getIsValidForm()
  }

  onCalculatorFormStateChanged (): void {
    this.setCalculatorFormState(this.calculatorState.formState)
  }

  onServiceKindChanged (value : ServiceKind) : void {
    this.setSelectedServiceKindAsync(value)
    this.setSelectedServiceAsync(undefined)
  }

  @Emit('onSelectedServiceChanged')
  onSelectedServiceChanged (selectedService : Service) : Service {
    this.setSelectedServiceAsync(selectedService)
    return selectedService
  }

  @Emit('noSchoolGroupsAvailable')
  onNoSchoolGroupsAvailable () : void {
    // emits onNoSchoolGroupsAvailable
  }

  onNextClicked () : void {
    if (this.getIsValidForm()) {
      this.$emit('onNextClicked', this.userSelectionState.formState.selectedService)
    }
  }

  validateSchoolForm (isValid :{ isValidSchool: boolean, isValidSchoolGroup: boolean, isSchoolSelected: boolean, isSchoolGroupSelected: boolean, numberOfSchoolGroups : number }) : void {
    this.isSchoolselectionValid = isValid.isValidSchool && isValid.isValidSchoolGroup && isValid.isSchoolSelected && isValid.isSchoolGroupSelected
  }

  getPopupWidth () : number {
    const width = window.innerWidth - 96
    return width > 520 ? 520 : width
  }
}

const regexes = {
  input: {
    initialRegex: /^([A-Z\u00C0-\u00ff\u0100-\u017f.]\.?)+$/i,
    nameRegex: /^([A-Z\u00C0-\u00ff\u0100-\u017f' .-])+$/i,
    phoneNumberRegex: /^(?:[\d+-\s]|Enter|Delete|Backspace|ArrowRight|ArrowLeft|ArrowUp|ArrowDown|Tab|Home|End)$/g,
    numberRegex: /^(?:\d|Enter|Delete|Backspace|ArrowRight|ArrowLeft|ArrowUp|ArrowDown|Tab|Home|End)$/g,
    postalcodeRegex: /^(?:[A-Z\d]|Enter|Delete|Backspace|ArrowRight|ArrowLeft|ArrowUp|ArrowDown|Tab|Home|End| {,6})/i,
    dateRegex: /^(?:[\d+-]|Enter|Delete|Backspace|ArrowRight|ArrowLeft|ArrowUp|ArrowDown|Tab|Home|End)$/g
  },
  validation: {
    initialRegex: /^(?:[A-Z\u00C0-\u00ff\u0100-\u017f]\.*){1,4}$/i,
    bsnRegex: /^\d{8,9}$/,
    nameRegex: /^([A-Z\u00C0-\u00ff\u0100-\u017f]{2,})([ /-][A-Z\u00C0-\u00ff\u0100-\u017f]{2,}){0,10}$/i,
    emailRegex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/,
    postalcodeRegex: /^(?:\d{4} ?(?!sa|sd|ss)[a-z]{2})$/i,
    phoneNumberRegex: /^((\+?|00)[0-9][0-9][0-9]?(\s|\s?-\s?)?(\(0\)[-\s]?)?|0)[1-9]((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]$/
  }
}
export { regexes }

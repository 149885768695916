import IContactDetailsFormState from './IContactDetailsFormState'

export const STATE = {
  formState: 'formState',
  createBookingState: 'createBookingState'
}

export default interface IContactDetailsState {
  formState: IContactDetailsFormState
}


import { Component, Prop, Vue } from 'vue-property-decorator'
import PartouFloatingCircleButton from '@/components/PartouComponents/Buttons/PartouFloatingCircleButton.vue'
import PartouButton from '@/components/PartouComponents/Buttons/PartouButton.vue'
import PartouCard from '@/components/PartouComponents/PartouCard.vue'
import OrderContactInfoForm from './OrderContactInfoForm.vue'
import { ROUTES } from '@/router/routes'
import { NAMESPACES, STATE, ACTIONS, GETTERS } from '@/store'
import { namespace } from 'vuex-class'
import IUserSelectionFormState from '@/store/modules/userSelection/IUserSelectionFormState'
import IContactDetailsFormState from '@/store/modules/contactDetails/IContactDetailsFormState'
import { eventBus } from '@/EventBus'
import { isArray } from 'lodash'
import Page from '@/pages/Page'
import i18n from '@/plugins/i18n'
import SharingMenu from '@/components/SharingMenu/SharingMenu.vue'
import { Proposition } from '@/models'
import { sendPersonalDataAnalyticsEvent } from '@/plugins/googleAnalytics/gtagFunctions'
import moment from 'moment'

const userSelectionModule = namespace(NAMESPACES.userSelection)
const contactDetailsModule = namespace(NAMESPACES.contactDetails)
const propositionModule = namespace(NAMESPACES.proposition)

@Component({
  components: { PartouFloatingCircleButton, PartouButton, PartouCard, OrderContactInfoForm, SharingMenu },
  metaInfo () {
    return {
      title: (this as any).pageTitle, // eslint-disable-line @typescript-eslint/no-explicit-any
      meta: [
        { property: 'title', content: (this as any).pageTitle }, // eslint-disable-line @typescript-eslint/no-explicit-any
        { name: 'robots', content: 'noindex, nofollow' }
      ]
    }
  }
})
export default class OrderContactInfoPage extends Vue implements Page {
  pageTitle = i18n.t('pageTitles.orderContactInfo').toString()

  @userSelectionModule.State(STATE.userSelection.formState)
  userSelectionFormState!: IUserSelectionFormState

  @contactDetailsModule.State(STATE.contactDetails.formState)
  contactDetailsFormState!: IContactDetailsFormState

  @userSelectionModule.Action(ACTIONS.userSelection.resetStateAsync) resetUserSelectionStateAsync!: () => Promise<void>
  @contactDetailsModule.Action(ACTIONS.contactDetails.resetStateAsync) resetContactDetailStateAsync!: () => Promise<void>

  @propositionModule.Getter(GETTERS.proposition.getPropositionById)
  getPropositionById!: (id: string) => Proposition | undefined

  @Prop({ required: false })
  bookingHash!: string

  @Prop({ required: true })
  serviceSlug!: string

  @Prop({ required: false, default: null })
  causedByReasonType!: string | null

  @Prop({ required: false })
  causedByReasonComment: string | undefined

  @Prop({ required: false, default: '' })
  anchor?: string

  isValid = false

  async mounted () : Promise<void> {
    if (this.anchor) {
      if (this.anchor === 'careTaker-1') {
        eventBus.$emit('secondCareTakerEditClicked')
      }

      await new Promise(resolve => setTimeout(resolve, 10))

      const formElement = this.$refs.form as Vue
      const element = formElement.$refs[this.anchor] as HTMLElement

      if (isArray(element)) {
        const elements = element as HTMLElement[]
        this.scrollIntoView(elements[0])
      } else {
        this.scrollIntoView(element)
      }
    }
  }

  scrollIntoView (element : HTMLElement) : void {
    if (element && element.scrollIntoView) {
      element.scrollIntoView()
    }
  }

  onBackButtonClicked () : void {
    this.$router.push({ name: ROUTES.orderOverview, params: { bookingHash: this.bookingHash } })
  }

  onIsValidUpdated (isValid : boolean) : void {
    Vue.set(this, 'isValid', isValid)
  }

  onNextClicked () : void {
    const form = (this.$refs.form as Vue).$refs['order-contact-form'] as HTMLFormElement
    const careTakerBsnForms = (this.$refs.form as Vue).$refs['care-taker-bsn-forms'] as HTMLFormElement[]
    const postalCodeForms = (this.$refs.form as Vue).$refs['postal-code-form'] as HTMLFormElement[]
    const childBsnForm = (this.$refs.form as Vue).$refs['child-bsn-form'] as HTMLFormElement
    const careTakerBsnValidateResult = careTakerBsnForms.every(x => x.validate())
    const postalCodeValidateResult = postalCodeForms[0].validate()
    const childBsnValidateResult = this.isBsnRequired() ? childBsnForm.validate() : true
    this.isValid = form.validate() && careTakerBsnValidateResult && childBsnValidateResult && postalCodeValidateResult
    if (this.isValid) {
      sendPersonalDataAnalyticsEvent()
      this.updateUserSelectionFormStateIfNeeded()
      this.$router.push({ name: ROUTES.contactInfoCheckPage, params: { serviceSlug: this.serviceSlug, bookingHash: this.bookingHash } })
    } else {
      this.scrollToInvalidElement([form]) || this.scrollToInvalidElement(careTakerBsnForms) || this.scrollToInvalidElement(postalCodeForms) || this.scrollToInvalidElement([childBsnForm])
    }
  }

  updateUserSelectionFormStateIfNeeded () : void {
    if (this.causedByReasonType != null) {
      this.userSelectionFormState.causedByReasonType = this.causedByReasonType
    }

    if (this.causedByReasonComment != null) {
      this.userSelectionFormState.causedByReasonComment = this.causedByReasonComment
    }
  }

  scrollToInvalidElement (forms: HTMLFormElement[]): boolean {
    for (let i = 0; i < forms.length; ++i) {
      if (forms[i]) {
        const formElements = Array.from(forms[i].$children) as HTMLFormElement[]
        const invalidFormElements = formElements.filter(x => x.internalIsComponentValid !== undefined ? !x.internalIsComponentValid : !x.inputValue && (x.$vnode.componentOptions.tag === 'PartouTextField' || x.$vnode.componentOptions.tag === 'DateInputField'))
        if (invalidFormElements.length > 0) {
          invalidFormElements[0]?.$el.scrollIntoView({ behavior: 'auto', block: 'center', inline: 'center' })
          return true
        }
      }
    }

    return false
  }

  isBsnRequired () : boolean {
    const requiredBeforeDate = moment().add(-4, 'weeks')
    const dateOfBirth = moment(this.userSelectionFormState.dateOfBirth)
    return dateOfBirth <= requiredBeforeDate
  }

  get sharingTitle () : string {
    if (this.userSelectionFormState?.selectedPropositionId) {
      return this.getPropositionById(this.userSelectionFormState.selectedPropositionId)?.name ?? ''
    } else { return '' }
  }
}


import { Component, Vue } from 'vue-property-decorator'
import PartouTextField from '@/components/PartouComponents/Input/PartouTextField/PartouTextField.vue'
import PartouButton from '@/components/PartouComponents/Buttons/PartouButton.vue'
import PartouFloatingShieldButton from '@/components/PartouComponents/Buttons/PartouFloatingShieldButton.vue'
import PartouAutocomplete from '@/components/PartouComponents/Input/PartouAutoComplete/PartouAutoComplete.vue'
import DaySelector from '@/components/InputFields/DaySelector/DaySelector.vue'
import DayCheckboxType from '@/components/InputFields/DaySelector/DayCheckboxType'
import { DayOfWeek } from '@/models'
import PartouRadio from '@/components/PartouComponents/Input/PartouRadio/PartouRadio.vue'
import PartouCheckBox from '@/components/PartouComponents/Input/PartouCheckBox/PartouCheckBox.vue'

@Component({
  components: {
    PartouTextField,
    PartouButton,
    PartouFloatingShieldButton,
    PartouAutocomplete,
    DaySelector,
    PartouRadio,
    PartouCheckBox
  }
})
export default class ComponentsPage extends Vue {
  isValid = false
  componentValue = {
    textField: '',
    days: [
      { day: DayOfWeek.Monday, type: DayCheckboxType.Check, isChecked: false },
      { day: DayOfWeek.Tuesday, type: DayCheckboxType.Wait, isChecked: false },
      { day: DayOfWeek.Wednesday, type: DayCheckboxType.Closed, isChecked: false },
      { day: DayOfWeek.Thursday, type: DayCheckboxType.Check, isChecked: true },
      { day: DayOfWeek.Friday, type: DayCheckboxType.Wait, isChecked: true },
      { day: DayOfWeek.Saturday, type: DayCheckboxType.Closed, isChecked: true },
      { day: DayOfWeek.Sunday, type: DayCheckboxType.Check, isChecked: false }
    ]
  }

  textFieldChanged (newValue: string) :void {
    this.componentValue.textField = newValue
  }

  checkSimonRule (value: string): boolean | string {
    const errorMsgIfNotValid = 'simon is required'
    if (value !== 'simon') {
      return errorMsgIfNotValid
    }
    return true
  }

  selectedRadioItem = 1
}

import DayCheckboxState from '@/components/InputFields/DaySelector/DayCheckboxState'
import DayCheckboxType from '@/components/InputFields/DaySelector/DayCheckboxType'
import { ServiceKind } from '@/models'
import { getInitialDaySelectionState } from '@/models/types/DaySelectionState'
import IState from '@/store/IState'
import IUserSelectionState from '../IUserSelectionState'
import moment from 'moment'
import i18n from '@/plugins/i18n'
import ServiceVarietyName, { sortedServiceVarietyNames } from '@/models/enums/ServiceVarietyName'

export function getDayCheckboxStateWaitingListProposition (state: IUserSelectionState, rootState : IState) : Record<ServiceVarietyName, DayCheckboxState[]> {
  const daycheckboxState: Record<ServiceVarietyName, DayCheckboxState[]> = {
    KDV: getInitialDaySelectionState().map((x, index) => ({ day: x.day, type: DayCheckboxType.Check, isChecked: state.formState.selectedServiceKind === ServiceKind.DayCare ? state.formState.daysPerServiceVariety.KDV[index]?.isChecked ?? false : false, isReadOnly: false, priority: x.priority })),
    NSO: getInitialDaySelectionState().map((x, index) => ({ day: x.day, type: DayCheckboxType.Check, isChecked: state.formState.selectedServiceKind === ServiceKind.SchoolCare ? state.formState.daysPerServiceVariety.NSO[index]?.isChecked ?? false : false, isReadOnly: false, priority: x.priority })),
    VSO: getInitialDaySelectionState().map((x, index) => ({ day: x.day, type: DayCheckboxType.Check, isChecked: state.formState.selectedServiceKind === ServiceKind.SchoolCare ? state.formState.daysPerServiceVariety.VSO[index]?.isChecked ?? false : false, isReadOnly: false, priority: x.priority }))
  }

  sortedServiceVarietyNames.forEach(serviceVarietyName => {
    const days = daycheckboxState[serviceVarietyName]
    const availableDays = rootState.proposition.waitinglistPropositionStatus?.availableWaitingListDays ? rootState.proposition.waitinglistPropositionStatus.availableWaitingListDays[serviceVarietyName] : []
    if (!availableDays || availableDays.length === 0) {
      days.forEach(x => {
        x.isDisabled = true
      })
      daycheckboxState[serviceVarietyName] = days
      return
    }

    days.forEach(x => {
      const isSelectable = availableDays?.find((d: { dayOfWeek: string, validFrom: string }) => d.dayOfWeek === x.day)
      x.type = DayCheckboxType.Check
      x.isReadOnly = !isSelectable
      x.isDisabled = !isSelectable
      x.startDate = isSelectable?.validFrom ? moment(isSelectable.validFrom).locale('nl').format('D MMMM YYYY') : i18n.t('daySelector.notAvailable').toString()
    })

    if (serviceVarietyName === ServiceVarietyName.VSO) {
      days.forEach((x, index) => {
        if (!daycheckboxState.NSO[index].isChecked && !daycheckboxState.NSO[index].isDisabled) {
          x.isDisabled = true
          x.isChecked = false
        }
      })
    }
    daycheckboxState[serviceVarietyName] = days
  })
  return daycheckboxState
}

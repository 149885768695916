
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({

})
export default class OpenAvailabilitySelectorButton extends Vue {
  @Prop({ required: true })
  text! : string
}

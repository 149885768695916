import BookingService from '@/services/BookingService/BookingService'
import IBookingService from '@/services/BookingService/IBookingService'
import ContractService from '@/services/ContractService/ContractService'
import IContractService from '@/services/ContractService/IContractService'
import ConfigurationService from '@/services/ConfigurationService/ConfigurationService'
import IConfigurationService from '@/services/ConfigurationService/IConfigurationService'
import EmailService from '@/services/EmailService/EmailService'
import IEmailService from '@/services/EmailService/IEmailService'
import FeatureService from '@/services/FeatureService/FeatureService'
import IFeatureService from '@/services/FeatureService/IFeatureService'
import hasuraService from '@/services/HasuraService/HasuraService'
import IOfferService from '@/services/OfferService/IOfferService'
import OfferService from '@/services/OfferService/OfferService'
import IPostalCodeService from '@/services/PostalCodeService/IPostalCodeService'
import PostalCodeService from '@/services/PostalCodeService/PostalCodeService'
import IPropositionService from '@/services/PropositionService/IPropositionService'
import PropositionService from '@/services/PropositionService/PropositionService'
import IChildBenefitCalculatorService from '@/services/ChildBenefitCalculatorService/IChildBenefitCalculatorService'
import ChildBenefitCalculatorService from '@/services/ChildBenefitCalculatorService/ChildBenefitCalculatorService'
import ISchoolServiceService from '@/services/SchoolServiceService/ISchoolServiceService'
import SchoolServiceService from '@/services/SchoolServiceService/SchoolServiceService'
import IShareService from '@/services/ShareService/IShareService'
import ShareService from '@/services/ShareService/ShareService'
import ISubscriptionService from '@/services/SubscriptionService/ISubscriptionService'
import SubscriptionService from '@/services/SubscriptionService/SubscriptionService'
import IWaitingListService from '@/services/WaitingListService/IWaitingListService'
import WaitingListService from '@/services/WaitingListService/WaitingListService'
import ApolloClient from 'apollo-client'
import axios, { AxiosInstance } from 'axios'
import container, { SERVICE_IDENTIFIERS } from './container'
import IServiceSettingsService from '@/services/ServiceSettingsService/IServiceSettingsService'
import ServiceSettingsService from '@/services/ServiceSettingsService/ServiceSettingsService'
import IProductService from '@/services/ProductService/IProductService'
import ProductService from '@/services/ProductService/ProductService'

export function initDependencies () : void {
  container.bind(ApolloClient).toConstantValue(hasuraService.apollo)
  container.bind<AxiosInstance>(SERVICE_IDENTIFIERS.Axios).toConstantValue(axios)
  container.bind<IPropositionService>(SERVICE_IDENTIFIERS.IPropositionService).to(PropositionService)
  container.bind<IChildBenefitCalculatorService>(SERVICE_IDENTIFIERS.IChildBenefitCalculatorService).to(ChildBenefitCalculatorService)
  container.bind<IOfferService>(SERVICE_IDENTIFIERS.IOfferService).to(OfferService)
  container.bind<IPostalCodeService>(SERVICE_IDENTIFIERS.IPostalCodeService).to(PostalCodeService)
  container.bind<IBookingService>(SERVICE_IDENTIFIERS.IBookingService).to(BookingService)
  container.bind<IContractService>(SERVICE_IDENTIFIERS.IContractService).to(ContractService)
  container.bind<ISchoolServiceService>(SERVICE_IDENTIFIERS.ISchoolServiceService).to(SchoolServiceService)
  container.bind<IServiceSettingsService>(SERVICE_IDENTIFIERS.IServiceSettingsService).to(ServiceSettingsService)
  container.bind<IConfigurationService>(SERVICE_IDENTIFIERS.IConfigurationService).to(ConfigurationService)
  container.bind<IFeatureService>(SERVICE_IDENTIFIERS.IFeatureService).to(FeatureService)
  container.bind<IEmailService>(SERVICE_IDENTIFIERS.IEmailService).to(EmailService)
  container.bind<IWaitingListService>(SERVICE_IDENTIFIERS.IWaitingListService).to(WaitingListService)
  container.bind<ISubscriptionService>(SERVICE_IDENTIFIERS.ISubscriptionService).to(SubscriptionService)
  container.bind<IShareService>(SERVICE_IDENTIFIERS.IShareService).to(ShareService)
  container.bind<IProductService>(SERVICE_IDENTIFIERS.IProductService).to(ProductService)
}

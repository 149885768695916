
import { Component, Prop, Vue } from 'vue-property-decorator'
import VueSelect from 'vue-select'
import PartouChevronDown from '@/components/PartouComponents/Icons/PartouChevronDown.vue'
import i18n from '@/plugins/i18n'
import { debounce } from 'lodash'

@Component({
  components: { VueSelect }
})
export default class PartouAutocomplete extends Vue {
  @Prop({ required: false, default: '' })
  prependIconString? : string

  @Prop({ required: false, default: undefined })
  maxWidth? : string

  @Prop({ required: false, default: '' })
  appendIconString? : string

  @Prop({ required: false, default: i18n.t('autocomplete.noData') })
  noDataText! : string

  @Prop({ required: false, default: false })
  clearable! : boolean

  @Prop({ required: false, default: () => PartouChevronDown })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  openIndicator! : any

  @Prop({ required: false, default: false })
  disableRotateOfIcon! : boolean

  @Prop({ required: false, default: false })
  fillIcon! : boolean

  dropUp = false
  components = { OpenIndicator: this.openIndicator }
  dropdownOpened = false

  mounted () : void {
    window.addEventListener('scroll', this.setDropDownLocation)
    window.addEventListener('resize', debounce(this.setDropDownLocation, 100))
    window.addEventListener('touchmove', this.setDropDownLocation)
    this.setDropDownLocation()
  }

  beforeDestroy () : void {
    window.removeEventListener('scroll', this.setDropDownLocation)
    window.removeEventListener('resize', this.setDropDownLocation)
    window.removeEventListener('touchmove', this.setDropDownLocation)
  }

  setDropDownLocation () : void {
    const boundingClientRect = this.$el.getBoundingClientRect()
    const dropdownHeight = 304
    this.dropUp = boundingClientRect.top > dropdownHeight && (this.$el.getBoundingClientRect().bottom + dropdownHeight > window.innerHeight)
  }
}

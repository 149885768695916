import * as Sentry from '@sentry/vue'
import IState from '@/store/IState'
import router from '@/router/router'
import { Store } from 'vuex'
import Vue from 'vue'

export function configureSentryScope (store : Store<IState>) : void {
  Sentry.configureScope(scope => {
    const state = {
      userSelection: {
        dateOfBirth: store.state.userSelection.formState.dateOfBirth,
        daysPerServiceVariety: store.state.userSelection.formState.daysPerServiceVariety,
        isAcceptingWaitinglist: store.state.userSelection.formState.isAcceptingWaitinglist,
        isUsingChildbenefitCalculator: store.state.userSelection.formState.isUsingChildbenefitCalculator,
        postalCode: store.state.userSelection.formState.postalCode?.postcode,
        selectedPropositionId: store.state.userSelection.formState.selectedPropositionId,
        selectedSchool: store.state.userSelection.formState.selectedSchool,
        selectedSchoolGroup: store.state.userSelection.formState.selectedSchoolGroup,
        selectedSchoolLocality: store.state.userSelection.formState.selectedSchoolLocality,
        selectedService: store.state.userSelection.formState.selectedService,
        selectedServiceKind: store.state.userSelection.formState.selectedServiceKind,
        selectedServiceVarieties: store.state.userSelection.formState.selectedServiceVarieties,
        proposedSubscription: store.state.userSelection.formState.proposedSubscription,
        selectedSubscription: store.state.userSelection.formState.selectedSubscription,
        startDateOfDayCare: store.state.userSelection.formState.startDateOfDayCare,
        shareHash: store.state.userSelection.formState.shareHash
      },
      propositionIds: store.state.proposition?.propositions?.flatMap(item => item.id)
    }

    scope.setContext('state', {
      state: {
        type: 'Vuex',
        value: state
      }
    })
  })
}

export function initSentry (): void {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router)
      })
    ],
    release: process.env.VUE_APP_VERSION,
    tracesSampleRate: 0.2,
    normalizeDepth: 10,
    environment: process.env.VUE_APP_PROJECT_ENV
  })
}

import container, { SERVICE_IDENTIFIERS } from '@/init/container'
import IConfigurationService from '@/services/ConfigurationService/IConfigurationService'
import IState from '@/store/IState'
import { ActionTree } from 'vuex'
import { MUTATIONS } from './childBenefitCalculatorMutations'
import ICalculatorConstants from './ICalculatorConstants'
import ICalculatorState from './ICalculatorState'

export const ACTIONS = {
  fetchConstantsOnceAsync: 'fetchConstantsOnceAsync'
}

export function actions (): ActionTree<ICalculatorState, IState> {
  return {
    async fetchConstantsOnceAsync ({ state, commit }) {
      if (state.constants.isLoaded) return

      const configurationService: IConfigurationService = container.get(SERVICE_IDENTIFIERS.IConfigurationService)
      const constants = await configurationService.getConfigurationByKeyAsync<ICalculatorConstants>({ key: 'kot_settings', date: new Date() })

      commit(MUTATIONS.setConstants, { isLoaded: true, ...constants })
    }
  }
}

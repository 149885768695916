
import { Component, Vue, Prop, Emit } from 'vue-property-decorator'

@Component({})
export default class ChildBenefitSummaryTable extends Vue {
  @Prop({ required: true })
  costsPerHour! : number

  @Prop({ required: true })
  costsPerMonth! : number

  @Prop({ required: true })
  benefitsPerHour! : number

  @Prop({ required: true })
  benefitsPerMonth! : number

  @Prop({ required: true })
  ownContributionPerMonth! : number

  @Prop({ required: true })
  ownContributionPerHour! : number

  @Prop({ required: true })
  careHoursPerMonth! : number

  @Prop({ required: false, default: false })
  showWarningText!: boolean

  @Emit('onBenefitAmountInfoClicked')
  onBenefitAmountInfoClicked () : void {
    // Emits onBenefitAmountInfoClicked
  }
}

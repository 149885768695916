import PlanSettings from '@/models/configuration/PlanSettings'

export const STATE = {
  configuration: 'configuration',
  planSettings: 'planSettings'
}

export default interface IConfigurationState {
    configuration?: Record<string, any>  /* Disabled because of any */// eslint-disable-line
    planSettings?: PlanSettings
}

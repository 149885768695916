
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

@Component
export default class PartouAutocomplete extends Vue {
  @Prop({ required: true, default: () => [] })
  items! : string[]

  @Prop({ required: false, default: undefined })
  selectedItem?: string

  @Prop({ required: false, default: '' })
  prependIconString? : string

  @Prop({ required: false, default: '' })
  appendIconString? : string

  @Prop({ required: false, default: '' })
  placeholdertext? : string

  @Prop({ required: false, default: 'form.noData' })
  noDataText? : string

  @Prop({ required: false, default: false })
  disabled? : boolean

  @Prop({ required: false, default: false })
  cachingEnabled?: boolean

  @Emit('onAutocompleteChanged')
  onAutocompleteChanged (value: string) : string {
    return value
  }
}

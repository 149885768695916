
import { ACTIONS, NAMESPACES, STATE, GETTERS } from '@/store'
import { Vue, Component, Ref } from 'vue-property-decorator'
import { namespace, State } from 'vuex-class'
import PartouNotify from '@/components/PartouComponents/PartouNotify.vue'
import PartouSocials from '@/components/PartouComponents/PartouSocials.vue'
import PartouAutocomplete from '@/components/PartouComponents/Input/PartouAutoComplete/PartouAutoCompleteN.vue'
import ChildBenefitSummaryHeader from './ChildBenefitSummaryHeader.vue'
import PartouButton from '@/components/PartouComponents/Buttons/PartouButton.vue'
import ChildBenefitCalculatorInputForm from '@/components/ChildBenefitCalculator/ChildBenefitCalculatorInputForm/ChildBenefitCalculatorInputForm.vue'
import ChildBenefitCalculatorResult from '@/components/ChildBenefitCalculator/ChildBenefitCalculatorResult/ChildBenefitCalculatorResult.vue'
import { capitalizeFirstLetters } from '@/utils/stringUtils'
import PropositionSubscription from '@/models/PropositionSubscription'
import IPropositionService from '@/services/PropositionService/IPropositionService'
import { DayOfWeek, Maybe, Service, ServiceKind, SubscriptionCode, ServiceVarietyName } from '@/models'
import container, { SERVICE_IDENTIFIERS } from '@/init/container'
import moment, { Moment } from 'moment'
import IUserSelectionFormState from '@/store/modules/userSelection/IUserSelectionFormState'
import IChildBenefitCalculatorService from '@/services/ChildBenefitCalculatorService/IChildBenefitCalculatorService'
import DayCheckboxState from '@/components/InputFields/DaySelector/DayCheckboxState'
import { sendCompletedCalculationAnalyticsEvent } from '@/plugins/googleAnalytics/gtagFunctions'
import { addBusinessDays, minimalBusinessDaysBeforeCareStartDate } from '@/utils/dateUtils'
import { eventBus } from '@/EventBus'
import ServicePricing from '@/models/ServicePricing'
import PropositionSubscriptionTypes from '@/models/enums/PropositionSubscriptionTypes'

const childBenefitCalculatorModule = namespace(NAMESPACES.childBenefitCalculator)
const userSelectionModule = namespace(NAMESPACES.userSelection)

@Component({
  components: { PartouAutocomplete, PartouButton, ChildBenefitSummaryHeader, ChildBenefitCalculatorInputForm, ChildBenefitCalculatorResult, PartouNotify, PartouSocials }
})
export default class ChildBenefitCalculatorWithoutProposition extends Vue {
  @Ref('calculation-result')
  calculationResult!: Vue

  @childBenefitCalculatorModule.Action(ACTIONS.childBenefitCalculator.fetchConstantsOnceAsync)
  fetchConstantsOnceAsync! : () => void

  @userSelectionModule.State(STATE.userSelection.formState)
  userSelectionFormState!: IUserSelectionFormState

  @userSelectionModule.Getter(GETTERS.userSelection.getUniqueSelectedDays)
  getUniqueSelectedDays!: () => DayOfWeek[]

  @userSelectionModule.Getter(GETTERS.userSelection.getDayCheckboxState)
  getDayCheckboxState!: (withAvailability: boolean, withOpeningHours: boolean) => Record<ServiceVarietyName, DayCheckboxState[]>

  @State(NAMESPACES.configuration)
  configuration! : Record<string, any>  /* Disabled because of any */// eslint-disable-line

  propositionService! : IPropositionService
  childBenefitCalculatorService! : IChildBenefitCalculatorService

  currentYear = new Date().getFullYear()
  selectedService? : Service
  propositionSubscription? : PropositionSubscription
  showCalculation = false
  noCalculationPossible = false
  isGeneralInfoVisible = false
  currentAndFutureServicePricings: ServicePricing[] = []
  selectedServicePricing: ServicePricing | null = null
  rerenderKey = 0
  isCalculating = false
  allPropositionSubscriptions?: PropositionSubscription[] | null = []

  calculationResultProposition (): PropositionSubscription | undefined {
    if (!this.propositionSubscription || !this.selectedServicePricing || this.propositionSubscription.referenceType !== PropositionSubscriptionTypes.Product) {
      return this.propositionSubscription
    }

    return this.selectedServicePricing.propositionSubscription
  }

  created () : void {
    this.propositionService = container.get<IPropositionService>(SERVICE_IDENTIFIERS.IPropositionService)
    this.childBenefitCalculatorService = container.get<IChildBenefitCalculatorService>(SERVICE_IDENTIFIERS.IChildBenefitCalculatorService)
    this.fetchConstantsOnceAsync()
  }

  getServicePricingLabel (servicePricing: ServicePricing) : string {
    if (servicePricing.validFrom) {
      return 'vanaf ' + capitalizeFirstLetters(moment(servicePricing.validFrom).locale('nl').format('MMMM YYYY'))
    }

    return ''
  }

  onNoSchoolGroupsAvailable (): void {
    this.noCalculationPossible = true
  }

  async onPricingChangeDateChangedAsync (value: ServicePricing): Promise<void> {
    if (!this.selectedService) {
      return
    }

    this.selectedServicePricing = value

    // For the old price calculation we need to make a request to recalculate pricing on each date change. For the new
    // price calculation we immediately get all prices on the PropositionSubscription, so we simply increment the
    // rerenderkey to re-render the calculation result.
    if (this.propositionSubscription?.referenceType !== PropositionSubscriptionTypes.Product) {
      await this.startCalculationAsync(this.selectedService, moment(value.validFrom))
    } else {
      this.rerenderKey++
    }
  }

  async onSelectedServiceChanged (service: Service) : Promise<void> {
    this.selectedService = service
    if (!this.selectedService) {
      return
    }

    if (service.serviceSettings[0].useFlexkidsProducts) {
      this.noCalculationPossible = false
      return
    }

    if (service?.servicePricings.length <= 0) {
      this.noCalculationPossible = true
    }

    if (!service?.serviceVarieties.some(x => x.serviceVarietyOpenings.length)) {
      this.noCalculationPossible = true
    }
  }

  async onNextClickedAsync (service: Service) : Promise<void> {
    this.isCalculating = true

    await this.startCalculationAsync(service)
      .then(() => {
        if (this.propositionSubscription) {
          this.toggleShowCalculation()
        }
        if (this.calculationResult) {
          (this.calculationResult.$refs['calculation-result'] as HTMLElement).scrollIntoView()
        }
      }).catch(() => {
        eventBus.$emit('globalError')
        this.isCalculating = false
      })
  }

  async startCalculationAsync (service: Service, startDate?: Moment) : Promise<void> {
    if (!service) {
      return
    }

    this.allPropositionSubscriptions = await this.fetchPropositionSubscriptionsAsync(service, startDate)
    const currentPropositionSubscription = this.allPropositionSubscriptions?.find(x => x.numberOfWeeks === 52)
    if (!currentPropositionSubscription) {
      return
    }

    this.propositionSubscription = currentPropositionSubscription
    this.rerenderKey++

    if (this.propositionSubscription?.referenceType === PropositionSubscriptionTypes.Product) {
      this.extractPriceChanges()
    } else if (this.currentAndFutureServicePricings.length === 0) {
      // For old price calculation request the price change dates only on the first time calculating.
      this.currentAndFutureServicePricings = await this.childBenefitCalculatorService.fetchCurrentAndFuturePricingChangesAsync({
        serviceId: service.id,
        minDate: addBusinessDays(new Date(), minimalBusinessDaysBeforeCareStartDate)
      })
      this.selectedServicePricing = this.currentAndFutureServicePricings[0]
    }
  }

  onCalculationFinished () : void {
    if (this.selectedService) {
      sendCompletedCalculationAnalyticsEvent(this.selectedService, this.getUniqueSelectedDays(), this.userSelectionFormState.selectedSchool)
    }
    this.isCalculating = false
  }

  async fetchPropositionSubscriptionsAsync (service: Service, startDate?: Moment) : Promise<Maybe<PropositionSubscription[]>> {
    let dateOfBirth
    if (!startDate || startDate < moment(addBusinessDays(new Date(), minimalBusinessDaysBeforeCareStartDate))) {
      startDate = moment(addBusinessDays(new Date(), minimalBusinessDaysBeforeCareStartDate))
    }
    const selectedDays = this.getDayCheckboxState(false, true)
    const availableSelectedDays : Record<ServiceVarietyName, DayOfWeek[]> = { KDV: [], VSO: [], NSO: [] }
    if (this.userSelectionFormState.selectedServiceKind === ServiceKind.DayCare) {
      dateOfBirth = moment().subtract(1, 'year')
      availableSelectedDays.KDV = selectedDays.KDV.filter((day) => day.isChecked && day.type !== 'Closed').map((day) => day.day)
    } else {
      dateOfBirth = moment().subtract(5, 'year')
      if (service.serviceVarieties.some((value) => value.name === ServiceVarietyName.VSO)) {
        availableSelectedDays.VSO = selectedDays.VSO.filter((day) => day.isChecked && day.type !== 'Closed').map((day) => day.day)
      }
      availableSelectedDays.NSO = selectedDays.NSO.filter((day) => day.isChecked && day.type !== 'Closed').map((day) => day.day)
    }

    let requestedProductIds : string[] | undefined
    if (service.serviceSettings[0].useFlexkidsProducts) {
      requestedProductIds = await this.fetchCalculatorProducts(service, startDate, availableSelectedDays)
    }

    return this.propositionService.getPropositionSubscriptionsAsync({
      offerId: service.id,
      schoolGroupId: this.userSelectionFormState.selectedSchoolGroup?.id ?? '',
      dateOfBirth: dateOfBirth.toISOString(),
      startDateOfCare: startDate.toISOString(),
      availableDaysPerServiceVariety: availableSelectedDays,
      waitingListDaysPerServiceVariety: {},
      requestedSubscriptionsCodes: [SubscriptionCode[SubscriptionCode.AllWeeks]],
      requestedProductIds
    })
  }

  async fetchCalculatorProducts (service: Service, startDate: Moment, selectedDays: Record<ServiceVarietyName, DayOfWeek[]>) : Promise<string[]> {
    const products = await this.childBenefitCalculatorService.getChildBenefitCalculatorProductsAsync({
      offerId: service.id,
      referenceDate: startDate
    })

    const productIds : string[] = []
    Object.values(ServiceVarietyName).forEach((serviceVariety) => {
      const product = products[serviceVariety]
      if (selectedDays[serviceVariety].length && product) {
        productIds.push(product)
      }
    })

    return productIds
  }

  extractPriceChanges (): void {
    this.currentAndFutureServicePricings = []
    this.selectedServicePricing = null

    if (!this.allPropositionSubscriptions || this.allPropositionSubscriptions?.length === 0) {
      return
    }

    this.allPropositionSubscriptions.sort((a, b) => {
      const dateA = a.priceAgreementValidFrom ? a.priceAgreementValidFrom.getTime() : -Infinity
      const dateB = b.priceAgreementValidFrom ? b.priceAgreementValidFrom.getTime() : -Infinity
      return dateA - dateB
    })

    let lastPricing: ServicePricing | undefined
    for (let i = 0; i < this.allPropositionSubscriptions.length; i++) {
      const propositionSubscription = this.allPropositionSubscriptions[i]
      if (!lastPricing || lastPricing.hourRate !== propositionSubscription.pricePerHour) {
        const validFrom = propositionSubscription.priceAgreementValidFrom?.toISOString()
        lastPricing = {
          validFrom: validFrom ?? '',
          hourRate: propositionSubscription.pricePerHour ?? 0,
          monthlyRate: propositionSubscription.priceOfFirstFullMonth ?? 0,
          numberOfHours: propositionSubscription.averageAvailableHoursPerMonth ?? 0,
          propositionSubscription
        }

        this.currentAndFutureServicePricings.push(lastPricing)
      }
    }
  }

  onGeneralInfoClicked (): void {
    this.isGeneralInfoVisible = true
  }

  toggleShowCalculation () : void {
    this.showCalculation = !this.showCalculation
  }

  getPopupWidth () : number {
    const width = window.innerWidth - 96
    return width > 520 ? 520 : width
  }
}

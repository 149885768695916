
import Availability from '@/models/enums/Availability'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class AvailabilityIndicator extends Vue {
  @Prop({ required: true })
  availability! : Availability

  Availability = Availability
}

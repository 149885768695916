
import { Component, Prop, Vue } from 'vue-property-decorator'
import PartouCheckBox from '@/components/PartouComponents/Input/PartouCheckBox/PartouCheckBox.vue'

export type Term = {
  label: string
  isAccepted: boolean
  isMandatory: boolean
}

@Component({
  components: { PartouCheckBox }
})
export default class PartouTerms extends Vue {
  @Prop({ required: true })
  terms! : Term[]

  isRequiredRule (value: string) : boolean | string {
    return !!value || this.$t('form.required').toString()
  }
}

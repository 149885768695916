
import { Component, Prop, Vue } from 'vue-property-decorator'
import PartouSocials from '@/components/PartouComponents/PartouSocials.vue'
import { ContactInfo } from '@/models'

@Component({
  components: { PartouSocials }
})
export default class OfferNotAvailableContent extends Vue {
  @Prop({ required: false })
  contactInfo?: ContactInfo
}


import { Component, Prop, Ref, Vue } from 'vue-property-decorator'
import { Component as VueComponent } from 'vue'

@Component
export default class PartouButton extends Vue {
  @Ref('button')
  button!: VueComponent & { $el: HTMLButtonElement }

  @Prop({ required: false, default: '' })
  text? : string

  @Prop({ required: false, default: 'normal' })
  iconSize? : 'normal' | 'large' | 'extra-large'

  @Prop({ required: false, default: '' })
  iconColor? : string

  @Prop({ required: false, default: 16 })
  fontSize? : 11 | 14 | 16 | 24

  @Prop({ required: false, default: '' })
  iconName? : string

  @Prop({ required: false, default: 'right' })
  iconPosition? : 'left' | 'right'

  @Prop({ required: false, default: 'normal' })
  size? : 'normal' | 'small' | 'large'

  @Prop({ required: false, default: 'partou-primary-gradient' })
  colour! : 'partou-primary-gradient' | 'partou-secondary-bright-green' | 'partou-black-ninety' | 'partou-secondary-bordeaux' | 'partou-secondary-bordeaux-fourty' | 'partou-secondary-bordeaux-diop' | 'partou-black-eighty'

  @Prop({ required: false, default: false })
  disabled?: boolean

  @Prop({ required: false, default: false })
  loading?: boolean

  @Prop({ required: false, default: '' })
  className! : string

  @Prop({ required: false, default: false })
  wrapLabel?: boolean

  focus () : void {
    this.$nextTick(() => this.button.$el.focus())
  }
}

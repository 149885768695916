
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class WaitingListHeader extends Vue {
  @Prop({ required: true })
  imageSrc!: string

  @Prop({ required: true })
  title!: string
}

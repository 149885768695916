
import { Component, Prop, Vue } from 'vue-property-decorator'
import PartouButton from '@/components/PartouComponents/Buttons/PartouButton.vue'
import BookingStatusError from './BookingStatusError'

@Component({
  components: { PartouButton }
})
export default class BookingStatusErrorContent extends Vue {
  @Prop({ required: true, default: 0 })
  bookingStatusError! : BookingStatusError

  BookingStatusError = BookingStatusError

  onButtonClicked () : void {
    this.$emit('onButtonClicked')
  }

  getTranslation (content: string): string {
    return this.$i18n.t(`bookingStatusPage.error.${this.bookingStatusError}.${content}`).toString()
  }
}


import { Vue, Component } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { NAMESPACES, STATE } from '@/store'
import PartouSocials from '@/components/PartouComponents/PartouSocials.vue'
import PartouButton from '@/components/PartouComponents/Buttons/PartouButton.vue'
import IUserSelectionFormState from '@/store/modules/userSelection/IUserSelectionFormState'
import ServiceKind from '@/models/enums/ServiceKind'
import PartouTextButton from '@/components/PartouComponents/Buttons/PartouTextButton.vue'
import { ROUTES } from '@/router/routes'
import { sendCustomSubscriptionChosenAnalyticsEvent } from '@/plugins/googleAnalytics/gtagFunctions'

const userSelectionModule = namespace(NAMESPACES.userSelection)
const configurationModule = namespace(NAMESPACES.configuration)

@Component({
  components: { PartouSocials, PartouButton, PartouTextButton }
})
export default class CustomSubscription extends Vue {
  @userSelectionModule.State(STATE.userSelection.formState)
  userSelectionFormState!: IUserSelectionFormState

  @configurationModule.State(STATE.configuration.configuration)
  configuration! : Record<string, any>  /* Disabled because of any */// eslint-disable-line

  showCustomSubscription = false
  hasMdmId = false

  async created () : Promise<void> {
    this.hasMdmId = !!this.userSelectionFormState.selectedService?.mdmId
  }

  onShowCustomSubscriptionClicked (): void {
    this.showCustomSubscription = !this.showCustomSubscription
  }

  onBookExternalClicked () : void {
    if (this.configuration.externalUrls.bookPage && this.userSelectionFormState.selectedService?.mdmId) {
      sendCustomSubscriptionChosenAnalyticsEvent()
      this.$router.redirectToPartouSite(ROUTES.external.getBookPageUrl(), this.userSelectionFormState.selectedServiceKind as ServiceKind, this.userSelectionFormState.selectedService?.mdmId)
    }
  }
}

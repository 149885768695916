import { Feature, GetDefaultFeaturesDocument, GetDefaultFeaturesQuery } from '@/models'
import ApolloClient from 'apollo-client'
import { inject, injectable } from 'inversify'
import IFeatureService from './IFeatureService'

@injectable()
export default class FeatureService implements IFeatureService {
  private readonly apollo: ApolloClient<unknown>
  constructor (@inject(ApolloClient) apollo: ApolloClient<unknown>) {
    this.apollo = apollo
  }

  public async getDefaultFeaturesAsync () : Promise<Feature[]> {
    const result = await this.apollo.query<GetDefaultFeaturesQuery>({
      query: GetDefaultFeaturesDocument
    })
    return result.data.feature as Feature[]
  }
}

import { ProductViewData } from './ProductViewData'

export class ProductCategoryViewData {
  constructor (name: string, products: ProductViewData[]) {
    this.name = name
    this.products = products
    this.features = undefined
  }

    name!: string
    products: ProductViewData[] = []
    features?: {name: string, title: string, isIncluded: boolean, description: string}[]
}


import PageHeader from '@/components/PageHeader/PageHeader.vue'
import PartouFloatingShieldButton from '@/components/PartouComponents/Buttons/PartouFloatingShieldButton.vue'
import PartouFloatingCircleButton from '@/components/PartouComponents/Buttons/PartouFloatingCircleButton.vue'
import PartouButton from '@/components/PartouComponents/Buttons/PartouButton.vue'
import PartouAutocomplete from '@/components/PartouComponents/Input/PartouAutoComplete/PartouAutoComplete.vue'
import PartouCheckBox from '@/components/PartouComponents/Input/PartouCheckBox/PartouCheckBox.vue'
import PartouNotify from '@/components/PartouComponents/PartouNotify.vue'
import PartouSocials from '@/components/PartouComponents/PartouSocials.vue'
import SchoolSelectionForm from '@/components/AvailabilitySelector/SchoolSelectionForm.vue'
import { ROUTES } from '@/router/routes'
import { GETTERS, MUTATIONS, NAMESPACES, STATE } from '@/store'
import IUserSelectionFormState from '@/store/modules/userSelection/IUserSelectionFormState'
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import FakeLink from '@/components/Generic/FakeLink.vue'
import Page from '@/pages/Page'
import i18n from '@/plugins/i18n'

const userSelectionModule = namespace(NAMESPACES.userSelection)
const configurationModule = namespace(NAMESPACES.configuration)

@Component({
  components: {
    PageHeader,
    PartouFloatingShieldButton,
    PartouFloatingCircleButton,
    PartouButton,
    PartouAutocomplete,
    PartouCheckBox,
    PartouNotify,
    PartouSocials,
    SchoolSelectionForm,
    FakeLink
  },
  metaInfo () {
    return {
      title: (this as any).pageTitle, // eslint-disable-line @typescript-eslint/no-explicit-any
      meta: [
        { property: 'title', content: (this as any).pageTitle }, // eslint-disable-line @typescript-eslint/no-explicit-any
        { name: 'robots', content: 'noindex, nofollow' }
      ]
    }
  }
})
export default class SchoolSelectionPage extends Vue implements Page {
  pageTitle = i18n.t('pageTitles.schoolSelectionPage').toString()

  @userSelectionModule.Mutation(MUTATIONS.userSelection.setCurrentStep)
  setCurrentStep!: (currentStep: number) => void

  @userSelectionModule.State(STATE.userSelection.formState)
  userSelectionFormState!: IUserSelectionFormState

  @configurationModule.State(STATE.configuration.configuration)
  configuration! : Record<string, any> /* Disabled because of any */// eslint-disable-line

  @userSelectionModule.Getter(GETTERS.userSelection.getIsValidSchoolLocality)
  getIsValidSchoolLocality!: () => boolean

  @userSelectionModule.Getter(GETTERS.userSelection.getIsValidSchool)
  getIsValidSchool!: () => boolean

  @userSelectionModule.Getter(GETTERS.userSelection.getIsValidSchoolGroup)
  getIsValidSchoolGroup!: () => boolean

  locationsRoute = ROUTES.locations
  locationsParams = { postalCode: '', serviceKind: '' }

  onBackButtonClicked (): void {
    this.$router.push({ name: ROUTES.selectionGuide })
  }

  onMyLocationsClicked (): void {
    this.setCurrentStep(2)
    this.locationsParams.postalCode = this.userSelectionFormState.postalCode?.postcode ?? ''
    this.locationsParams.serviceKind = i18n.t('pageUrls.serviceKindSchoolCareSuffix').toString()
    this.$router.push({ name: this.locationsRoute, params: this.locationsParams })
  }
}

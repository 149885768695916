
import { Component, Vue } from 'vue-property-decorator'
import PartouAutocomplete from '@/components/PartouComponents/Input/PartouAutoComplete/PartouAutoCompleteN.vue'
import PartouLocationSearchNew from '@/components/PartouComponents/Icons/PartouLocationSearch.vue'
import container, { SERVICE_IDENTIFIERS } from '@/init/container'
import { namespace } from 'vuex-class'
import { NAMESPACES, ACTIONS, STATE } from '@/store'
import SchoolServiceService from '@/services/SchoolServiceService/SchoolServiceService'
import IUserSelectionFormState from '@/store/modules/userSelection/IUserSelectionFormState'

const userSelectionModule = namespace(NAMESPACES.userSelection)

@Component({
  components: { PartouAutocomplete, PartouLocationSearchNew }
})
export default class SchoolLocalitySelector extends Vue {
  @userSelectionModule.State(STATE.userSelection.formState)
  userSelectionFormState!: IUserSelectionFormState

  @userSelectionModule.Action(ACTIONS.userSelection.setSelectedSchoolLocalityAsync)
  setSelectedSchoolLocalityAsync!: (selectedSchoolLocality: string) => Promise<void>

  schoolServiceService! : SchoolServiceService
  schoolLocalityOptions : string[] = []
  selectedSchoolLocality = ''

  beforeCreate () : void {
    this.schoolServiceService = container.get(SERVICE_IDENTIFIERS.ISchoolServiceService)
  }

  async mounted () : Promise<void> {
    const localityOptions = await this.schoolServiceService.getSchoolLocalityOptions(this.userSelectionFormState.isUsingChildbenefitCalculator ?? false, this.userSelectionFormState.selectedService?.id ?? '')
    this.schoolLocalityOptions = [...new Set(localityOptions.map(x => x.capitalizeFirstLetters()))].sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
    if (this.userSelectionFormState.selectedSchoolLocality) {
      this.selectedSchoolLocality = this.userSelectionFormState.selectedSchoolLocality
    }
  }

  onSelectedLocalityChanged () : void {
    this.setSelectedSchoolLocalityAsync(this.selectedSchoolLocality)
  }
}

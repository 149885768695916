import IState from '@/store/IState'
import { ActionTree } from 'vuex'
import { MUTATIONS } from './contactDetailsMutations'
import IContactDetailsState from './IContactDetailsState'

export const ACTIONS = {
  resetStateAsync: 'resetStateAsync',
  setEmail: 'setEmail',
  setChildDateOfBirth: 'setChildDateOfBirth',
  setChildsBsn: 'setChildsBsn'
}

export function actions (): ActionTree<IContactDetailsState, IState> {
  return {
    async resetStateAsync ({ commit }) {
      commit(MUTATIONS.resetState)
    },
    async setEmail ({ commit }, email : string) {
      commit(MUTATIONS.setEmail, email)
    },
    async setChildDateOfBirth ({ commit }, dateOfBirth : Date) {
      commit(MUTATIONS.setChildDateOfBirth, dateOfBirth)
    },
    async setChildsBsn ({ commit }, bsn : string) {
      commit(MUTATIONS.setChildsBsn, bsn)
    }
  }
}

import { render, staticRenderFns } from "./OpeningTimes.vue?vue&type=template&id=63fa2ceb&scoped=true&"
import script from "./OpeningTimes.vue?vue&type=script&lang=ts&"
export * from "./OpeningTimes.vue?vue&type=script&lang=ts&"
import style0 from "./OpeningTimes.vue?vue&type=style&index=0&id=63fa2ceb&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63fa2ceb",
  null
  
)

export default component.exports
import ICalculatorFormState from './ICalculatorFormState'
import ICalculatorConstants from './ICalculatorConstants'

export const STATE = {
  formState: 'formState',
  constants: 'constants'
}

export default interface ICalculatorState {
  constants: ICalculatorConstants & { isLoaded: boolean }
  formState: ICalculatorFormState & { formIsValid: boolean }
}

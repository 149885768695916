enum WaitingListPlacePriority {
    OwnStaff = 'OwnStaff',
    Smi = 'Smi',
    SecondChild = 'SecondChild',
    DayCareToSchoolCare = 'DayCareToSchoolCare',
    Expansion = 'Expansion',
    WaitingListFamily = 'WaitingListFamily',
    MoveHouse = 'MoveHouse',
    New = 'New',
    ChangeDays = 'ChangeDays',
    ChangeGroup = 'ChangeGroup',
    ChangeLocation = 'ChangeLocation',
    Extension = 'Extension',
    SleepingWaitingList = 'SleepingWaitingList',
    VveIndication = 'VveIndication',
    PovWithoutKot = 'PovWithoutKot'
}
export default WaitingListPlacePriority

import { inject, injectable } from 'inversify'
import ApolloClient, { ApolloError } from 'apollo-client'
import IWaitingListService, { AcceptProposedWaitingListPropositionRequest } from './IWaitingListService'
import UnprocessableWaitingListError from './UnprocessableWaitingListError'
import { ExtendWaitingListDocument, GetWaitingListStatusDocument, GetWaitingListStatusOutput, QueryRoot, CancelWaitingListDocument, DeclineProposedWaitingListPropositionOutput, DeclineProposedWaitingListPropositionDocument, AcceptProposedWaitingListPropositionSubscriptionSubscriptionVariables, AcceptProposedWaitingListPropositionSubscriptionDocument, AcceptProposedWaitingListPropositionRequestMutationVariables, AcceptProposedWaitingListPropositionRequestDocument, AcceptProposedWaitingListPropositionOutput, DeclineProposedWaitingListPropositionMutationVariables, DayOfWeek, ServiceVarietyName } from '@/models'
import IOperationContext from '../HasuraService/OperationContext'
import { StatusCodes } from 'http-status-codes'
import HttpResponseError from '@/Errors/HttpResponseError'
import IUserSelectionFormState from '@/store/modules/userSelection/IUserSelectionFormState'
import IContactDetailsFormState from '@/store/modules/contactDetails/IContactDetailsFormState'
import { buildCareTakerPayloadAsync, buildChildPayloadAsync, buildRemoveFromWaitingListDaysPerServiceVarietyPayloadAsync, buildSchoolPayload, buildSelectedDaysPayloadAsync } from '@/services/PayloadBuilders'
import DaySelectionState from '@/models/types/DaySelectionState'
import { toPromise } from '@/services/HasuraService/HasuraService'

@injectable()
export default class WaitingListService implements IWaitingListService {
  private readonly apollo: ApolloClient<unknown>
  constructor (@inject(ApolloClient) apollo: ApolloClient<unknown>) {
    this.apollo = apollo
  }

  async cancelWaitingList (bookingHash: string): Promise<void> {
    const variables = { bookingHash }
    const context: IOperationContext = {
      expectedErrorCodes: [StatusCodes.UNPROCESSABLE_ENTITY]
    }

    try {
      await this.apollo.mutate({
        mutation: CancelWaitingListDocument,
        variables,
        context
      })
    } catch (e) {
      if (e instanceof ApolloError && e.networkError instanceof HttpResponseError) {
        const statusCode = e.networkError.statusCode
        if (context.expectedErrorCodes.some(x => x === statusCode)) {
          throw new UnprocessableWaitingListError()
        }
      }
      throw e
    }
  }

  async extendWaitingList (bookingHash: string): Promise<void> {
    const variables = { bookingHash }
    const context: IOperationContext = {
      expectedErrorCodes: [StatusCodes.UNPROCESSABLE_ENTITY]
    }

    try {
      await this.apollo.mutate({
        mutation: ExtendWaitingListDocument,
        variables,
        context
      })
    } catch (e) {
      if (e instanceof ApolloError && e.networkError instanceof HttpResponseError) {
        const statusCode = e.networkError.statusCode
        if (context.expectedErrorCodes.some(x => x === statusCode)) {
          throw new UnprocessableWaitingListError()
        }
      }
      throw e
    }
  }

  public async getWaitingListStatusByHashAsync (bookingHash: string): Promise<GetWaitingListStatusOutput> {
    const variables = { hash: bookingHash }
    const queryResult = await this.apollo.query<QueryRoot>({
      query: GetWaitingListStatusDocument,
      variables,
      fetchPolicy: 'no-cache'
    })

    return queryResult.data.getWaitingListStatus as GetWaitingListStatusOutput
  }

  public async getAcceptProposedWaitingListPropositionResponseAsync (request : AcceptProposedWaitingListPropositionRequest) : Promise<AcceptProposedWaitingListPropositionOutput> {
    const variables : AcceptProposedWaitingListPropositionSubscriptionSubscriptionVariables = {
      RequestId: request.requestUuid
    }

    const context: IOperationContext = {
      expectedErrorCodes: [StatusCodes.UNPROCESSABLE_ENTITY, StatusCodes.GONE]
    }
    try {
      const subscription = this.apollo.subscribe({
        query: AcceptProposedWaitingListPropositionSubscriptionDocument,
        variables
      })

      const response = await toPromise(subscription, context)
      return response.data.acceptProposedWaitingListProposition.output as AcceptProposedWaitingListPropositionOutput
    } catch (e) {
      if (e instanceof HttpResponseError) {
        const statusCode = e.statusCode
        if (context.expectedErrorCodes.some(x => x === statusCode)) {
          throw new UnprocessableWaitingListError()
        }
      }
      throw e
    }
  }

  public async acceptProposedWaitingListPropositionAsync (bookingHash: string, days: Partial<Record<ServiceVarietyName, DaySelectionState[]>>,
    userSelectionFormState: IUserSelectionFormState, contactDetailsFormState: IContactDetailsFormState,
    removeFromWaitingListDaysPerServiceVariety: Record<ServiceVarietyName, DayOfWeek[]> | undefined, causedByReasonType: string | undefined, causedByReasonComment: string | undefined): Promise<AcceptProposedWaitingListPropositionRequest> {
    const childPayload = await buildChildPayloadAsync(contactDetailsFormState.child)
    const selectedDays = buildSelectedDaysPayloadAsync(days)
    const primaryCareTakerPayload = await buildCareTakerPayloadAsync(contactDetailsFormState.careTakers[0])
    const secondaryCareTakerPayload = contactDetailsFormState.careTakers.length > 1 ? await buildCareTakerPayloadAsync(contactDetailsFormState.careTakers[1]) : undefined
    const schoolPayload = buildSchoolPayload(userSelectionFormState.selectedSchool, userSelectionFormState.selectedSchoolGroup)
    const subscriptionServiceId = userSelectionFormState.selectedSubscription?.id ?? ''
    const removeFromWaitingListDays = removeFromWaitingListDaysPerServiceVariety ? buildRemoveFromWaitingListDaysPerServiceVarietyPayloadAsync(removeFromWaitingListDaysPerServiceVariety) : null
    const variables : AcceptProposedWaitingListPropositionRequestMutationVariables = {
      hash: bookingHash,
      selectedDays,
      Child: childPayload,
      PrimaryCareTaker: primaryCareTakerPayload,
      SecondaryCareTaker: secondaryCareTakerPayload,
      SelectedSchool: schoolPayload,
      SelectedSubscriptionServiceId: subscriptionServiceId,
      RemoveFromWaitingListDaysPerServiceVariety: removeFromWaitingListDays,
      CausedByReasonType: causedByReasonType,
      CausedByReasonComment: causedByReasonComment
    }

    const context: IOperationContext = {
      expectedErrorCodes: [StatusCodes.UNPROCESSABLE_ENTITY, StatusCodes.GONE]
    }

    try {
      const requestResult = await this.apollo.mutate({
        mutation: AcceptProposedWaitingListPropositionRequestDocument,
        variables,
        context
      })
      const requestUuid = requestResult.data.acceptProposedWaitingListProposition
      return {
        requestUuid,
        hash: bookingHash,
        childPayload,
        selectedDays,
        schoolPayload,
        selectedSubscription: userSelectionFormState.selectedSubscription
      }
    } catch (e) {
      if (e instanceof HttpResponseError) {
        const statusCode = e.statusCode
        if (context.expectedErrorCodes.some(x => x === statusCode)) {
          throw new UnprocessableWaitingListError()
        }
      }

      throw e
    }
  }

  public async declineProposedWaitingListPropositionAsync (bookingHash: string, removeFromWaitingListDaysPerServiceVariety: Record<ServiceVarietyName, DayOfWeek[]> | undefined, causedByReasonType: string, causedByReasonComment: string | undefined): Promise<DeclineProposedWaitingListPropositionOutput> {
    const variables : DeclineProposedWaitingListPropositionMutationVariables = {
      BookingHash: bookingHash,
      RemoveFromWaitingListDaysPerServiceVariety: removeFromWaitingListDaysPerServiceVariety,
      CausedByReasonType: causedByReasonType,
      CausedByReasonComment: causedByReasonComment === '' || causedByReasonComment === undefined ? null : causedByReasonComment
    }
    const result = await this.apollo.mutate({
      mutation: DeclineProposedWaitingListPropositionDocument,
      variables
    })

    return result.data.declineProposedWaitingListProposition as DeclineProposedWaitingListPropositionOutput
  }
}

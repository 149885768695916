
import PartouButton from '@/components/PartouComponents/Buttons/PartouButton.vue'
import PartouCard from '@/components/PartouComponents/PartouCard.vue'
import PartouDialog from '@/components/PartouComponents/PartouDialog.vue'
import container, { SERVICE_IDENTIFIERS } from '@/init/container'
import IWaitingListService from '@/services/WaitingListService/IWaitingListService'
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  components: { PartouCard, PartouButton, PartouDialog }
})
export default class AcceptWaitingListActions extends Vue {
  @Prop({ required: true })
  isOrderButtonDisabled! : boolean

  @Prop({ required: true })
  bookingHash!: string

  waitingListService!: IWaitingListService

  created () : void {
    this.waitingListService = container.get<IWaitingListService>(SERVICE_IDENTIFIERS.IWaitingListService)
  }

  onOrderButtonClicked () : void {
    this.$emit('onOrderClicked')
  }

  onDeclineButtonClicked () : void {
    this.$emit('onDeclineButtonClicked')
  }
}

import IState from '@/store/IState'
import { ActionTree } from 'vuex'
import IFeatureState from './IFeatureState'
import IFeatureService from '@/services/FeatureService/IFeatureService'
import { MUTATIONS } from './FeatureMutations'

export const ACTIONS = {
  getDefaultFeaturesAsync: 'getDefaultFeaturesAsync'
}

export function actions (featureService : IFeatureService): ActionTree<IFeatureState, IState> {
  return {
    async getDefaultFeaturesAsync ({ commit }) {
      commit(MUTATIONS.setDefaultFeatures, null)

      const data = await featureService.getDefaultFeaturesAsync()

      commit(MUTATIONS.setDefaultFeatures, data)
    }
  }
}

import Vue, { DirectiveOptions } from 'vue'

const clickOutsideDirective: DirectiveOptions = {
  bind: function (el : any, binding, vnode) { /* Disabled because of any: HTML element does not contain custom method */// eslint-disable-line 
    el.clickOutsideEvent = function (event : any) { /* Disabled because of any */// eslint-disable-line
      // here I check that click was outside the el and his childrens
      if (!(el === event.target || el.contains(event.target)) && vnode.context && binding.expression && typeof (vnode.context as any)[binding.expression] === 'function') { /* Disabled because of any */// eslint-disable-line 
        // and if it did, call method provided in attribute value
        (vnode.context as any)[binding.expression](event)  /* Disabled because of any */// eslint-disable-line 
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: function (el : any) {   /* Disabled because of any: HTML element does not contain custom method */// eslint-disable-line 
    document.body.removeEventListener('click', el.clickOutsideEvent)
  }
}

Vue.directive('click-outside', clickOutsideDirective)
export default clickOutsideDirective

import { Module } from 'vuex'
import IState from '@/store/IState'
import { mutations, MUTATIONS } from './ConfigurationMutations'
import { actions, ACTIONS } from './ConfigurationActions'
import IConfigurationState, { STATE } from './IConfigurationState'
import container, { SERVICE_IDENTIFIERS } from '@/init/container'
import IConfigurationService from '@/services/ConfigurationService/IConfigurationService'

export function state (): IConfigurationState {
  return {
    configuration: {}
  }
}

export function module (): Module<IConfigurationState, IState> {
  const ConfigurationService = container.get<IConfigurationService>(SERVICE_IDENTIFIERS.IConfigurationService)
  return {
    namespaced: true,
    state: state(),
    mutations: mutations(),
    actions: actions(ConfigurationService)
  }
}
export const NAMESPACE = 'configuration'
export { ACTIONS }
export { MUTATIONS }
export { STATE }

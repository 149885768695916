enum Category
{
    AllWeeks = 'AllWeeks',
    WorkingWeeks = 'WorkingWeeks',
    SchoolWeeks = 'SchoolWeeks',
    Divergent = 'Divergent',
    Unknow = ''
}

export default Category


import { Component, Prop, Vue, Watch, Ref } from 'vue-property-decorator'

import mapStyles from './mapStyles'
import { Proposition } from '@/models'
import MapLoader from './MapLoader'
import { eventBus } from '@/EventBus'
import * as Sentry from '@sentry/browser'
import Availability from '@/models/enums/Availability'

@Component
export default class ServicesMap extends Vue {
  @Ref('services-map')
  servicesMap?: HTMLElement

  @Prop({ required: true })
  value!: Proposition | Proposition[] | null

  @Prop({ required: false, default: null })
  homeLocation!: google.maps.LatLngLiteral | null

  @Prop({ required: false, default: null })
  zoom!: number | null

  @Prop({ required: false })
  autoZoomOut: number | undefined

  @Prop({ required: false, default: false })
  disableUserActions!: boolean

  map: google.maps.Map | undefined
  mapLoader!: MapLoader

  positionMarkers: google.maps.Marker[] | undefined = []
  homeMarker: google.maps.Marker | undefined

  defaultZoom = 15

  created (): void {
    try {
      this.mapLoader = new MapLoader()
    } catch (exception) {
      Sentry.captureException(exception)
    }
  }

  mounted (): void {
    this.initMap()
    eventBus.$on('onMarkerClicked', this.bounceMarker)
    eventBus.$on('onPanTo', this.panTo)
  }

  async initMap (): Promise<void> {
    try {
      await this.mapLoader.load()

      const element = document.getElementById('map') as HTMLElement
      // Set the default center to the center of the Netherlands, bounds will be redefined by the shown markers
      const center: google.maps.LatLngLiteral = { lat: 52.23, lng: 4.55 } // eslint-disable-line -- lint does not recognize namespace

      this.map = new google.maps.Map(element, { // eslint-disable-line -- lint does not recognize namespace
        center,
        styles: mapStyles,
        mapTypeControl: false,
        fullscreenControl: false,
        streetViewControl: false,
        zoomControl: false,
        gestureHandling: this.disableUserActions ? 'none' : null,
        zoom: this.zoom ?? this.defaultZoom,
        keyboardShortcuts: !this.disableUserActions
      })

      this.setMarkers()
    } catch (exception) {
      Sentry.captureException(exception)
    }
  }

  @Watch('value')
  onValueChanged (newValue: Proposition | Proposition[] | null, oldValue: Proposition | Proposition[] | null): void {
    if (oldValue && newValue) {
      const oldPropositions = Array.isArray(oldValue) ? oldValue : [oldValue]
      const newPropositions = Array.isArray(newValue) ? newValue : [newValue]

      // Do not recreate markers if they're unchanged
      if (oldPropositions.length === newPropositions.length) {
        if (!newPropositions.some(n => !oldPropositions.some(o => o.id === n.id && o.availability === n.availability))) {
          return
        }
      }
    }
    this.setMarkers()
  }

  @Watch('homeLocation')
  onHomeLocationChanged (newValue: google.maps.LatLngLiteral | undefined, oldValue: google.maps.LatLngLiteral | undefined): void {
    // Do not recreate marker if it is unchanged
    if (oldValue && newValue) {
      if (oldValue.lat === newValue.lat && oldValue.lng === newValue.lng) {
        return
      }
    }
    this.setMarkers()
  }

  panTo (lat: number, long: number): void {
    const location: google.maps.LatLngLiteral = { lat: lat, lng: long } // eslint-disable-line -- lint does not recognize namespace
    if (this.map) {
      this.map.panTo(location)
    }
  }

  setMarkers (): void {
    try {
      if (!this.map) {
        return
      }

      // Clear the markers on the map first
      if (this.positionMarkers) {
        this.positionMarkers.forEach(x => x.setMap(null))
      }
      if (this.homeMarker) {
        this.homeMarker.setMap(null)
      }

      // First one should be on top, so start zIndex at array length
      const propositions = this.value && (Array.isArray(this.value) ? this.value : [this.value])
      let zIndex = propositions?.length ?? 0
      this.positionMarkers = propositions?.map(x => {
        const marker = new google.maps.Marker({ // eslint-disable-line -- lint does not recognize namespace
          position: { lat: x.latitude, lng: x.longitude },
          map: this.map,
          title: x.name,
          icon: this.getMarkerIconForProposition(x),
          zIndex: zIndex--
        })
        marker.set('id', x.id)
        if (!this.disableUserActions) {
          marker.addListener('click', () => {
            this.$emit('markerClicked', x)
          })
        }

        return marker
      })

      this.homeMarker = this.homeLocation
        ? this.getHomeMarker(this.homeLocation, this.map)
        : undefined

      this.setBounds()
    } catch (exception) {
      Sentry.captureException(exception)
    }
  }

  @Watch('zoom')
  setBounds (): void {
    if (!this.map) {
      return
    }

    const bounds = new google.maps.LatLngBounds() // eslint-disable-line -- lint does not recognize namespace

    if (this.positionMarkers) {
      this.positionMarkers.forEach(x => {
        const position = x.getPosition()
        if (position) {
          bounds.extend(position)
        }
      })
    }

    if (this.homeLocation) {
      bounds.extend(this.homeLocation)
    }

    if (!bounds.isEmpty()) {
      // Set restrictions BEFORE fitting bounds! Otherwise map will show badly sometimes
      const maxBoundsSW = new google.maps.LatLng({ lat: bounds.getSouthWest().lat() - 0.030, lng: bounds.getSouthWest().lng() - 0.055 }) // eslint-disable-line -- lint does not recognize namespace
      const maxBoundsNE = new google.maps.LatLng({ lat: bounds.getNorthEast().lat() + 0.030, lng: bounds.getNorthEast().lng() + 0.055 }) // eslint-disable-line -- lint does not recognize namespace
      this.map.setOptions({ restriction: { latLngBounds: new google.maps.LatLngBounds(maxBoundsSW, maxBoundsNE), strictBounds: false } }) // eslint-disable-line -- lint does not recognize namespace

      const sw = new google.maps.LatLng({ lat: bounds.getSouthWest().lat() - 0.0025, lng: bounds.getSouthWest().lng() - 0.0025 }) // eslint-disable-line -- lint does not recognize namespace
      const ne = new google.maps.LatLng({ lat: bounds.getNorthEast().lat() + 0.0025, lng: bounds.getNorthEast().lng() + 0.0025 }) // eslint-disable-line -- lint does not recognize namespace
      this.map.fitBounds(new google.maps.LatLngBounds(sw, ne))  // eslint-disable-line -- lint does not recognize namespace
    }

    let zoom = this.zoom
    if (!zoom && this.autoZoomOut) {
      zoom = (this.map.getZoom() ?? 0) - this.autoZoomOut
    }

    if (zoom) {
      const map = this.map
      const listener = map.addListener('idle', () => { // eslint-disable-line -- lint does not recognize namespace
        if (zoom) {
          map.setZoom(zoom)
        }
        google.maps.event.removeListener(listener) // eslint-disable-line -- lint does not recognize namespace
      })
    }
  }

  getMarkerIconImageForProposition (proposition: Proposition): string {
    switch (proposition.availability) {
    case undefined:
      return 'services-marker-partou-red.svg'
    case Availability.Available:
      return 'service-marker-green.svg'
    case Availability.WaitingList:
    case Availability.NotBookable:
      return 'service-marker-blue.svg'
    default:
      return 'service-marker-orange.svg'
    }
  }

  getMarkerIconForProposition (proposition: Proposition): google.maps.Icon { // eslint-disable-line -- lint does not recognize namespace
    // Size of the svg marker icon.
    const markerSize = new google.maps.Size(56, 59); // eslint-disable-line -- lint does not recognize namespace
    return {
      url: require('@/assets/mapsIcons/' + this.getMarkerIconImageForProposition(proposition)),
      size: markerSize,
      // Origin point (0,0) defaults to top left.
      origin: new google.maps.Point(0, 0), // eslint-disable-line -- lint does not recognize namespace
      // Points to the base of the marker
      anchor: new google.maps.Point(markerSize.width / 2, markerSize.height) // eslint-disable-line -- lint does not recognize namespace
    }
  }

  getHomeMarker (homeLocation: google.maps.LatLngLiteral, map: google.maps.Map): google.maps.Marker { // eslint-disable-line -- lint does not recognize namespace
    // Size of the svg home icon.
    const homeMarkerSize = new google.maps.Size(50, 53); // eslint-disable-line -- lint does not recognize namespace
    return new google.maps.Marker({ // eslint-disable-line -- lint does not recognize namespace
      position: homeLocation,
      map,
      title: 'Home',
      icon: {
        url: require('@/assets/mapsIcons/home-marker.svg'),
        size: homeMarkerSize,
        // Origin point (0,0) defaults to top left.
        origin: new google.maps.Point(0, 0), // eslint-disable-line -- lint does not recognize namespace,
        anchor: new google.maps.Point(homeMarkerSize.width / 2, homeMarkerSize.height / 2) // eslint-disable-line -- lint does not recognize namespace,
      },
      zIndex: 0
    })
  }

  async bounceMarker (markerId: string): Promise<void> {
    this.toggleMarkerBounce(markerId)
    if (this.servicesMap) {
      this.servicesMap.scrollIntoView()
    }
    await new Promise(resolve => setTimeout(resolve, 4000))
    this.toggleMarkerBounce(markerId)
  }

  toggleMarkerBounce (markerId: string): void {
    if (this.positionMarkers) {
      this.positionMarkers.forEach(x => {
        if (x.get('id') === markerId) {
          if (x.getAnimation() === undefined || x.getAnimation() === null) {
            x.setAnimation(google.maps.Animation.BOUNCE) // eslint-disable-line -- lint does not recognize namespace
          } else {
            x.setAnimation(null)
          }
        }
      })
    }
  }
}


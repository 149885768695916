import { inject, injectable } from 'inversify'
import ApolloClient from 'apollo-client'
import { CreateBookingRequestDocument, CreateBookingRequestMutationVariables, CreateBookingResponseDocument, CreateBookingResponseSubscriptionVariables, GetBookingByHashQueryVariables, GetBookingStatusDocument, GetBookingStatusOutput, Maybe, Proposition, QueryRoot, ServiceKind, ServiceVarietyName } from '@/models'
import IBookingService, { BookPropositionRequest } from './IBookingService'
import RetryUtils from '@/utils/retryUtils'
import IUserSelectionFormState from '@/store/modules/userSelection/IUserSelectionFormState'
import IContactDetailsFormState from '@/store/modules/contactDetails/IContactDetailsFormState'
import DayCheckboxState from '@/components/InputFields/DaySelector/DayCheckboxState'
import HttpResponseError from '@/Errors/HttpResponseError'
import { StatusCodes } from 'http-status-codes'
import IOperationContext from '@/services/HasuraService/OperationContext'
import OfferNoLongerAvailableError from './OfferNoLongerAvailableError'
import { toPromise } from '@/services/HasuraService/HasuraService'
import { buildCareTakerPayloadAsync, buildChildPayloadAsync, buildReservationPayloadAsync, buildSchoolPayload } from '../PayloadBuilders'

@injectable()
export default class BookingService implements IBookingService {
  private readonly apollo: ApolloClient<unknown>

  constructor (@inject(ApolloClient) apollo: ApolloClient<unknown>) {
    this.apollo = apollo
  }

  public async bookPropostionRequestAsync (proposition: Proposition, userSelectionFormState: IUserSelectionFormState, contactDetailsFormState: IContactDetailsFormState, checkBoxDays: Record<ServiceVarietyName, DayCheckboxState[]>): Promise<BookPropositionRequest> {
    if (!userSelectionFormState.startDateOfDayCare || !userSelectionFormState.dateOfBirth) {
      throw new Error('info not complete')
    }

    const reservationPayload = buildReservationPayloadAsync(proposition, userSelectionFormState.startDateOfDayCare, checkBoxDays)
    const childPayload = await buildChildPayloadAsync(contactDetailsFormState.child)
    const primaryCareTakerPayload = await buildCareTakerPayloadAsync(contactDetailsFormState.careTakers[0])
    const secondaryCareTakerPayload = contactDetailsFormState.careTakers.length > 1 ? await buildCareTakerPayloadAsync(contactDetailsFormState.careTakers[1]) : undefined
    const subscriptionServiceId = userSelectionFormState.selectedSubscription?.id ?? ''
    const selectedProductIds = userSelectionFormState.selectedProductIds ?? []
    const shareHash = userSelectionFormState.shareHash ?? ''

    let schoolPayload
    if (userSelectionFormState.selectedServiceKind === ServiceKind.SchoolCare) {
      schoolPayload = buildSchoolPayload(userSelectionFormState.selectedSchool, userSelectionFormState.selectedSchoolGroup)
    }

    const variables: CreateBookingRequestMutationVariables = {
      ...reservationPayload,
      Child: childPayload,
      PrimaryCareTaker: primaryCareTakerPayload,
      SecondaryCareTaker: secondaryCareTakerPayload,
      SelectedSchool: schoolPayload,
      SelectedSubscriptionServiceId: subscriptionServiceId,
      SelectedProductIds: selectedProductIds,
      shareHash
    }
    const context: IOperationContext = {
      expectedErrorCodes: [StatusCodes.GONE]
    }

    const requestResult = await this.apollo.mutate({
      mutation: CreateBookingRequestDocument,
      variables,
      context
    })
    const requestUuid = requestResult.data.createBooking

    return {
      requestUuid,
      childPayload,
      reservationPayload,
      schoolPayload,
      proposition,
      selectedSubscription: userSelectionFormState.selectedSubscription,
      selectedProductIds
    }
  }

  public async getBookPropositionResponseAsync (request: BookPropositionRequest) : Promise<string> {
    const context: IOperationContext = {
      expectedErrorCodes: [StatusCodes.GONE]
    }

    const variables: CreateBookingResponseSubscriptionVariables = {
      RequestId: request.requestUuid
    }

    try {
      const subscription = this.apollo.subscribe({
        query: CreateBookingResponseDocument,
        variables
      })

      const response = await toPromise(subscription, context)
      const urlHash = response.data.createBooking.output.urlHash

      return urlHash
    } catch (e) {
      if (e instanceof HttpResponseError) {
        const statusCode = e.statusCode
        if (context.expectedErrorCodes.some(x => x === statusCode)) {
          throw new OfferNoLongerAvailableError()
        }
      }

      throw e
    }
  }

  public async getBookingStatusByHashAsync (isSigned: boolean, variables: GetBookingByHashQueryVariables): Promise<GetBookingStatusOutput> {
    let result: Maybe<GetBookingStatusOutput> = {}
    const amountOfRetries = 5
    const timeout = 10

    await RetryUtils.callAsyncWithRetryIfConditionNotMet(async () => {
      let shouldRetry = false
      const queryResult = await this.apollo.query<QueryRoot>({
        query: GetBookingStatusDocument,
        variables,
        fetchPolicy: 'no-cache'
      })
      result = queryResult.data.getBookingStatus

      if (result && isSigned && result.isEmailVerified && (!result.isContractSigned && !result.isContractDeclined)) {
        shouldRetry = true
      }

      return shouldRetry
    }, amountOfRetries, timeout)

    return result
  }
}

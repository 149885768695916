
import { Component, Vue } from 'vue-property-decorator'
import WaitingListHeader from '@/pages/WaitingListFlow/WaitingListComponents/WaitingListHeader.vue'
import WaitingListFooter from '@/pages/WaitingListFlow/WaitingListComponents/WaitingListFooter.vue'

@Component({
  components: { WaitingListHeader, WaitingListFooter }
})
export default class WaitingListsBookedContent extends Vue {
}


import { ServiceVariety, ServiceVarietyOpening, ServiceVarietyName } from '@/models'
import { Component, Prop, Vue } from 'vue-property-decorator'
import OpeningTimes from '@/components/OpeningTimes/OpeningTimes.vue'
import moment from 'moment'
import { isBetween } from '@/utils/dateUtils'

@Component({
  components: { OpeningTimes }
})
export default class OpeningTimesContent extends Vue {
  @Prop({ required: true })
  serviceVarieties!: ServiceVariety[]

  getDayName (dayName: string): string {
    return dayName.substring(0, 2).toLowerCase()
  }

  get sortedServiceVarieties (): ServiceVariety[] {
    const sortedVarieties: ServiceVariety[] = [...this.serviceVarieties]
    sortedVarieties.sort((a, b) => b.name.toLowerCase().localeCompare(a.name.toLowerCase()))
    return sortedVarieties
  }

  getCurrentOpening (serviceVariety: ServiceVariety): ServiceVarietyOpening {
    return serviceVariety.serviceVarietyOpenings.find(opening =>
      isBetween(moment(opening.validFrom).toDate(), opening.validUntil)
    ) ?? {} as ServiceVarietyOpening
  }

  getOverrideFrom (serviceVariety: ServiceVariety) : string | undefined {
    if (serviceVariety.name === ServiceVarietyName.NSO) {
      return this.$t('openingTimes.schoolTime').toString()
    }
  }

  getOverrideUntil (serviceVariety: ServiceVariety) : string | undefined {
    if (serviceVariety.name === ServiceVarietyName.VSO) {
      return this.$t('openingTimes.schoolTime').toString()
    }
  }

  showWeekendOpenings (serviceVariety: ServiceVariety) : boolean {
    const openings = this.getCurrentOpening(serviceVariety)
    return !!(openings.saturday?.length || openings.sunday?.length)
  }
}


import { Component, Vue } from 'vue-property-decorator'
import PartouFloatingShieldButton from '@/components/PartouComponents/Buttons/PartouFloatingShieldButton.vue'
import WaitingListHeader from '@/pages/WaitingListFlow/WaitingListComponents/WaitingListHeader.vue'
import WaitingListFooter from '@/pages/WaitingListFlow/WaitingListComponents/WaitingListFooter.vue'

@Component({
  components: { PartouFloatingShieldButton, WaitingListHeader, WaitingListFooter }
})
export default class CancelSuccessStatusContent extends Vue {}


import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import { SelectableSubscription } from './SelectableSubscription'
import PartouNotify from '@/components/PartouComponents/PartouNotify.vue'
import CustomSubscription from './CustomSubscription/CustomSubscription.vue'

@Component({
  components: { PartouNotify, CustomSubscription }
})
export default class SubscriptionSelect extends Vue {
  @Prop({ required: true, default: () => [] })
  selectableSubscriptions!: SelectableSubscription[]

  @Prop({ required: false, default: true })
  showPricing!: boolean

  showFeatureInfo = false
  featureTitle = ''
  featureDescription = ''

  get selectedSubscriptionNote () : string | undefined {
    const subscriptionService = this.selectableSubscriptions.find(x => x.isSelected)?.subscriptionService

    if (subscriptionService?.isDisplayNote && subscriptionService.note) {
      return subscriptionService.note
    }

    return undefined
  }

  get selectedSubscriptionIsCustomEnabled () : boolean {
    const subscriptionService = this.selectableSubscriptions.find(x => x.isSelected)?.subscriptionService

    return subscriptionService?.service?.isCustomSubscriptionEnabled ?? false
  }

  isCheapestSubscription (subscriptionId : string) : boolean {
    const cheapestSubscription = this.selectableSubscriptions.reduceRight((prev, curr) => prev.propositionSubscription.pricePerHour < curr.propositionSubscription.pricePerHour ? prev : curr)
    return cheapestSubscription?.id === subscriptionId ?? false
  }

  formatHourRate (hourRate : number) : string {
    return `€ ${hourRate.toFixed(2).replace('.', ',')}`
  }

  async onFeatureInfoCancelled () : Promise<void> {
    this.showFeatureInfo = false
    await new Promise(resolve => setTimeout(resolve, 200))
    this.featureTitle = ''
    this.featureDescription = ''
  }

  onFeatureInfoClicked (title : string, description: string) : void {
    this.featureTitle = title
    this.featureDescription = description
    this.showFeatureInfo = true
  }

  @Emit('onSubscriptionSelected')
  onSubscriptionSelected (index : number) : SelectableSubscription {
    this.selectableSubscriptions.forEach(x => { x.isSelected = false })
    this.selectableSubscriptions[index].isSelected = true
    return this.selectableSubscriptions[index]
  }
}

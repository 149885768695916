
import { Component, Prop, Vue } from 'vue-property-decorator'
import PartouButton from '@/components/PartouComponents/Buttons/PartouButton.vue'

@Component({
  components: { PartouButton }
})
export default class ContractSignContent extends Vue {
  @Prop()
  emailAddress! : string

  OnStartSignClicked () : void {
    this.$emit('onStartSignClicked')
  }
}

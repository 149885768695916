import { render, staticRenderFns } from "./PartouTerms.vue?vue&type=template&id=4cdf1692&scoped=true&"
import script from "./PartouTerms.vue?vue&type=script&lang=ts&"
export * from "./PartouTerms.vue?vue&type=script&lang=ts&"
import style0 from "./PartouTerms.vue?vue&type=style&index=0&id=4cdf1692&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cdf1692",
  null
  
)

export default component.exports
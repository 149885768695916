import PartouCopy from '@/components/PartouComponents/Icons/PartouCopy.vue'
import PartouShieldNew from '@/components/PartouComponents/Icons/PartouShieldNew.vue'
import PartouMoneyNew from '@/components/PartouComponents/Icons/PartouMoneyNew.vue'
import PartouPreviousNew from '@/components/PartouComponents/Icons/PartouPreviousNew.vue'
import PartouPrevious2New from '@/components/PartouComponents/Icons/PartouPrevious2New.vue'
import PartouPrevious3New from '@/components/PartouComponents/Icons/PartouPrevious3New.vue'
import PartouNextNew from '@/components/PartouComponents/Icons/PartouNextNew.vue'
import PartouNext2New from '@/components/PartouComponents/Icons/PartouNext2New.vue'
import PartouNext3New from '@/components/PartouComponents/Icons/PartouNext3New.vue'
import PartouDropdownNew from '@/components/PartouComponents/Icons/PartouDropdownNew.vue'
import PartouDropdownReversedNew from '@/components/PartouComponents/Icons/PartouDropdownReversedNew.vue'
import PartouPlayNew from '@/components/PartouComponents/Icons/PartouPlayNew.vue'
import PartouAddNew from '@/components/PartouComponents/Icons/PartouAddNew.vue'
import PartouDeleteNew from '@/components/PartouComponents/Icons/PartouDeleteNew.vue'
import PartouLocationPinNew from '@/components/PartouComponents/Icons/PartouLocationPinNew.vue'
import PartouLocationPin from '@/components/PartouComponents/Icons/PartouLocationPin.vue'
import PartouCalendarNew from '@/components/PartouComponents/Icons/PartouCalendarNew.vue'
import PartouCustomShareIcon from '@/components/PartouComponents/Icons/PartouCustomShareIcon.vue'
import PartouLocationSearchNew from '@/components/PartouComponents/Icons/PartouLocationSearchNew.vue'
import PartouCheck from '@/components/PartouComponents/Icons/PartouCheck.vue'
import PartouArrow from '@/components/PartouComponents/Icons/PartouArrow.vue'
import PartouArrowLeft from '@/components/PartouComponents/Icons/PartouArrowLeft.vue'
import PartouExpansionArrow from '@/components/PartouComponents/Icons/PartouExpansionArrow.vue'
import PartouCircle from '@/components/PartouComponents/Icons/PartouCircle.vue'
import PartouGlobe from '@/components/PartouComponents/Icons/PartouGlobe.vue'
import PartouGradientArrowLeft from '@/components/PartouComponents/Icons/PartouGradientArrowLeft.vue'
import PartouCall from '@/components/PartouComponents/Icons/PartouCall.vue'
import PartouChat from '@/components/PartouComponents/Icons/PartouChat.vue'
import PartouMail from '@/components/PartouComponents/Icons/PartouMail.vue'
import PartouChevronUp from '@/components/PartouComponents/Icons/PartouChevronUp.vue'
import PartouChevronDown from '@/components/PartouComponents/Icons/PartouChevronDown.vue'
import PartouEdit from '@/components/PartouComponents/Icons/PartouEdit.vue'
import PartouWait from '@/components/PartouComponents/Icons/PartouWait.vue'
import PartouLock from '@/components/PartouComponents/Icons/PartouLock.vue'
import PartouClose from '@/components/PartouComponents/Icons/PartouClose.vue'
import PartouSchool from '@/components/PartouComponents/Icons/PartouSchool.vue'
import PartouSearch from '@/components/PartouComponents/Icons/PartouSearch.vue'
import PartouShield from '@/components/PartouComponents/Icons/PartouShield.vue'
import PartouShare from '@/components/PartouComponents/Icons/PartouShare.vue'
import PartouRefresh from '@/components/PartouComponents/Icons/PartouRefresh.vue'
import PartouEditNew from '@/components/PartouComponents/Icons/PartouEditNew.vue'
import PartouLoaderNew from '@/components/PartouComponents/Icons/PartouLoaderNew.vue'
import PartouCalculatorNew from '@/components/PartouComponents/Icons/PartouCalculatorNew.vue'
import PartouInfo from '@/components/PartouComponents/Icons/PartouInfo.vue'
import PartouCheckNew from '@/components/PartouComponents/Icons/PartouCheckNew.vue'
import PartouCheckHourglass from '@/components/PartouComponents/Icons/PartouHourglassNew.vue'
import PartouCheckLock from '@/components/PartouComponents/Icons/PartouLockNew.vue'
import PartouLocationMarker from '@/components/PartouComponents/Icons/PartouLocationMarker.vue'
import PartouThick from '@/components/PartouComponents/Icons/PartouThick.vue'
import PartouGroup from '@/components/PartouComponents/Icons/PartouGroup.vue'
import PartouCalendarEdit from '@/components/PartouComponents/Icons/PartouCalendarEdit.vue'
import PartouArrowCircleLeft from '@/components/PartouComponents/Icons/PartouArrowCircleLeft.vue'
import PartouArrowCircleRight from '@/components/PartouComponents/Icons/PartouArrowCircleRight.vue'
import PartouRadioOn from '@/components/PartouComponents/Icons/PartouRadioOn.vue'
import PartouRadioOff from '@/components/PartouComponents/Icons/PartouRadioOff.vue'
import PartouArrowTop from '@/components/PartouComponents/Icons/PartouArrowTop.vue'
import PartouSubscriptionCheck from '@/components/PartouComponents/Icons/PartouSubscriptionCheck.vue'
import PartouHomeMarker from '@/components/PartouComponents/Icons/PartouHomeMarker.vue'

const icons = {
  partouCheck: {
    component: PartouCheck
  },
  partouArrow: {
    component: PartouArrow
  },
  partouArrowLeft: {
    component: PartouArrowLeft
  },
  partouExpansionArrow: {
    component: PartouExpansionArrow
  },
  partouCircle: {
    component: PartouCircle
  },
  partouGlobe: {
    component: PartouGlobe
  },
  partouGradientArrowLeft: {
    component: PartouGradientArrowLeft
  },
  partouCall: {
    component: PartouCall
  },
  partouChat: {
    component: PartouChat
  },
  partouMail: {
    component: PartouMail
  },
  partouChevronUp: {
    component: PartouChevronUp
  },
  partouChevronDown: {
    component: PartouChevronDown
  },
  partouEdit: {
    component: PartouEdit
  },
  partouCalculator: {
    component: PartouCalculatorNew
  },
  partouWait: {
    component: PartouWait,
    props: {
      name: 'partou-wait',
      color: '#CB5A5C'
    }
  },
  offPartouWait: {
    component: PartouWait,
    props: {
      name: 'partou-wait',
      color: '#C4C4C4'
    }
  },
  partouLock: {
    component: PartouLock
  },
  partouClose: {
    component: PartouClose
  },
  partouSearch: {
    component: PartouSearch
  },
  partouSchool: {
    component: PartouSchool
  },
  partouShieldGradient: {
    component: PartouShield,
    props: {
      name: 'partou-shield',
      color: 'url(#paint0_linear)'
    }
  },
  partouShieldWhite: {
    component: PartouShield,
    props: {
      name: 'partou-shield',
      color: '#FFFFFF'
    }
  },
  partouRefresh: {
    component: PartouRefresh
  },
  partouEditNew: {
    component: PartouEditNew
  },
  partouShield: {
    component: PartouShieldNew
  },
  partouMoney: {
    component: PartouMoneyNew
  },
  partouPrevious: {
    component: PartouPreviousNew
  },
  partouPrevious2: {
    component: PartouPrevious2New
  },
  partouPrevious3: {
    component: PartouPrevious3New
  },
  partouNext: {
    component: PartouNextNew
  },
  partouNext2: {
    component: PartouNext2New
  },
  partouNext3: {
    component: PartouNext3New
  },
  partouDropdown: {
    component: PartouDropdownNew
  },
  partouDropdownReversed: {
    component: PartouDropdownReversedNew
  },
  partouPlay: {
    component: PartouPlayNew
  },
  partouAdd: {
    component: PartouAddNew
  },
  partouDelete: {
    component: PartouDeleteNew
  },
  partouLocationPinNew: {
    component: PartouLocationPinNew
  },
  partouCalendar: {
    component: PartouCalendarNew
  },
  partouLocationSearch: {
    component: PartouLocationSearchNew
  },
  partouLoader: {
    component: PartouLoaderNew
  },
  partouInfo: {
    component: PartouInfo
  },
  partouCheckNew: {
    component: PartouCheckNew
  },
  partouHourglassNew: {
    component: PartouCheckHourglass
  },
  partouLockNew: {
    component: PartouCheckLock
  },
  partouLocationMarker: {
    component: PartouLocationMarker
  },
  partouThick: {
    component: PartouThick
  },
  partouGroup: {
    component: PartouGroup
  },
  partouCalendarEdit: {
    component: PartouCalendarEdit
  },
  partouArrowCircleleft: {
    component: PartouArrowCircleLeft
  },
  partouArrowCircleRight: {
    component: PartouArrowCircleRight
  },
  partouCopy: {
    component: PartouCopy
  },
  partouShareBlack: {
    component: PartouShare,
    props: {
      name: 'partou-share',
      color: '#343432'
    }
  },
  partouShareBordeaux: {
    component: PartouShare,
    props: {
      name: 'partou-share',
      color: '#B61544'
    }
  },
  partouCustomShareIcon: {
    component: PartouCustomShareIcon
  },
  partouLocationPin: {
    component: PartouLocationPin
  },
  partouRadioOn: {
    component: PartouRadioOn
  },
  partouRadioOff: {
    component: PartouRadioOff
  },
  partouArrowTop: {
    component: PartouArrowTop
  },
  partouSubscriptionCheck: {
    component: PartouSubscriptionCheck
  },
  partouHomeMarker: {
    component: PartouHomeMarker
  }
}

export default icons

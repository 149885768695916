import { ServiceProduct, ServiceVarietyName } from '@/models'
import { ProductCategoryViewData } from './ProductCategoryViewData'
import { ProductViewMapper } from './ProductViewMapper'
import Category from '@/models/enums/Category'

export class ProductCatagoryViewMapper {
  constructor (serviceProducts: Partial<ServiceProduct>[]) {
    const uniqueCategories = [...new Set(serviceProducts.map(product => product.product?.category))]
    if (serviceProducts[0].product?.serviceVariety !== ServiceVarietyName.KDV) {
      this.productCategories = uniqueCategories.flatMap(category => {
        const NSOServiceProductsPerCategory = serviceProducts.filter(serviceProduct => serviceProduct.product?.category === category && serviceProduct.product?.serviceVariety === ServiceVarietyName.NSO)
        if (NSOServiceProductsPerCategory.length <= 0) {
          return []
        }
        const VSOServiceProductsPerCategory = serviceProducts.filter(serviceProduct => serviceProduct.product?.category === Category.SchoolWeeks.toString() && serviceProduct.product?.serviceVariety === ServiceVarietyName.VSO)
        const productsForCategory = new ProductViewMapper([...NSOServiceProductsPerCategory, ...VSOServiceProductsPerCategory]).products

        return [new ProductCategoryViewData(category ?? '', productsForCategory)]
      })
    } else {
      this.productCategories = uniqueCategories.map(category => {
        const serviceProductsPerCategory = serviceProducts.filter(serviceProduct => serviceProduct.product?.category === category)
        const productsForCategory = new ProductViewMapper(serviceProductsPerCategory).products

        return new ProductCategoryViewData(category ?? '', productsForCategory)
      })
    }
  }

    productCategories: ProductCategoryViewData[] = []
}

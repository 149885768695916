
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class PartouTextButton extends Vue {
  @Prop({ required: true })
  text! : string

  @Prop({ required: false })
  iconName? : string

  @Prop({ required: false, default: 16.5 })
  iconSize! : number

  @Prop({ required: false, default: 14 })
  fontSize! : number

  @Prop({ required: false, default: false })
  loading!: boolean

  @Prop({ required: false, default: false })
  disabled!: boolean

  @Prop({ required: false, default: '' })
  className! : string

  @Prop({ required: false, default: false })
  iconLeft!: boolean
}

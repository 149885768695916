import DayOfWeek, { parseStringToDayOfWeek } from '@/models/enums/DayOfWeek'

type DaySelectionState = {
  priority?: number;
  day: DayOfWeek;
  isChecked: boolean;
}

export default DaySelectionState

export function getInitialDaySelectionState () : DaySelectionState[] {
  const initialDaySelectionState : DaySelectionState[] = []
  for (const dayOfWeek in DayOfWeek) {
    if (dayOfWeek !== DayOfWeek.Saturday && dayOfWeek !== DayOfWeek.Sunday) {
      const day = parseStringToDayOfWeek(dayOfWeek)
      initialDaySelectionState.push({ day, isChecked: false })
    }
  }

  return initialDaySelectionState
}


import { Component, Emit, Prop, VModel, Vue } from 'vue-property-decorator'
import PartouFloatingCircleButton from '@/components/PartouComponents/Buttons/PartouFloatingCircleButton.vue'
import PartouSocials from '@/components/PartouComponents/PartouSocials.vue'
import PartouNotify from '@/components/PartouComponents/PartouNotify.vue'
import PartouButton from '@/components/PartouComponents/Buttons/PartouButton.vue'
import { ContactInfo } from '@/models'
import { ROUTES } from '@/router/routes'

@Component({ components: { PartouFloatingCircleButton, PartouSocials, PartouNotify, PartouButton } })
export default class PartouErrorMessage extends Vue {
  @VModel({ required: true })
  showErrorMessage! : boolean

  @Prop({ required: true })
  contactInfo!: ContactInfo

  @Emit('closeDialog')
  closeDialog () : void {
    // Emits closedialog
  }

  @Emit('onBackClicked')
  onBackClicked () : void {
    if (this.$route.name !== ROUTES.selectionGuide) {
      this.$router.push({ name: ROUTES.selectionGuide })
    }
    this.closeDialog()
  }
}

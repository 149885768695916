import { StatusCodes } from 'http-status-codes'

export default class HttpResponseError extends Error {
  public statusCode: StatusCodes

  constructor (statusCode: StatusCodes | string, message?: string) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(message)
    this.statusCode = Number(statusCode) as StatusCodes

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, HttpResponseError)
    }
  }
}

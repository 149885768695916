
import { School, Service, ServiceKind, ServiceVarietyName } from '@/models'
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator'
import PartouAutocomplete from '@/components/PartouComponents/Input/PartouAutoComplete/PartouAutoCompleteN.vue'
import PartouLocationSearchNew from '@/components/PartouComponents/Icons/PartouLocationSearch.vue'
import container, { SERVICE_IDENTIFIERS } from '@/init/container'
import IOfferService from '@/services/OfferService/IOfferService'
import { namespace } from 'vuex-class'
import { NAMESPACES, STATE } from '@/store'
import IUserSelectionFormState from '@/store/modules/userSelection/IUserSelectionFormState'
import moment from 'moment'
import { ACTIONS } from '@/store/modules/userSelection'

const userSelectionModule = namespace(NAMESPACES.userSelection)

@Component({
  components: { PartouAutocomplete, PartouLocationSearchNew }
})
export default class LocationSearch extends Vue {
  @Prop({ required: false, default: ServiceKind.SchoolCare })
  serviceKind? : ServiceKind

  @Prop({ required: false })
  school? : School

  @Prop({ required: false, default: false })
  filterForChildBenefitCalculator!: boolean

  @userSelectionModule.Action(ACTIONS.setSelectedPropositionId)
  setSelectedPropositionId!: (selectedPropositionId: string) => Promise<void>

  @userSelectionModule.State(STATE.userSelection.formState)
  userSelectionFormState!: IUserSelectionFormState

  offerService! : IOfferService

  PartouLocationSearch = PartouLocationSearchNew
  localityOptions : string[] = []
  selectedLocality = ''
  serviceOptions : Service[] = []
  selectedService : Partial<Service> | null = null
  ServiceKind = ServiceKind

  get serviceVarityNames () : ServiceVarietyName[] {
    return this.serviceKind === ServiceKind.DayCare ? [ServiceVarietyName.KDV] : [ServiceVarietyName.VSO, ServiceVarietyName.NSO]
  }

  async beforeCreate () : Promise<void> {
    this.offerService = container.get(SERVICE_IDENTIFIERS.IOfferService)
  }

  async mounted () : Promise<void> {
    if (this.userSelectionFormState.selectedPropositionId) {
      this.selectedService = await this.offerService.getServiceByIdAsync({ id: this.userSelectionFormState.selectedPropositionId, dateOfOpeningTime: moment().toDate() })
      if (this.selectedService) {
        this.selectedLocality = this.selectedService.location?.locality?.capitalizeFirstLetters() ?? ''
        await this.onSelectedServiceChangedAsync(this.selectedService)
        await this.onSelectedLocalityChangedAsync()
      }
    }

    if (this.serviceKind === ServiceKind.SchoolCare) {
      await this.fetchServiceOptionsBySchoolIdAsync()
    }
    this.getLocalityOptionsAsync()
  }

  @Watch('school')
  async fetchServiceOptionsBySchoolIdAsync (newValue?: School, oldValue?: School) : Promise<void> {
    if (this.school && this.school.id) {
      this.serviceOptions = await this.offerService.getServicesBySchoolIdAsync({
        schoolId: this.school.id,
        serviceVarietyNames: [ServiceVarietyName.VSO, ServiceVarietyName.NSO],
        filterForChildBenefitCalculator: this.filterForChildBenefitCalculator
      })

      if (this.userSelectionFormState.isUsingChildbenefitCalculator && newValue !== oldValue) {
        await this.onSelectedServiceChangedAsync(null)
      }

      if (this.serviceOptions.findIndex(x => this.selectedService && x.id === this.selectedService.id) === -1) {
        await this.onSelectedServiceChangedAsync(null)
      }
    }
  }

  async onSelectedLocalityChangedAsync () : Promise<void> {
    this.serviceOptions = await this.offerService.getServiceNamesByLocalityAsync({ locality: this.selectedLocality, serviceVarietyNames: this.serviceVarityNames })
    if (this.serviceOptions.findIndex(x => this.selectedService && x.id === this.selectedService.id) === -1) {
      await this.onSelectedServiceChangedAsync(null)
    }
  }

  @Watch('serviceKind')
  async onServiceKindChanged () : Promise<void> {
    if (this.serviceKind === ServiceKind.SchoolCare) {
      this.selectedLocality = this.school?.locality ?? ''
    }

    this.serviceOptions = await this.offerService.getServiceNamesByLocalityAsync({ locality: this.selectedLocality, serviceVarietyNames: this.serviceVarityNames })
    this.getLocalityOptionsAsync()
    if (this.serviceOptions.findIndex(x => this.selectedService && x.id === this.selectedService.id) === -1) {
      await this.onSelectedServiceChangedAsync(null)
    }
  }

  async getLocalityOptionsAsync () : Promise<void> {
    this.localityOptions = await this.offerService.getLocationLocalitiesByServiceVarietyNamesAsync(this.serviceVarityNames)
    this.localityOptions = this.localityOptions.map(x => x.capitalizeFirstLetters())
    this.localityOptions = [...new Set(this.localityOptions)].sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
  }

  @Emit('onSelectedServiceChanged')
  async onSelectedServiceChangedAsync (selectedService : Partial<Service> | null) : Promise<Partial<Service> | null> {
    this.selectedService = selectedService
    if (selectedService && selectedService.id) {
      this.selectedService = await this.offerService.getServiceByIdAsync({ id: selectedService.id ?? '', dateOfOpeningTime: moment().toDate() })
      this.setSelectedPropositionId(selectedService.id)
    }
    return this.selectedService
  }

  isDisabled () : boolean {
    if (this.userSelectionFormState.selectedServiceKind === ServiceKind.SchoolCare) {
      return this.userSelectionFormState.selectedSchoolGroup === undefined || this.serviceOptions.length === 0
    } else {
      return this.serviceOptions.length === 0
    }
  }
}

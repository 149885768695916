
import { Prop, Component, Vue, Emit } from 'vue-property-decorator'
import PartouFloatingShieldButton from '@/components/PartouComponents/Buttons/PartouFloatingShieldButton.vue'
import { Offer } from '@/models'
import { Dictionary } from 'lodash'
import ServicesMap from '@/components/ServicesMap/ServicesMap.vue'
import FakeLink from '@/components/Generic/FakeLink.vue'
import PartouButton from '@/components/PartouComponents/Buttons/PartouButton.vue'

@Component({
  components: { PartouFloatingShieldButton, ServicesMap, FakeLink, PartouButton }
})
export default class LocationContent extends Vue {
  @Prop({ required: true })
  offer! : Offer

  @Prop({ required: true })
  locationsLink! : string

  @Prop({ required: true })
  locationsLinkParams! : Dictionary<string> | undefined

  @Emit('onLocationInNeighbourhoodClicked')
  onLocationInNeighbourhoodClicked () : void {
    // emits onLocationInNeighbourhoodClicked event
  }
}

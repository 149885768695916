import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages, { defaultLanguage } from '@/locale'

Vue.use(VueI18n)

export default new VueI18n({
  locale: defaultLanguage,
  fallbackLocale: defaultLanguage,
  messages
})


import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class PartouFloatingShieldButton extends Vue {
  @Prop({ required: false, default: '' })
  iconName?: string

  @Prop({ required: false, default: '' })
  text? : string

  onButtonClicked (): void {
    this.$emit('click')
  }
}

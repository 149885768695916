import { FetchConfigurationByKeyDocument, FetchConfigurationByKeyQuery, FetchConfigurationByKeyQueryVariables } from '@/models'
import ApolloClient from 'apollo-client'
import { inject, injectable } from 'inversify'
import IConfigurationService from './IConfigurationService'

@injectable()
export default class ConfigurationService implements IConfigurationService {
  private readonly apollo: ApolloClient<unknown>

  constructor (@inject(ApolloClient) apollo: ApolloClient<unknown>) {
    this.apollo = apollo
  }

  async getConfigurationByKeyAsync<T> (variables: FetchConfigurationByKeyQueryVariables): Promise<T> {
    const result = await this.apollo.query<FetchConfigurationByKeyQuery>({
      query: FetchConfigurationByKeyDocument,
      variables
    })
    return result.data.configuration[0]?.value as T
  }
}

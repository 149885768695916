
import { Component, Prop, Vue } from 'vue-property-decorator'
import PartouStepper from '@/components/PartouComponents/PartouStepper.vue'
import EmailVerificationContent from './StepperContent/EmailVerificationContent.vue'
import ContractSignContent from './StepperContent/ContractSignContent.vue'
import MandateSignContent from './StepperContent/MandateSignContent.vue'
import { GetBookingStatusOutput } from '@/models'
@Component({
  components: { PartouStepper, ContractSignContent, MandateSignContent, EmailVerificationContent }
})
export default class WaitingListBookingContent extends Vue {
  @Prop({ required: true })
  currentBookingStatus! : GetBookingStatusOutput

  @Prop({ required: false })
  emailAddress? : string

  onResendEmailClicked () : void {
    this.$emit('onResendEmailClicked')
  }
}


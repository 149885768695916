
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class PartouCheckBox extends Vue {
  @Prop({})
  value! : boolean

  @Prop({ required: false, default: '$vuetify.icons.partouCheck' })
  onIconString? : string

  @Prop({ required: false, default: '' })
  offIconString? : string

  @Prop({ required: false, default: false })
  disabled? : boolean

  @Prop({})
  rules? : []

  @Prop({})
  error? : boolean

  get checkboxValue () : boolean {
    return this.value
  }

  set checkboxValue (value : boolean) {
    this.$emit('input', value)
  }
}

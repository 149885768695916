
import { Component, Vue } from 'vue-property-decorator'
import PartouErrorMessage from '@/components/PartouComponents/PartouErrorMessage.vue'
import { eventBus } from '@/EventBus'
import Debounce from '@/utils/decorators/debounceDecorator'
import { ACTIONS, NAMESPACES, STATE } from '@/store'
import { namespace } from 'vuex-class'

const configurationModule = namespace(NAMESPACES.configuration)

@Component({
  components: { PartouErrorMessage }
})
export default class App extends Vue {
  public VUE_APP_VERSION = process.env.VUE_APP_VERSION

  @configurationModule.State(STATE.configuration.configuration)
  configuration! : Record<string, any> /* Disabled because of any */// eslint-disable-line

  @configurationModule.Action(ACTIONS.configuration.getAllNecessaryConfigurations)
  getAllNecessaryConfigurations!: () => Promise<void>

  showErrorMessage = false

  mounted () : void {
    this.getAllNecessaryConfigurations()
    eventBus.$on('globalError', this.openErrorMessage)
    this.setViewHeight()
  }

  closeErrorMessage () : void {
    this.showErrorMessage = false
  }

  @Debounce(1000)
  openErrorMessage () : void {
    this.showErrorMessage = true
  }

  setViewHeight () : void {
    document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`)
  }
}

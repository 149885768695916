
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { GETTERS, NAMESPACES, STATE } from '@/store'
import IUserSelectionFormState from '@/store/modules/userSelection/IUserSelectionFormState'
import BSOTransitionAgePopup from '@/components/PartouComponents/BSOTransitionAgePopup/BSOTransitionAgePopup.vue'
import { eventBus } from '@/EventBus'
const userSelectionModule = namespace(NAMESPACES.userSelection)

@Component({ components: { BSOTransitionAgePopup } })
export default class DateValidationPopups extends Vue {
  @userSelectionModule.State(STATE.userSelection.formState)
  userSelectionFormState!: IUserSelectionFormState

  @userSelectionModule.Getter(GETTERS.userSelection.getIsBSOTransitionAge)
  getIsBSOTransitionAge!: () => boolean

  showBSOTransitionAgePopup = false

  mounted () {
    eventBus.$on('validateIsBSOTransitionAge', this.validateIsBSOTransitionAge)
  }

  validateIsBSOTransitionAge () : void {
    this.showBSOTransitionAgePopup = this.getIsBSOTransitionAge()
  }
}

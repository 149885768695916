import DayOfWeek from '@/models/enums/DayOfWeek'
import DayCheckboxState from './DayCheckboxState'
import DayCheckboxType from './DayCheckboxType'

export default class DaySelectorCheckboxesFactory {
  static constructCheckboxes () : DayCheckboxState[] {
    return [
      { day: DayOfWeek.Monday, type: DayCheckboxType.Check, isChecked: false, isReadOnly: false, priority: undefined },
      { day: DayOfWeek.Tuesday, type: DayCheckboxType.Check, isChecked: false, isReadOnly: false, priority: undefined },
      { day: DayOfWeek.Wednesday, type: DayCheckboxType.Check, isChecked: false, isReadOnly: false, priority: undefined },
      { day: DayOfWeek.Thursday, type: DayCheckboxType.Check, isChecked: false, isReadOnly: false, priority: undefined },
      { day: DayOfWeek.Friday, type: DayCheckboxType.Check, isChecked: false, isReadOnly: false, priority: undefined }
    ]
  }
}

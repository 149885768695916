
import DayCheckboxState from '@/components/InputFields/DaySelector/DayCheckboxState'
import DaySelector from '@/components/InputFields/DaySelector/DaySelector.vue'
import PartouCheckBox from '@/components/PartouComponents/Input/PartouCheckBox/PartouCheckBox.vue'
import PartouLoader from '@/components/PartouComponents/PartouLoader.vue'
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import PartouNotify from '@/components/PartouComponents/PartouNotify.vue'
import DaySelectorCheckboxesFactory from '@/components/InputFields/DaySelector/DaySelectorCheckboxesFactory'

@Component({
  components: { PartouCheckBox, DaySelector, PartouLoader, PartouNotify }
})
export default class MultiStateDaySelectorComponent extends Vue {
    @Prop({ required: false, default: () => DaySelectorCheckboxesFactory.constructCheckboxes() })
    value! : DayCheckboxState[]

    @Prop({ required: false, default: false })
    isLoading!: boolean

    @Prop({ required: false, default: false })
    isReadOnly! : boolean

    @Prop({ required: false, default: false })
    mustSelectOne!: boolean

    @Prop({ required: false, default: false })
    withPriority! : boolean

    @Prop({ required: false, default: false })
    isWaitingListOnly!: boolean

    @Prop({ required: false, default: true })
    alignCenter! : boolean

    @Prop({ required: false, default: 'Loading Error' })
    notLoadingText!: string

    @Prop({ required: false, default: 0 })
    numberOfDaysSelected!: number

    @Emit('input')
    onInput () : void {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
    }

    @Emit('onDayChange')
    onDayChange () : void {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
    }

    @Emit('onInfoClicked')
    onInfo () : void {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
    }
}

enum WeekInterval {
  All = 'All',
  Odd = 'Odd',
  Even = 'Even'
}
export default WeekInterval

export function ParseStringToWeekInterval (value : string) : WeekInterval {
  switch (value) {
  case 'All':
  case 'all': {
    return WeekInterval.All
  }
  case 'Odd':
  case 'odd': {
    return WeekInterval.Odd
  }
  case 'Even':
  case 'even': {
    return WeekInterval.Even
  }
  }
  throw new Error()
}

export function ParseWeeknumberToWeekInterval (value : number) : WeekInterval {
  switch (value % 2) {
  case 0: {
    return WeekInterval.Even
  }
  case 1: {
    return WeekInterval.Odd
  }
  }
  throw new Error()
}

import LocaleMessages from './LocaleMessages'

const en: LocaleMessages = {
  pageTitles: {
    selectionGuide: '',
    schoolSelectionPage: '',
    locations: '',
    locationDetail: '',
    orderOverview: '',
    orderOverviewSelectProduct: '',
    orderOverviewAcceptingWaitinglist: '',
    orderOverviewSelectProductAcceptingWaitinglist: '',
    orderContactInfo: '',
    orderContactInfoCheck: '',
    bookingStatus: '',
    signContract: '',
    emailConfirm: '',
    waitingList: '',
    sharedPage: '',
    notFound: ''
  },
  pageUrls: {
    selectionGuide: '/',
    selectionGuideWithPostalCode: '/postalCode/:postalCode',
    schoolSelectionPage: '/schools',
    locations: 'search',
    locationDetail: '/:serviceSlug',
    orderOverview: '/:serviceSlug/subscriptions',
    orderContactInfo: '/:serviceSlug/your-data',
    orderContactInfoCheck: '/:serviceSlug/your-overview',
    bookingStatus: '/booking/:bookingHash',
    signContract: '/contract/:bookingHash',
    emailConfirm: '/booking/:bookingHash/confirm/:confirmationHash',
    waitingList: '/waitinglist/:bookingHash',
    waitingListChildren: {
      cancel: 'cancel',
      dayOverview: 'offer',
      dayOverviewAccept: 'offer/accepting',
      dayOverviewDecline: 'offer/decline',
      cancelSuccess: 'success',
      reactionReceived: 'received',
      booked: 'finished',
      offerExpired: 'expired',
      unavailable: 'unavailable',
      extend: 'extend',
      requiresPlanner: 'planner'
    },
    shareWithData: '/share/:hash/:encryptionKey',
    serviceKindDayCareSuffix: 'daycare',
    serviceKindSchoolCareSuffix: 'after-school-care'
  },
  externalWebsiteTranslations: {
    caretypes: {
      BSO: 'after-school-care',
      KDV: 'daycare'
    }
  },
  pageDescriptions: {
    selectionGuide: '',
    locations: ''
  },
  sharingMenu: {
    shareButtonName: '',
    sharingTitle: '',
    sharingWithoutDataBtnText: '',
    sharingWithDataBtnText: ''
  },
  errorPopUp: {
    title: '',
    text: '',
    back: ''
  },
  bsoTransitionPopup: {
    title: '',
    text: '',
    confirm: '',
    decline: '',
    help: ''
  },
  partouSocials: {
    questionsOrHelp: '',
    callButtonName: '',
    chatButtonName: '',
    emailButtonName: '',
    phoneText: '',
    chatText: '',
    mailText: '',
    copied: ''
  },
  form: {
    required: '',
    invalidEmail: '',
    invalidPhoneNumber: '',
    invalidInitials: '',
    invalidName: '',
    invalidBsn: '',
    sameBsn: '',
    sameBsnMultiple: '',
    invalidDate: '',
    dateBeforeMinDate: '',
    dateAfterMaxDate: '',
    minimalAgeRequirement: '',
    invalidPostalCode: '',
    invalidHouseNumber: '',
    invalidHouseNumberAddition: '',
    unknownPostalCodeHouseNumberCombination: '',
    noData: ''
  },
  VSO: '',
  NSO: '',
  KDV: '',
  BSO: '',
  notification: {
    cancel: '',
    close: '',
    ok: ''
  },
  autocomplete: {
    noData: '',
    noSearchResults: ''
  },
  openingTimes: {
    unknown: '',
    closed: '',
    schoolTime: ''
  },
  days: {
    monday: '',
    tuesday: '',
    wednesday: '',
    thursday: '',
    friday: '',
    saturday: '',
    sunday: ''
  },
  serviceVarieties: {
    KDV: '',
    VSO: '',
    NSO: '',
    Daycare: ''
  },
  daySelector: {
    wait: '',
    closed: '',
    placeAvailable: '',
    notAvailable: '',
    startsFrom: '',
    errorMsgMinimumSelected: '',
    chooseYourDaysExplanation: '',
    ariaLabel: {
      monday: '',
      tuesday: '',
      wednesday: '',
      thursday: '',
      friday: '',
      checked: '',
      unchecked: '',
      waitinglist: ''
    }
  },
  childBenefitCalculator: {
    upperTitle: '',
    title: '',
    altTitle: '',
    introText: '',
    introTextDisclaimer: '',
    form: {
      cumulativeIncome: {
        text: '',
        suffix: ''
      },
      workingHours: {
        text: '',
        suffix: ''
      },
      currentAndFutureServicePricings: {
        description: '',
        text: ''
      }
    },
    invoiceAmount: '',
    amountChosenHours: '',
    benefitAmount: '',
    ownContribution: '',
    maximumHours: '',
    declarableHours: '',
    remainingHours: '',
    hourPerMonthShort: '',
    calculatePrice: '',
    calculateNetPrice: '',
    hour: '',
    perHour: '',
    perMonth: '',
    moreInformation: '',
    lessInformation: '',
    disclaimer: '',
    table: {
      heading: ''
    },
    info: {
      general: {
        heading: '',
        text: '',
        disclaimer: {
          heading: '',
          text: ''
        }
      },
      cumulativeIncome: {
        heading: '',
        text: ''
      },
      workingHours: {
        heading: '',
        text: ''
      },
      benefitAmount: {
        heading: '',
        text: ''
      },
      maximumHours: {
        heading: '',
        text: ''
      }
    },
    attention: {
      heading: '',
      moreText: ''
    },
    saveButton: {
      textContinue: '',
      textShowPrices: ''
    },
    inputForm: {
      infoSectionTitle: '',
      careSectionTitle: '',
      schoolSectionTitle: '',
      locationSectionTitle: '',
      locationSectionText: '',
      daySelectionText: '',
      calculateCosts: ''
    },
    noCalculationPossibleHintTitle: '',
    noCalculationPossibleHintText: '',
    noCalculationPossibleSchoolHintText: '',
    editInfo: ''
  },
  serviceOverviewPage: {
    backButtonName: '',
    resultLength: '',
    daySelectorInfo: '',
    showMore: '',
    serviceCard: {
      moreInformation: '',
      moreInformationAriaLabel: '',
      availabilityIndicator: {
        available: '',
        partlyAvailable: '',
        unavailable: ''
      }
    },
    selectionPanel: {
      showPanel: '',
      showPanelAriaLabel: '',
      closePanelAriaLabel: ''
    }
  },
  orderOverviewPage: {
    backButtonName: '',
    selection: {
      supertitle: '',
      title: '',
      expansionTitle: '',
      location: '',
      startDate: '',
      noSelection: '',
      choiceWithStartDates: '',
      expansionChoiceWithStartDates: '',
      subscription: {
        cheapestChoice: '',
        careDuring: '',
        subText: '',
        perHour: '',
        featureDisclaimer: '',
        groupDisclaimer: ''
      },
      customSubscription: {
        title: '',
        callToAction: '',
        formCTA: '',
        buttonText: ''
      }
    },
    productSelection: {
      nsoTimeQuestion: '',
      nso: '',
      vsoTimeQuestion: '',
      vso: '',
      kdvTimeQuestion: '',
      hourlyRateKDV: '',
      hourlyRateNSO: '',
      hourlyRateVSO: '',
      perHour: '',
      cheapestChoice: '',
      careDuring: '',
      subText: '',
      featureDisclaimer: '',
      groupDisclaimer: '',
      AllWeeks: '',
      SchoolWeeks: '',
      from: '',
      textualAdditionNSO: '',
      textualAdditionVSO: ''
    },
    linkToPartouOffers: {
      title: '',
      callToAction: '',
      formCTA: '',
      buttonText: ''
    },
    noDaysOrSchoolSelected: '',
    offerNotAvailable: '',
    priceSummary: {
      headerText: '',
      invoiceAmount: '',
      perMonth: '',
      allowance: '',
      contribution: '',
      indicationPerMonth: '',
      orderButton: '',
      calculateCosts: '',
      calculaterButtonName: ''
    },
    acceptWaitingListActions: {
      orderButton: '',
      declineButton: '',
      declineDialog: {
        heading: '',
        text: '',
        confirmText: ''
      }
    },
    declineReasonPicker: {
      placeholder: '',
      commentPlaceholder: ''
    }
  },
  orderContactInfoFormPage: {
    supertitle: '',
    title: '',
    subtitle: '',
    backButtonName: '',
    careTaker: '',
    child: '',
    primaryCareTaker: '',
    secondaryCareTaker: '',
    optional: '',
    addCareTaker: '',
    removeCareTaker: '',
    thisCareTaker: '',
    defaultChildName: '',
    form: {
      personalInfo: '',
      editPersonalInfo: '',
      initials: '',
      firstName: '',
      middleName: '',
      lastName: '',
      gender: '',
      genders: {
        female: '',
        male: '',
        other: ''
      },
      bsn: '',
      bsnHyphenated: '',
      contactInfo: '',
      emailAddress: '',
      phoneNumber: '',
      addressInfo: '',
      postalCode: '',
      houseNumber: '',
      houseNumberAddition: '',
      street: '',
      locality: '',
      childInfoSuper: '',
      childInfo: '',
      editChildInfo: '',
      dateOfBirth: '',
      informationIsCorrect: '',
      agreedToPrivacyPolicy: '',
      agreedToTermsText: '',
      agreedToExpansionText: ''
    },
    notification: {
      error: '',
      errorSeatAlreadyTaken: ''
    },
    perWeek: '',
    perMonth: '',
    monthlyAmount: '',
    days: '',
    orderButton: ''
  },
  orderContactInfoCheckPage: {
    supertitle: '',
    title: '',
    careTakerOne: '',
    careTakerTwo: '',
    childInfo: '',
    orderButton: '',
    bookLoadingText: ''
  },
  selectionGuidePage: {
    welcomeTitle: '',
    welcomeText: '',
    locationsButton: '',
    selectionGuideForm: {
      postalCodeInfo: '',
      expectedDateOfBirthInfo: '',
      startDateOfDayCareInfo: '',
      daySelectorInfo: '',
      postalcodeTooltip: '',
      daySelector: {
        errorMsgMinimumSelected: ''
      },
      postalCodeInput: {
        postalCodePlaceholder: '',
        error: {
          msgPostalCodeDoesntExist: '',
          msgPostalCodeInvalidFormat: '',
          APIError: '',
          postalCodeisPOBox: ''
        }
      },
      expectedDateOfBirth: {
        errorMsgStartDateIsBeforeDateOfBirth: ''
      },
      startDateOfDayCare: {
        errorMsgStartDateIsBeforeDateOfBirth: '',
        errorMsgStartDateMustBeInFuture: ''
      },
      ageErrorMessage: ''
    }
  },
  availabilitySelector: {
    incorrectAgeTitle: '',
    incorrectAgeActiontext: '',
    incorrectAgeDescriptionFooter: '',
    incorrectSchoolTitle: '',
    incorrectSchoolActiontext: '',
    incorrectSchoolDescriptionFooter: '',
    dateStepTitle: '',
    schoolStepTitle: '',
    schoolStepDescription: '',
    restartSelector: '',
    showAvailability: '',
    checkAvailability: '',
    book: '',
    next: ''
  },
  serviceDetailPage: {
    title: '',
    backButtonName: '',
    characteristics: '',
    openingTimes: '',
    contactTitle: '',
    contactDescription: '',
    chooseDays: '',
    showOfferButton: '',
    locationinfo: '',
    locationsInNeighbourHood: '',
    roomOnLocation: '',
    roomOnLocationDescription: '',
    moreInfo: '',
    bookExternal: '',
    bookTour: '',
    multiStateDaySelector: {
      chooseDays: '',
      title: '',
      beforeSchoolCare: '',
      afterSchoolCare: '',
      dayCare: '',
      loading: '',
      legend: {
        description: '',
        locationClosed: '',
        queueAvailable: '',
        queueChosen: '',
        seatAvailable: '',
        seatChosen: '',
        placeAvailable: '',
        placeNotAvailable: '',
        dayNotAvailable: ''
      },
      popup: {
        nsoHeader: '',
        nsoMessage: '',
        kdvHeader: '',
        kdvMessage: '',
        vsoHeader: '',
        vsoMessage: ''
      }
    },
    serviceContent: {
      title: ''
    }
  },
  schoolSelectionPage: {
    title: '',
    description: '',
    locationSearchLabel: '',
    locationSearchPlaceholder: '',
    locationSearchErrorText: '',
    schoolSearchLabel: '',
    schoolSearchPlaceholder: '',
    schoolSearchErrorText: '',
    schoolGroupLabel: '',
    schoolGroupPlaceholder: '',
    VSONSOCheckLabel: '',
    VSOcheckboxText: '',
    NSOcheckboxText: '',
    VSOHintText: '',
    NSOHintText: '',
    schoolInformationHint: '',
    schoolNotAvailableHintTitle: '',
    schoolNotAvailableHintText: '',
    nextStepButton: '',
    locatlityAutocomplete: {
      nodata: ''
    },
    schoolAutocomplete: {
      nodata: ''
    },
    schoolGroupAutocomplete: {
      nodata: ''
    }
  },
  bookingStatusPage: {
    title: '',
    stepTitle: '',
    descriptionSingle: '',
    descriptionMultiple: '',
    stepper: {
      email: '',
      contract: '',
      SEPA: ''
    },
    emailVerifyContent: {
      title: '',
      description: '',
      noEmail: '',
      retry: ''
    },
    signContractContent: {
      title: '',
      description: '',
      startSign: ''
    },
    contractCannotYetBeSignedContent: {
      title: '',
      description: ''
    },
    signMandateContent: {
      title: '',
      description: '',
      startSign: ''
    },
    completed: {
      reservationBooking: {
        title: '',
        description: '',
        nextSteps: {
          title: '',
          step1: '',
          step2: '',
          step3: '',
          description: ''
        },
        backtoHomePage: ''
      },
      waitingListBooking: {
        title: '',
        description: '',
        welcome: '',
        backtoHomePage: ''
      }
    },
    error: {
      cancelled: {
        title: '',
        description: '',
        button: ''
      },
      emailConfirmUrlNotFound: {
        title: '',
        description: '',
        button: ''
      },
      contractDeclined: {
        title: '',
        description: '',
        button: ''
      }
    }
  },
  waitingListFlow: {
    general: {
      questionsOrHelp: ''
    },
    cancelWaitingList: {
      title: '',
      buttonYes: '',
      buttonNo: ''
    },
    bookWaitingList: {
      title: '',
      description: '',
      confirmSelectedDays: '',
      declineSelectedDays: '',
      bookLoadingText: '',
      location: '',
      startDate: '',
      popup: {
        title: '',
        description: '',
        confirm: '',
        decline: ''
      }
    },
    status: {
      titleCancelSuccess: '',
      descriptionCancelSuccess: '',
      titleKeepWaitingList: '',
      titleBookWaitingListSuccess: '',
      titleBookingRequiresPlanner: ''
    },
    error: {
      expired: {
        title: '',
        body: ''
      },
      unavailable: {
        title: '',
        body: ''
      },
      stillSearching: '',
      buttonSeeAvailableLocations: ''
    },
    dialog: {
      partlyDecline: {
        title: '',
        possibleDays: '',
        stayOnWaitingList: '',
        confirmStayOnWaitingList: '',
        reasonNotTakingDays: '',
        changeWaitingList: '',
        maximumCharacters: ''
      }
    }
  },
  sharedPage: {
    expired: {
      title: '',
      body: '',
      buttonRestart: '',
      contact: ''
    }
  },
  notFound: {
    title1: '',
    title2: '',
    message: '',
    buttonText: '',
    contact: ''
  },
  preorder: {
    popup: {
      title: '',
      description: '',
      buttonText: ''
    }
  },
  feature: {
    signContract: {
      sign: {
        title: '',
        subtitle: '',
        content: '',
        button: ''
      },
      signed: {
        title: ''
      },
      declined: {
        title: '',
        subtitle: ''
      },
      expired: {
        title: '',
        subtitle: ''
      },
      notFound: {
        title: '',
        subtitle: ''
      },
      careInfo: {
        title: '',
        button: ''
      }
    },
    contactInfoCheck: {
      confirmButton: '',
      newDays: {
        title: '',
        startsOn: '',
        kdv: '',
        nso: '',
        vso: ''
      }
    }
  }
}

export default en

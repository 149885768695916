
import { Component, Emit, Vue, Watch } from 'vue-property-decorator'
import PartouAutocomplete from '@/components/PartouComponents/Input/PartouAutoComplete/PartouAutoCompleteN.vue'
import PartouLocationSearchNew from '@/components/PartouComponents/Icons/PartouLocationSearch.vue'
import container, { SERVICE_IDENTIFIERS } from '@/init/container'
import { namespace } from 'vuex-class'
import { NAMESPACES, ACTIONS, STATE, GETTERS } from '@/store'
import SchoolServiceService from '@/services/SchoolServiceService/SchoolServiceService'
import IUserSelectionFormState from '@/store/modules/userSelection/IUserSelectionFormState'
import { School } from '@/models'
import { SchoolValidationResponse } from '@/store/modules/userSelection/ValidatorEnums/SchoolValidationResponse'

const userSelectionModule = namespace(NAMESPACES.userSelection)

@Component({
  components: { PartouAutocomplete, PartouLocationSearchNew }
})
export default class SchoolLocalitySelector extends Vue {
  @userSelectionModule.Action(ACTIONS.userSelection.setSelectedSchoolAsync)
  setSelectedSchoolAsync!: (selectedSchool: School | undefined) => Promise<void>

  @userSelectionModule.State(STATE.userSelection.formState)
  userSelectionFormState!: IUserSelectionFormState

  @userSelectionModule.Getter(GETTERS.userSelection.getIsValidSchoolLocality)
  getIsValidSchoolLocality!: () => boolean

  @userSelectionModule.Getter(GETTERS.userSelection.getIsValidSchool)
  getIsValidSchool!: () => SchoolValidationResponse

  schoolServiceService! : SchoolServiceService
  schoolOptions : School[] = []
  selectedSchool : School | null = null
  schoolInputDisabled = true
  intialLoad = true

  beforeCreate () : void {
    this.schoolServiceService = container.get(SERVICE_IDENTIFIERS.ISchoolServiceService)
  }

  mounted () : void {
    this.fetchSchoolOptionsAsync()
    this.selectedSchool = this.userSelectionFormState.selectedSchool ?? null
  }

  @Emit('onInvalidSchoolSelected')
  onInvalidSchoolSelected () : void {
    // Emits onInvalidSchoolSelected
  }

  @Emit('onInvalidSchoolSelectedBasedOnCurrentLocation')
  onInvalidSchoolSelectedBasedOnCurrentLocation () : void {
    // Emits onInvalidSchoolSelected
  }

  @Emit('onInvalidSchoolSelectedUsingChildBenefitCalculator')
  onInvalidSchoolSelectedUsingChildBenefitCalculator () : void {
    // Emits onInvalidSchoolSelectedUsingChildBenefitCalculator
  }

  @Watch('userSelectionFormState.selectedSchool')
  onSelectedSchoolStateChanged () : void {
    this.selectedSchool = this.userSelectionFormState.selectedSchool ?? null
  }

  @Watch('userSelectionFormState.selectedSchoolLocality')
  onSchoolLocalityChanged () : void {
    this.setSelectedSchoolAsync(undefined)
    this.fetchSchoolOptionsAsync()
  }

  async fetchSchoolOptionsAsync () : Promise<void> {
    if (this.getIsValidSchoolLocality()) {
      this.schoolInputDisabled = false
      this.schoolOptions = await this.schoolServiceService.getSchoolByLocalityAndName(
        { locationInput: this.userSelectionFormState.selectedSchoolLocality ?? '', nameInput: '' },
        this.userSelectionFormState.isUsingChildbenefitCalculator ?? false,
        this.userSelectionFormState.selectedService?.id ?? ''
      )
      this.schoolOptions.forEach(x => x.name.capitalizeFirstLetters())
      this.schoolOptions.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
    } else {
      this.schoolInputDisabled = true
    }
  }

  async onSelectedSchoolChanged () : Promise<void> {
    if (this.selectedSchool) {
      const selectedSchoolReferences = await this.schoolServiceService.getSchoolAndReferencesById(this.selectedSchool.id)
      this.setSelectedSchoolAsync(selectedSchoolReferences)
    }

    const isValid = this.getIsValidSchool()
    if (isValid !== SchoolValidationResponse.Success) {
      if (isValid === SchoolValidationResponse.NoRelationToSelectedLocation) {
        this.onInvalidSchoolSelectedBasedOnCurrentLocation()
        return
      }

      if (this.userSelectionFormState.isUsingChildbenefitCalculator) {
        this.onInvalidSchoolSelectedUsingChildBenefitCalculator()
        return
      }

      this.onInvalidSchoolSelected()
    }
  }
}

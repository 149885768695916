
import { ACTIONS, NAMESPACES, STATE } from '@/store'
import { Component, Vue, VModel } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import PartouNotify from '@/components/PartouComponents/PartouNotify.vue'
import IUserSelectionFormState from '@/store/modules/userSelection/IUserSelectionFormState'
import PartouMultilineButton from '@/components/PartouComponents/Buttons/PartouMultilineButton.vue'
import PartouButton from '@/components/PartouComponents/Buttons/PartouButton.vue'
import PartouSocials from '@/components/PartouComponents/PartouSocials.vue'
import { eventBus } from '@/EventBus'

import moment from 'moment'
import { Proposition, ServiceKind, ServiceVarietyName } from '@/models'
import DaySelectionState from '@/models/types/DaySelectionState'
import { ROUTES } from '@/router/routes'

const configurationModule = namespace(NAMESPACES.configuration)
const userSelectionModule = namespace(NAMESPACES.userSelection)

@Component({ components: { PartouNotify, PartouMultilineButton, PartouButton, PartouSocials } })
export default class BSOTransitionAgePopup extends Vue {
  @VModel({ required: true })
  showDialog! : boolean

  @userSelectionModule.State(STATE.userSelection.formState)
  userSelectionFormState!: IUserSelectionFormState

  @configurationModule.State(STATE.configuration.configuration)
  configuration! : Record<string, any>  /* Disabled because of any */// eslint-disable-line

  @userSelectionModule.Action(ACTIONS.userSelection.setSelectedServiceVarietyDaysAsync)
  setSelectedServiceVarietyDaysAsync!: (daysPerServiceVariety: Record<ServiceVarietyName, DaySelectionState[]>, proposition?: Proposition) => Promise<void>

  @userSelectionModule.Action(ACTIONS.userSelection.setStartDate)
  setStartDate!: (date : Date) => Promise<void>

  get childsFourthBirthday () : Date {
    return moment(this.userSelectionFormState.dateOfBirth).add(4, 'years').toDate()
  }

  handleCloseBsoTransitionPopup () : void {
    this.showDialog = false
  }

  handleBsoTransitionAccepted () : void {
    const dayCheckboxStates = this.userSelectionFormState.daysPerServiceVariety
    const daySelectionStates : Record<ServiceVarietyName, DaySelectionState[]> = {
      KDV: dayCheckboxStates.KDV?.map(x => ({ day: x.day, isChecked: x.isChecked, priority: x.priority })) ?? [],
      NSO: dayCheckboxStates.KDV?.map(x => ({ day: x.day, isChecked: x.isChecked, priority: x.priority })) ?? [],
      VSO: dayCheckboxStates.VSO?.map(x => ({ day: x.day, isChecked: x.isChecked, priority: x.priority })) ?? []
    }

    this.setSelectedServiceVarietyDaysAsync(daySelectionStates)
    this.setStartDate(moment(this.childsFourthBirthday, 'DD-MM-YYYY').toDate())
    if (this.$route.name === ROUTES.locations || this.$route.name === ROUTES.locationDetail || this.$route.name === ROUTES.locationsWithPostalCode) {
      this.$router.replace({ name: ROUTES.locations, params: { serviceKind: 'bso' } })
      eventBus.$emit('fetchPropositions', ServiceKind.SchoolCare)
    }
    this.handleCloseBsoTransitionPopup()
  }
}

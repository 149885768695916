
import { Component, Emit, Vue, Watch } from 'vue-property-decorator'
import PartouAutocomplete from '@/components/PartouComponents/Input/PartouAutoComplete/PartouAutoCompleteN.vue'
import PartouLocationSearchNew from '@/components/PartouComponents/Icons/PartouLocationSearch.vue'
import container, { SERVICE_IDENTIFIERS } from '@/init/container'
import { namespace } from 'vuex-class'
import { NAMESPACES, ACTIONS, STATE, GETTERS } from '@/store'
import SchoolServiceService from '@/services/SchoolServiceService/SchoolServiceService'
import IUserSelectionFormState from '@/store/modules/userSelection/IUserSelectionFormState'
import { SchoolGroup } from '@/models'
import { SchoolValidationResponse } from '@/store/modules/userSelection/ValidatorEnums/SchoolValidationResponse'
import { sendSchoolSearchAnalyticsEvent } from '@/plugins/googleAnalytics/gtagFunctions'

const userSelectionModule = namespace(NAMESPACES.userSelection)

@Component({
  components: { PartouAutocomplete, PartouLocationSearchNew }
})
export default class SchoolGroupSelector extends Vue {
  @userSelectionModule.Action(ACTIONS.userSelection.setSelectedSchoolGroupAsync)
  setSelectedSchoolGroupAsync!: (selectedSchoolGroup: SchoolGroup | undefined) => Promise<void>

  @userSelectionModule.State(STATE.userSelection.formState)
  userSelectionFormState!: IUserSelectionFormState

  @userSelectionModule.Getter(GETTERS.userSelection.getIsValidSchool)
  getIsValidSchool!: () => SchoolValidationResponse

  @userSelectionModule.Getter(GETTERS.userSelection.getIsValidSchoolGroup)
  getIsValidSchoolGroup!: () => boolean

  @userSelectionModule.Getter(GETTERS.userSelection.getFirstFullMonthAfterMinStartDate)
  getFirstFullMonthAfterMinStartDate!: () => Date

  schoolServiceService! : SchoolServiceService
  schoolGroupsOptions : SchoolGroup[] = []
  selectedSchoolGroup : SchoolGroup | null = null
  schoolGroupInputVisible = false

  @Emit('onInvalidSchoolGroupSelected')
  onInvalidSchoolGroupSelected () : void {
    // Emits onInvalidSchoolGroupSelected
  }

  beforeCreate () : void {
    this.schoolServiceService = container.get(SERVICE_IDENTIFIERS.ISchoolServiceService)
  }

  async mounted () : Promise<void> {
    await this.fetchSchoolOptionsAsync()
    this.selectedSchoolGroup = this.userSelectionFormState.selectedSchoolGroup ?? null
  }

  @Watch('userSelectionFormState.selectedSchoolGroup')
  onSelectedSchoolGroupStateChanged () : void {
    this.selectedSchoolGroup = this.userSelectionFormState.selectedSchoolGroup ?? null
    sendSchoolSearchAnalyticsEvent()
  }

  @Watch('userSelectionFormState.selectedSchool.name')
  onSchoolChanged () : void {
    this.setSelectedSchoolGroupAsync(undefined)
    this.fetchSchoolOptionsAsync()
  }

  async fetchSchoolOptionsAsync () : Promise<void> {
    if (this.getIsValidSchool() === SchoolValidationResponse.Success && this.userSelectionFormState.selectedSchool) {
      this.schoolGroupInputVisible = true
      this.schoolGroupsOptions = await this.schoolServiceService.getSchoolGroupsBySchoolIdAsync({
        schoolId: this.userSelectionFormState.selectedSchool.id,
        dateOfOpeningTime: this.userSelectionFormState.isUsingChildbenefitCalculator ? this.getFirstFullMonthAfterMinStartDate() : new Date()
      })
      this.schoolGroupsOptions.forEach(x => x.name.capitalizeFirstLetters())
      this.schoolGroupsOptions.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
    } else {
      this.schoolGroupInputVisible = false
    }
  }

  async onSelectedSchoolGroupchangedAsync () : Promise<void> {
    if (this.selectedSchoolGroup) {
      await this.setSelectedSchoolGroupAsync(this.selectedSchoolGroup)
    }

    if (!this.getIsValidSchoolGroup()) {
      this.onInvalidSchoolGroupSelected()
    }
  }
}

const gtag = {
  events: {
    selectionFlow: {
      searchLocations: 'location_search',
      schoolSearchBso: 'school_search',
      useChildBenefitCalculator: 'use_rekentool',
      chooseLocation: 'choose_location',
      schoolSearch: 'school_search', // This event is implemented when a location is selected. This was a requirement in KF-3874
      locationPageLanding: 'locationPageLanding', // This event is implemented when landed on a location page. This was a requirement in KF-5457
      reserveRegistration: 'inschrijving_start',
      bookTour: 'book_tour',
      moreInformation: 'locationMoreInformation',
      discoverOtherLocations: 'discover_location_pages',
      subscriptionChosen: 'registration',
      externalBooking: 'inschrijvingStartDomainSwitch',
      personalData: 'registeration',
      confirmRegistration: 'registeration',
      isPreorderSelected: 'push_message_maximum_months',
      waitingListSelected: 'waiting_list_push',
      contactClicked: 'contact_icon',
      call: 'phone',
      chat: 'mijnpartou',
      mail: 'mail',
      mdm: 'mdm'
    },
    orderFlow: {
      booking: {
        success: 'orderflow_booking_success',
        failed: 'orderflow_booking_failed',
        notAvailable: 'orderflow_booking_not_available'
      }
    },
    bookingFlow: {
      status: {
        emailVerified: 'bookingflow_status_email_verified',
        contractSigned: 'bookingflow_status_contract_signed',
        mandateSigned: 'bookingflow_status_mandate_signed',
        bookingFinished: 'bookingflow_status_booking_finished'
      }
    },
    calculation: {
      calculation: 'calculation',
      startCalculation: 'start_calculation',
      progressCalculation: 'progress_calculation',
      completedCalculation: 'completed_calculation'
    },
    contactChosen: 'contact_chosen',
    sharedLink: 'shared_link'
  },

  event_category: {
    searchLocations: 'partou_location',
    schoolSearchBso: 'school_search_bso',
    chooseLocation: 'choose_school',
    pageLanding: 'pageLanding',
    useChildBenefitCalculator: 'use_rekentool',
    reserveRegistration: 'inschrijving',
    bookTour: 'book_tour',
    moreInformation: 'locationInfo',
    discoverOtherLocations: 'discover_location_pages',
    subscriptionChosen: 'your_childcare_package',
    customSubscriptionChosen: 'ask_something_else',
    personalData: 'registration_personal_data',
    confirmRegistration: 'confirm_registration',
    isPreorderSelected: 'push_message_maximum_months',
    waitingListSelected: 'waiting_list_push',
    progressCalculation: 'progressCalculation',
    completedCalculation: 'completed_calculation',
    externalBooking: 'inschrijvingStartDomainSwitch',
    registrationStart: 'registrationStart',
    contact: 'contact'
  },

  event_action: {
    searchLocations: 'find_a_partou',
    schoolSearchBso: 'search_school_bso',
    chooseLocation: 'choose_school',
    landingOnLocationPage: 'landingOnLocationPage',
    useChildBenefitCalculator: 'use_rekentool',
    reserveRegistration: 'inschrijving_start',
    bookTour: 'book_tour',
    moreInformation: 'clickMoreAboutLocation',
    discoverOtherLocations: 'discover_location_pages',
    subscriptionChosen: 'your_childcare_package',
    personalData: 'registration_personal_data',
    confirmRegistration: 'confirm_registration',
    isPreorderSelected: 'push_message_maximum_months',
    waitingListSelected: 'Ja graag',
    contactClicked: 'click_contact_icon',
    chooseDateAndTime: 'choose_date_and_time'
  },

  event_params: {
    childCareType: {
      KDV: 'KDV',
      BSO: 'BSO'
    },
    registrationType: {
      empty: 'Empty',
      requestTour: 'rondleiding aanvragen',
      registerChild: 'kind inschrijven'
    },
    registerType: {
      waitingList: 'Wachtlijst',
      directRegistration: 'Direct Inschrijven'
    },
    unknownMdmIdValue: 'Onbekend',
    moreInformation: 'moreInformation',
    booking: {
      notAvailable: {
        noSchoolOrSchoolGroup: 'No schoolgroup selected',
        noBirthOrStartDate: 'No birth or startDate selected',
        noSubscriptionsOrPricings: 'No subscriptions or pricings available'
      }
    },
    sharedLink: {
      result: {
        send: 'send',
        copied: 'copied to clipboard'
      }
    }
  }
}
export default gtag

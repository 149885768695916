import { inject, injectable } from 'inversify'
import ApolloClient, { ApolloError } from 'apollo-client'
import { GetContractStatusDocument, GetContractStatusOutput, GetContractStatusQueryVariables, Maybe, QueryRoot } from '@/models'
import IContractService from './IContractService'
import RetryUtils from '@/utils/retryUtils'
import IOperationContext from '../HasuraService/OperationContext'
import { StatusCodes } from 'http-status-codes'
import HttpResponseError from '@/Errors/HttpResponseError'

@injectable()
export default class ContractService implements IContractService {
  private readonly apollo: ApolloClient<unknown>
  constructor (@inject(ApolloClient) apollo: ApolloClient<unknown>) {
    this.apollo = apollo
  }

  public async getContractStatusAsync (isSigned: boolean, variables : GetContractStatusQueryVariables) : Promise<Maybe<GetContractStatusOutput>> {
    let result: Maybe<GetContractStatusOutput> = null
    const amountOfRetries = 5
    const timeout = 10
    const context: IOperationContext = {
      expectedErrorCodes: [StatusCodes.NOT_FOUND]
    }

    await RetryUtils.callAsyncWithRetryIfConditionNotMet(async () => {
      let shouldRetry = false
      try {
        const queryResult = await this.apollo.query<QueryRoot>({
          query: GetContractStatusDocument,
          variables,
          fetchPolicy: 'no-cache',
          context
        })
        result = queryResult.data.getContractStatus

        if (result && isSigned && !result.isSigned) {
          shouldRetry = true
        }
      } catch (e) {
        if (e instanceof ApolloError && e.networkError instanceof HttpResponseError) {
          switch (e.networkError.statusCode) {
          case StatusCodes.NOT_FOUND:
            shouldRetry = false
            break
          default:
            throw e
          }
        }
      }

      return shouldRetry
    }, amountOfRetries, timeout)

    return result
  }
}


import { Component, Prop, Vue } from 'vue-property-decorator'
import PartouFloatingShieldButton from '@/components/PartouComponents/Buttons/PartouFloatingShieldButton.vue'
import PartouCard from '@/components/PartouComponents/PartouCard.vue'
import { ContactInfo } from '@/models'
import Debounce from '@/utils/decorators/debounceDecorator'
import { ContactType, sendContactClickedEvent } from '@/plugins/googleAnalytics/gtagFunctions'

@Component({ components: { PartouFloatingShieldButton, PartouCard } })
export default class PartouSocials extends Vue {
  @Prop({ required: true })
  contactInfo!: ContactInfo

  showContactHint = false
  contactHintTitle = ''
  contactHintText = ''
  showCopiedAnimation = false

  @Debounce(50)
  onCallClicked () : void {
    this.contactHintTitle = this.contactInfo.phone
    this.contactHintText = this.$t('partouSocials.phoneText').toString()
    this.showContactHint = true
    sendContactClickedEvent(ContactType.Call)
    window.open(`tel:${this.contactInfo.phone}`, '_self')
  }

  @Debounce(50)
  onChatClicked () : void {
    this.contactHintTitle = this.contactInfo.chat
    this.contactHintText = this.$t('partouSocials.chatText').toString()
    this.showContactHint = true
    sendContactClickedEvent(ContactType.Chat)
    window.open(`${this.contactInfo.chat}`)
  }

  @Debounce(50)
  onMailClicked () : void {
    this.contactHintTitle = this.contactInfo.email
    this.contactHintText = this.$t('partouSocials.mailText').toString()
    this.showContactHint = true
    sendContactClickedEvent(ContactType.Mail)
    window.open(`mailto:${this.contactInfo.email}`, '_self')
  }

  onCloseContactHint () : void {
    this.showContactHint = false
  }

  onCopyHintTitleClicked () : void {
    navigator.clipboard.writeText(this.contactHintTitle)
    this.showCopiedAnimation = true
    this.resetHiddenAnimation()
  }

  @Debounce(1000)
  resetHiddenAnimation () : void {
    this.showCopiedAnimation = false
  }
}

import * as userSelection from './modules/userSelection'
import * as proposition from './modules/proposition'
import * as contactDetails from './modules/contactDetails'
import * as childBenefitCalculator from './modules/childBenefitCalculator'
import * as defaultFeature from './modules/features'
import * as configuration from './modules/configuration'
import { Module } from 'vuex'
import IState from './IState'
import IPropositionState from './modules/proposition/IPropositionState'
import IUserSelectionState from './modules/userSelection/IUserSelectionState'
import IContactDetailsState from './modules/contactDetails/IContactDetailsState'
import ICalculatorState from './modules/childBenefitCalculator/ICalculatorState'
import IFeatureState from './modules/features/IFeatureState'
import IConfigurationState from './modules/configuration/IConfigurationState'

export enum moduleNames {
  userSelection = 'userSelection',
  proposition = 'proposition',
  contactDetails = 'contactDetails',
  childBenefitCalculator = 'childBenefitCalculator',
  defaultFeature = 'defaultFeature',
  configuration ='configuration'
}

type modules = {
  userSelection: Module<IUserSelectionState, IState>,
  proposition: Module<IPropositionState, IState>,
  contactDetails: Module<IContactDetailsState, IState>
  childBenefitCalculator: Module<ICalculatorState, IState>,
  defaultFeature: Module<IFeatureState, IState>,
  configuration: Module<IConfigurationState, IState>
}

export function getModules () : modules {
  return {
    userSelection: userSelection.module(),
    proposition: proposition.module(),
    contactDetails: contactDetails.module(),
    childBenefitCalculator: childBenefitCalculator.module(),
    defaultFeature: defaultFeature.module(),
    configuration: configuration.module()
  }
}

export function getSingleModule (type : moduleNames) : Module<any, IState> { // eslint-disable-line @typescript-eslint/no-explicit-any
  switch (type) {
  case moduleNames.userSelection: { return userSelection.module() }
  case moduleNames.proposition: { return proposition.module() }
  case moduleNames.contactDetails: { return contactDetails.module() }
  case moduleNames.childBenefitCalculator: { return childBenefitCalculator.module() }
  case moduleNames.defaultFeature: { return defaultFeature.module() }
  case moduleNames.configuration: { return configuration.module() }
  }
}

export const NAMESPACES = {
  userSelection: userSelection.NAMESPACE,
  proposition: proposition.NAMESPACE,
  contactDetails: contactDetails.NAMESPACE,
  childBenefitCalculator: childBenefitCalculator.NAMESPACE,
  defaultFeature: defaultFeature.NAMESPACE,
  configuration: configuration.NAMESPACE
}

export const ACTIONS = {
  userSelection: userSelection.ACTIONS,
  proposition: proposition.ACTIONS,
  contactDetails: contactDetails.ACTIONS,
  childBenefitCalculator: childBenefitCalculator.ACTIONS,
  defaultFeature: defaultFeature.ACTIONS,
  configuration: configuration.ACTIONS
}

export const MUTATIONS = {
  userSelection: userSelection.MUTATIONS,
  proposition: proposition.MUTATIONS,
  contactDetails: contactDetails.MUTATIONS,
  childBenefitCalculator: childBenefitCalculator.MUTATIONS,
  defaultFeature: defaultFeature.MUTATIONS,
  configuration: configuration.MUTATIONS
}

export const GETTERS = {
  proposition: proposition.GETTERS,
  userSelection: userSelection.GETTERS,
  childBenefitCalculator: childBenefitCalculator.GETTERS,
  contactDetails: contactDetails.GETTERS
}

export const STATE = {
  userSelection: userSelection.STATE,
  proposition: proposition.STATE,
  contactDetails: contactDetails.STATE,
  childBenefitCalculator: childBenefitCalculator.STATE,
  defaultFeature: defaultFeature.STATE,
  configuration: configuration.STATE
}


import { Component, Prop, Vue } from 'vue-property-decorator'
import PartouButton from '@/components/PartouComponents/Buttons/PartouButton.vue'
import { ROUTES } from '@/router/routes'
import WaitingListHeader from '@/pages/WaitingListFlow/WaitingListComponents/WaitingListHeader.vue'
import WaitingListFooter from '@/pages/WaitingListFlow/WaitingListComponents/WaitingListFooter.vue'
import IWaitingListService from '@/services/WaitingListService/IWaitingListService'
import container, { SERVICE_IDENTIFIERS } from '@/init/container'
import UnprocessableWaitingListError from '@/services/WaitingListService/UnprocessableWaitingListError'

@Component({
  components: { PartouButton, WaitingListFooter, WaitingListHeader }
})
export default class WaitingListCancellationContent extends Vue {
  @Prop({ required: true })
  bookingHash!: string

  waitingListService!: IWaitingListService

  loading = false

  created () : void {
    this.waitingListService = container.get<IWaitingListService>(SERVICE_IDENTIFIERS.IWaitingListService)
  }

  async onYesClicked () : Promise<void> {
    this.loading = true

    await this.waitingListService
      .cancelWaitingList(this.bookingHash)
      .then(() => this.$router.replace({ name: ROUTES.waitingListCancelSuccess }))
      .catch(e => {
        if (e instanceof UnprocessableWaitingListError) {
          this.$router.replace({ name: ROUTES.waitingListOfferExpired })
          return
        }
        console.error(e)
      })

    this.loading = false
  }

  onNoClicked () : void {
    this.$router.replace({ name: ROUTES.waitingListExtend })
  }
}

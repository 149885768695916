import Vue from 'vue'
import { NavigationGuardNext, Route } from 'vue-router'
import { ROUTES } from './routes'
import container, { SERVICE_IDENTIFIERS } from '@/init/container'
import IOfferService from '@/services/OfferService/IOfferService'
import { ServiceKind } from '@/models'
import i18n from '@/plugins/i18n'

export async function LocationDetailMdmidGuard (to: Route, from: Route, next: NavigationGuardNext<Vue>) {
  const serviceKind = getServiceKindFromParam(to.params.careType)

  if (serviceKind && to.params.mdmId) {
    const serviceSlug = await getServiceByMdmidAndServiceKind(to.params.mdmId, serviceKind)

    if (serviceSlug) {
      next({ name: ROUTES.locationDetail, params: { serviceSlug } })
      return
    }
  }
  next({ name: ROUTES.notFound })
}

async function getServiceByMdmidAndServiceKind (mdmId : string, serviceKind : ServiceKind) : Promise<string> {
  const OfferService = container.get<IOfferService>(SERVICE_IDENTIFIERS.IOfferService)
  return await OfferService.getServiceSlugByMdmidAndServiceKind({ mdmId, serviceKind: serviceKind.toString() })
}

// KF-3844 TODO: remove temporaryValue logic and use function parameter value after english translations have been added
function getServiceKindFromParam (value: string) : ServiceKind | undefined {
  let temporaryValue = value
  if (value === 'daycare') { temporaryValue = 'kinderdagverblijf' }
  if (value === 'after-school-care') { temporaryValue = 'bso' }

  const serviceKindDayCareText = i18n.t('pageUrls.serviceKindDayCareSuffix').toString()
  const serviceKindSchoolCareText = i18n.t('pageUrls.serviceKindSchoolCareSuffix').toString()

  switch (temporaryValue) {
  case serviceKindDayCareText: return ServiceKind.DayCare
  case serviceKindSchoolCareText: return ServiceKind.SchoolCare
  default: return undefined
  }
}

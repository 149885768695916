import Vue from 'vue'

const euroFilter = function (value?: number | string): string {
  value = isNaN(Number(value)) ? 0 : Number(value)
  const formatter = new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' })
  return formatter.format(value)
}

Vue.filter('euro', euroFilter)
export default euroFilter

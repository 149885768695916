import IState from '@/store/IState'
import moment from 'moment'
import { GetterTree } from 'vuex'
import IContactDetailsState from './IContactDetailsState'

export const GETTERS = {
  isFormStateSpecified: 'isFormStateSpecified'
}

export function getters (): GetterTree<IContactDetailsState, IState> {
  return {
    isFormStateSpecified: (state) => {
      const formState = state.formState
      const validCareTaker = formState.careTakers.length > 0 &&
      (!!formState.careTakers[0].firstName &&
        !!formState.careTakers[0].lastName &&
        !!formState.careTakers[0].dateOfBirth &&
        !!formState.careTakers[0].bsn &&
        !!formState.careTakers[0].postalCode &&
        !!formState.careTakers[0].houseNumber &&
        !!formState.careTakers[0].street &&
        !!formState.careTakers[0].locality &&
        !!formState.careTakers[0].emailAddress &&
        !!formState.careTakers[0].phoneNumber)

      const validCareTaker2 = formState.careTakers.length < 2 ||
        (!!formState.careTakers[1].firstName &&
          !!formState.careTakers[1].lastName &&
          !!formState.careTakers[1].bsn &&
          !!formState.careTakers[1].postalCode &&
          !!formState.careTakers[1].dateOfBirth &&
          !!formState.careTakers[1].houseNumber &&
          !!formState.careTakers[1].street &&
          !!formState.careTakers[1].locality &&
          !!formState.careTakers[1].emailAddress &&
          !!formState.careTakers[1].phoneNumber)

      const requiredBeforeDate = moment().add(-4, 'weeks')
      const dateOfBirth = moment(state.formState.child.dateOfBirth)
      let validChild = false

      if (dateOfBirth <= requiredBeforeDate) {
        validChild = !!formState.child.dateOfBirth &&
          !!formState.child.firstName &&
          !!formState.child.lastName &&
          !!formState.child.gender &&
          !!formState.child.bsn
      } else {
        validChild = !!formState.child.dateOfBirth &&
          !!formState.child.firstName &&
          !!formState.child.lastName &&
          !!formState.child.gender
      }

      return validChild && validCareTaker && validCareTaker2
    }
  }
}

import HttpResponseError from '@/Errors/HttpResponseError'
import { eventBus } from '@/EventBus'
import { InMemoryCache } from 'apollo-cache-inmemory'
import ApolloClient, { DefaultOptions } from 'apollo-client'
import { ApolloLink, concat, FetchResult, Observable, split } from 'apollo-link'
import { HttpLink } from 'apollo-link-http'
import VueApollo, { ApolloProvider } from 'vue-apollo'
import { StatusCodes } from 'http-status-codes'
import IOperationContext from './OperationContext'
import { WebSocketLink } from 'apollo-link-ws'

export interface HasuraOptions {
  httpUrl: string
  websocketUrl: string
  headers?: any /*eslint-disable-line*/// because of any type: headers should be any, see HttpLink.Options
  httpMiddleware?: ApolloLink[]
  websocketMiddleware?: ApolloLink[]
}

export class HasuraService {
  apollo!: ApolloClient<any> /*eslint-disable-line*/// because of any type: type doesn't really matter yet, and this keeps it flexible

  init (hasuraOptions: HasuraOptions, apolloOptions?: DefaultOptions, override = false): void {
    if (this.apollo && !override) {
      console.warn('An Apollo instance has already been set')
      return
    }

    this.apollo = this.createApolloClient(hasuraOptions, apolloOptions)
  }

  createHttpLink (options: HasuraOptions): ApolloLink {
    const httpOptions: HttpLink.Options = {
      uri: options.httpUrl,
      fetch,
      headers: options.headers ?? {}
    }

    return new HttpLink(httpOptions)
  }

  createWebsocketLink (options: HasuraOptions): ApolloLink {
    const httpOptions = {
      uri: options.websocketUrl,
      options: {
        reconnect: true,
        connectionParams: {
          headers: options.headers ?? {}
        }
      }
    }

    return new WebSocketLink(httpOptions)
  }

  createCombinedHttpWebsocketLink (httpLink : ApolloLink, websocketLink : ApolloLink) : ApolloLink {
    return split(
      ({ query }) => {
        const kind = query.definitions[0]?.kind
        const operation = (query.definitions[0] as any)?.operation // eslint-disable-line @typescript-eslint/no-explicit-any
        return kind === 'OperationDefinition' && operation === 'subscription'
      },
      websocketLink,
      httpLink
    )
  }

  handleErrorCodes (errorCodes: { code: any, message: any}[], operationContext: IOperationContext) : void { // eslint-disable-line @typescript-eslint/no-explicit-any
    if (errorCodes.length > 0) {
      if (errorCodes.some(x => x.code === StatusCodes.INTERNAL_SERVER_ERROR || x.code === 'unexpected')) {
        eventBus.$emit('globalError')
      } else {
        const statusCode = Number(errorCodes[0].code) as StatusCodes

        if (operationContext?.expectedErrorCodes && operationContext.expectedErrorCodes.some(x => x === statusCode)) {
          // If the received code is expected, it should be handled outside this scope
          throw new HttpResponseError(statusCode, errorCodes[0].message)
        } else {
          eventBus.$emit('globalError')
        }
      }
    }
  }

  addHttpErrorHandling () : ApolloLink {
    return new ApolloLink((operation, forward) => {
      return forward(operation).map((response) => {
        if (response.errors) {
          const errorCodes = response.errors
            .flatMap(x => { return { code: x.extensions?.code, message: x.message } })
            .filter(x => x.code)
          const operationContext = operation.getContext() as IOperationContext

          this.handleErrorCodes(errorCodes, operationContext)
        }
        return response
      })
    })
  }

  addMiddleware (link: ApolloLink, middleware?: ApolloLink[]): ApolloLink {
    if (!middleware) {
      return link
    }

    // Add middleware
    if (middleware) {
      // Reverse list to make middleware more user friendly: first one in list is first one called
      middleware.reverse()
      middleware.forEach(m => {
        link = concat(m, link)
      })
    }

    return link
  }

  getNamedLink (url: string) : ApolloLink {
    return new ApolloLink((operation, forward) => {
      operation.setContext(() => ({
        uri: `${url}?${operation.operationName}`
      })
      )
      return forward ? forward(operation) : null
    })
  }

  createApolloClient(hasuraOptions: HasuraOptions, apolloOptions?: DefaultOptions): ApolloClient<any> {  /*eslint-disable-line*/// because of any type: type doesn't really matter yet, and this keeps it flexible
    const httpLink = this.createHttpLink(hasuraOptions)
    const websocketLink = this.createWebsocketLink(hasuraOptions)
    const link = this.createCombinedHttpWebsocketLink(
      ApolloLink.from([this.getNamedLink(hasuraOptions.httpUrl), this.addHttpErrorHandling(), this.addMiddleware(httpLink, hasuraOptions.httpMiddleware)]),
      ApolloLink.from([this.getNamedLink(hasuraOptions.websocketUrl), this.addMiddleware(websocketLink, hasuraOptions.websocketMiddleware)])
    )

    return new ApolloClient({
      link,
      cache: new InMemoryCache(),
      defaultOptions: apolloOptions
    })
  }

  getProvider(client?: ApolloClient<any>): ApolloProvider {  /*eslint-disable-line*/// because of any type: type doesn't really matter yet, and this keeps it flexible
    client = client ?? this.apollo
    return new VueApollo({
      defaultClient: client
    })
  }
}

const hasuraService = new HasuraService()
export default hasuraService

export function toPromise (observable: Observable<FetchResult<any, Record<string, any>, Record<string, any>>>, context?: IOperationContext): Promise<FetchResult<any, Record<string, any>, Record<string, any>>> { // eslint-disable-line @typescript-eslint/no-explicit-any
  let completed = false
  return new Promise<FetchResult<any, Record<string, any>, Record<string, any>>>((resolve, reject) => { // eslint-disable-line @typescript-eslint/no-explicit-any
    observable.subscribe({
      next: response => {
        const dataValues = Object.values(response.data) as any[] // eslint-disable-line @typescript-eslint/no-explicit-any
        if (!dataValues || dataValues.length <= 0) {
          return
        }

        const data = dataValues[0]
        if (completed) {
          console.warn('Promise Wrapper does not support multiple results from Observable')
        } else if (data.output) {
          completed = true
          resolve(response)
        } else if (data.errors || response.errors) {
          completed = true
          const errorCodes = getWebsocketErrorResponseErrorCodes(response)
          handleWebsocketErrorResponseErrorCodes(reject, response, errorCodes, context)
        }
      },
      error: e => {
        eventBus.$emit('globalError')
        reject(e)
      }
    }, e => {
      eventBus.$emit('globalError')
      reject(e)
    })
  })
}

function getWebsocketErrorResponseErrorCodes (response: FetchResult<any, Record<string, any>, Record<string, any>>) : { code: any, message: any}[] { // eslint-disable-line @typescript-eslint/no-explicit-any
  if (response.errors) {
    const errorCodes = response.errors
      .flatMap((x: any) => { return { code: x.extensions?.code, message: x.extensions?.message } }) // eslint-disable-line @typescript-eslint/no-explicit-any
      .filter((x: any) => x.code) // eslint-disable-line @typescript-eslint/no-explicit-any
    return errorCodes
  }

  const dataValues = Object.values(response.data) as any[] // eslint-disable-line @typescript-eslint/no-explicit-any
  if (dataValues && dataValues.length > 0) {
    const errors = dataValues[0].errors
    if (errors) {
      const errorCode = { code: errors.code, message: errors.error }
      return [errorCode]
    }
  }

  return []
}

function handleWebsocketErrorResponseErrorCodes (reject: (reason: any) => void, response: FetchResult<any, Record<string, any>, Record<string, any>>, errorCodes: { code: any, message: any}[], context?: IOperationContext) { // eslint-disable-line @typescript-eslint/no-explicit-any
  if (context?.expectedErrorCodes && errorCodes.length > 0) {
    const expectedErrors = errorCodes.filter(error => {
      const statusCode = Number(error.code) as StatusCodes
      return context.expectedErrorCodes.some(x => x === statusCode)
    })

    if (expectedErrors.length > 0) {
      const error = errorCodes[0]
      // If the received code is expected, it should be handled outside this scope
      reject(new HttpResponseError(error.code, error.message))
      return
    }
  }

  eventBus.$emit('globalError')
  reject(response)
}

enum SubscriptionCode
{
    AllWeeks,
    WorkingWeeks,
    SchoolWeeks,
    Divergent,
    Unknown
}

export default SubscriptionCode

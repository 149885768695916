
import { Component, Prop, Vue } from 'vue-property-decorator'

export type PartouStepperStep = {
  translationId: string;
  backgroundIconString: string;
  stepLabel: string;
  isCompletedIconString: string;
};

@Component({
  components: {}
})
export default class PartouStepper extends Vue {
  @Prop({ required: true })
  steps!: PartouStepperStep[]

  @Prop({ required: true })
  currentStep!: number

  @Prop({ required: false, default: false })
  isVertical!: boolean

  @Prop({ required: false, default: true })
  showStepLabels!: boolean
}

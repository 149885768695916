
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: { }
})
export default class PartouShieldImage extends Vue {
  @Prop({ required: true })
  imageSrc!: string
}

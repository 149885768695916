import { ServiceVarietyName } from '@/models'
import { GetBenefitsInput, GetCareHoursPerMonthInput, GetDeclarableHourlyRateInput, GetDeclarableHoursInput, GetMaximumHourlyRateInput, GetRemainingDeclarableCareHoursInput } from './ICalculatorGetterInputs'
import ICalculatorState from './ICalculatorState'

export class CalculatorGetterFunctions {
  static DAYS_PER_WEEK = 7
  static DAYS_PER_YEAR = CalculatorGetterFunctions.DAYS_PER_WEEK * 52 // 364 (Note: this is how KF does it as well)
  static DAYS_PER_MONTH = CalculatorGetterFunctions.DAYS_PER_YEAR / 12

  getMaximumHourlyRate = (state: ICalculatorState) => ({ serviceVarietyNames }: GetMaximumHourlyRateInput): number => {
    const maximumCompensatedHourlyRate = serviceVarietyNames.includes(ServiceVarietyName.KDV)
      ? state.constants.maximumCompensatedHourlyRateKDV
      : state.constants.maximumCompensatedHourlyRateBSO

    return maximumCompensatedHourlyRate ?? Number.MAX_SAFE_INTEGER
  }

  /**
   * Stap 1: bepaal de uurprijs
   * Voor kinderopvangtoeslag geldt een maximumuurtarief.
   *    - voor dagopvang bij een kindercentrum: €10,25 per uur (extra verhoogd in 2024), voorheen €9.65 per uur in 2023
   *    - voor buitenschoolse opvang (bso) bij een kindercentrum: €9,12 per uur (extra verhoogd in 2024), voorheen €7,79 per uur (in 2023)
   *
   * Is de opvang goedkoper? Ga dan uit van dat goedkopere uurtarief.
   * Is de opvang duurder? Ga dan uit van het maximumuurtarief.
   */
  getDeclarableHourlyRate = (state: ICalculatorState) => ({ offerHourlyRate, serviceVarietyNames }: GetDeclarableHourlyRateInput): number => {
    const maximumCompensatedHourlyRate = this.getMaximumHourlyRate(state)({ serviceVarietyNames })
    return Math.min(offerHourlyRate, maximumCompensatedHourlyRate)
  }

  /**
   * Stap 2: bereken de opvanguren waarvoor uw klant kinderopvangtoeslag kan krijgen
   *
   * Werkende ouders
   * Werken beide ouders? Dan gaan we uit van het aantal gewerkte uren van de ouder die het minst werkt.
   * Gaat het kind naar de dagopvang? Vermenigvuldig de gewerkte uren met 140%. Gaat het kind naar de
   * buitenschoolse opvang? Vermenigvuldig de gewerkte uren met 70%. Gaat het kind minder uren naar de
   * opvang? Neem dan de werkelijke uren. Een werkende ouder kan per kind voor maximaal 230 uur per maand
   * kinderopvangtoeslag krijgen.
   *
   * Ouders die een traject naar werk, studie of inburgeringscursus volgen
   * Volgen beide ouders een traject naar werk, studie of inburgeringscursus bij een gecertificeerde instelling?
   * Dan kunnen ze per kind voor maximaal 230 uur per maand kinderopvangtoeslag krijgen voor de duur van
   * het traject. Dit geldt ook als ze daarnaast werken. Werkt een van beide ouders? Dan gaan we uit van de
   * ouder die de minste uren opvang nodig heeft. Vermenigvuldig die uren met 140% voor dagopvang en 70%
   * voor buitenschoolse opvang.
   */
  getDeclarableCareHoursPerMonth = (state: ICalculatorState) => ({ propositionSubscription, withWaitingListHours }: GetDeclarableHoursInput): number => {
    const careHoursPerMonth = this.getCareHoursPerMonth()({ propositionSubscription, withWaitingListHours })
    return Math.ceil(Math.min(careHoursPerMonth, state.constants.maximumDeclarableHoursPerMonths)) // Gaat het kind minder uren naar de opvang? Neem dan de werkelijke uren.
  }

  /**
   * Stap 3: bereken het gezamenlijke toetsingsinkomen
   */
  getCumulativeIncomePerYear = (state: ICalculatorState) => (): number => {
    return Number(state.formState.cumulativeIncomePerYear) || 0
  }

  /**
   * Stap 4: bereken de kinderopvangtoeslag voor het 1e kind
   *
   * De hoogte van de kinderopvangtoeslag is afhankelijk van het (gezamenlijke) toetsingsinkomen.
   * In de tabel ziet u hoeveel procent van de opvangkosten vergoed wordt.
   * Opvangkosten 1e kind x percentage 1e kind = kinderopvangtoeslag 1e kind
   */
  getCompensatedPercentage = (state: ICalculatorState) => (): number => {
    const cumulativeIncomePerYear = this.getCumulativeIncomePerYear(state)()

    // Use lookup table to get the corresponding benpercentage
    const matchingcompensatedPercentageTreshold = state.constants.compensatedPercentageTresholds.find(x =>
      (x.maximumCumulativeIncomeThreshold == null || cumulativeIncomePerYear <= x.maximumCumulativeIncomeThreshold) &&
      (x.minimumCumulativeIncomeThreshold == null || cumulativeIncomePerYear >= x.minimumCumulativeIncomeThreshold)
    )

    const compensatedPercentage = matchingcompensatedPercentageTreshold?.compensatedPercentage || 0
    return compensatedPercentage / 100
  }

  getBenefitsPerMonth = (state: ICalculatorState) => ({ offerHourlyRate, serviceVarietyNames, propositionSubscription, withWaitingListHours }: GetBenefitsInput): number => {
    const declarableCareHoursPerMonth = this.getDeclarableCareHoursPerMonth(state)({ serviceVarietyNames, propositionSubscription, withWaitingListHours })
    const declarableHourlyRate = this.getDeclarableHourlyRate(state)({ offerHourlyRate, serviceVarietyNames })
    const declarableCostsPerMonth = declarableCareHoursPerMonth * declarableHourlyRate
    const compensatedPercentage = this.getCompensatedPercentage(state)()

    return Math.floor(declarableCostsPerMonth * compensatedPercentage)
  }

  getBenefitsPerHour = (state: ICalculatorState) => ({ offerHourlyRate, serviceVarietyNames }: GetBenefitsInput): number => {
    // Note: benefits per hour should not be determined from the benefits per month, since they are not dependent on the getDeclarableCareHoursPerMonth
    const declarableHourlyRate = this.getDeclarableHourlyRate(state)({ offerHourlyRate, serviceVarietyNames })
    const compensatedPercentage = this.getCompensatedPercentage(state)()

    return declarableHourlyRate * compensatedPercentage
  }

  getOwnContributionPerMonth = (state: ICalculatorState) => ({ offerHourlyRate, serviceVarietyNames, propositionSubscription, withWaitingListHours }: GetBenefitsInput): number => {
    const costsPerMonth = propositionSubscription.priceOfFirstFullMonth
    const benefitsPerMonth = this.getBenefitsPerMonth(state)({ offerHourlyRate, serviceVarietyNames, propositionSubscription, withWaitingListHours })

    return Math.max(costsPerMonth - benefitsPerMonth, 0)
  }

  getOwnContributionPerHour = (state: ICalculatorState) => ({ offerHourlyRate, serviceVarietyNames, propositionSubscription, withWaitingListHours }: GetBenefitsInput): number => {
    // Note: since benefitsPerHour is not dependent on benefitsPerMonth, ownContributionPerHour has to be calculated seperatly as well
    const costsPerHour = offerHourlyRate
    const benefitsPerHour = this.getBenefitsPerHour(state)({ offerHourlyRate, serviceVarietyNames, propositionSubscription, withWaitingListHours })

    return Math.max(costsPerHour - benefitsPerHour, 0)
  }

  getRemainingDeclarableCareHoursPerMonth = (state: ICalculatorState) => ({ propositionSubscription, withWaitingListHours }: GetRemainingDeclarableCareHoursInput): number => {
    const careHoursPerMonth = this.getCareHoursPerMonth()({ propositionSubscription, withWaitingListHours })
    const declarableCareHoursBasedOnWorkingHoursPerMonth = state.constants.maximumDeclarableHoursPerMonths

    return Math.floor(declarableCareHoursBasedOnWorkingHoursPerMonth - careHoursPerMonth)
  }

  getCareHoursPerMonth = () => ({ propositionSubscription, withWaitingListHours } : GetCareHoursPerMonthInput) : number => {
    return withWaitingListHours ? Math.ceil(propositionSubscription.averageAvailableHoursPerMonth + propositionSubscription.averageWaitingListHoursPerMonth) : Math.ceil(propositionSubscription.averageAvailableHoursPerMonth)
  }
}

import IUserSelectionFormState from './IUserSelectionFormState'

export const STATE = {
  formState: 'formState',
  currentStep: 'currentStep',
  formIsValid: 'formIsValid'
}

export default interface IUserSelectionState {
  formState: IUserSelectionFormState,
  currentStep: number,
  formIsValid: boolean
}

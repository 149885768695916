import IFeatureState, { STATE } from './IFeatureState'
import Vue from 'vue'
import { MutationTree } from 'vuex'
import { Feature } from '@/models'

export const MUTATIONS = {
  setDefaultFeatures: 'setDefaultFeatures'
}

export function mutations (): MutationTree<IFeatureState> {
  return {
    setDefaultFeatures (state: IFeatureState, defaultFeatures : Feature[]) {
      Vue.set(state, STATE.defaultFeatures, defaultFeatures)
    }
  }
}

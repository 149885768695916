
import { Component, Prop, Vue, VModel } from 'vue-property-decorator'

@Component
export default class PartouRadio extends Vue {
  @VModel({ required: false, default: null })
  selectedItem!: unknown

  @Prop({ required: true })
  items!: Array<Record<string, unknown>>

  @Prop({ required: true })
  itemLabel!: string | ((obj: Record<string, unknown>) => string)

  @Prop({ required: true })
  itemValue!: string | ((obj: Record<string, unknown>) => unknown)

  getLabel (item: Record<string, string>) : string {
    if (typeof (this.itemLabel) === 'string') {
      return item[this.itemLabel]
    } else {
      return this.itemLabel(item)
    }
  }

  getValue (item: Record<string, unknown>) : unknown {
    if (typeof (this.itemValue) === 'string') {
      return item[this.itemValue]
    } else {
      return this.itemValue(item)
    }
  }
}

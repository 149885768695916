
import { Component, Vue, Prop } from 'vue-property-decorator'
import PartouNotify from '@/components/PartouComponents/PartouNotify.vue'
import ProductTimeSelect from '../ProductTimeSelect/ProductTimeSelect.vue'
import { ProductCategoryViewData } from '@/services/ProductService/ProductCategoryViewData'
import { ProductViewData } from '@/services/ProductService/ProductViewData'
import { GETTERS, NAMESPACES } from '@/store'
import { namespace } from 'vuex-class'
import LinkToPartouOffers from '../LinkToPartouOffers/LinkToPartouOffers.vue'
import { ServiceVarietyName } from '@/models'

const userSelectionModule = namespace(NAMESPACES.userSelection)

@Component({
  components: { PartouNotify, ProductTimeSelect, LinkToPartouOffers }
})
export default class ProductCategorySelect extends Vue {
  @userSelectionModule.Getter(GETTERS.userSelection.getSelectedProductIds)
  getSelectedProductIds!: () => string[]

  @userSelectionModule.Getter(GETTERS.userSelection.getSelectedServiceVarieties)
  getSelectedServiceVarieties!: () => string[]

  @Prop({ required: true, default: () => [] })
  productCategories!: ProductCategoryViewData[]

  @Prop({ required: false, default: true })
  showPricing!: boolean

  @Prop({ required: false, default: false })
  showLinkToPartouOffers!: boolean

  @Prop({ required: false, default: false })
  displayVSOProducts!: boolean

  showFeatureInfo = false
  featureTitle = ''
  featureDescription = ''
  selectedCategory: string | undefined = ''
  uniqueCategories: (string | undefined)[] = []
  selectableProducts: ProductViewData[] = []

  showFromPricing (selectedCategory : string) : boolean {
    const category = this.productCategories.filter(c => c.name === selectedCategory)[0]
    if (this.getSelectedServiceVarieties().indexOf(ServiceVarietyName.KDV) !== -1) {
      if (category.products.length > 1) {
        return true
      }
    } else if (category.products.filter(p => p.serviceVariety === ServiceVarietyName.NSO).length > 1 || category.products.filter(p => p.serviceVariety === ServiceVarietyName.VSO).length > 1) {
      return true
    }
    return false
  }

  mounted () {
    const ids = this.getSelectedProductIds()
    const category = this.productCategories.find(cat =>
      cat.products.some(product => ids.indexOf(product.id) !== -1 && product.serviceVariety !== ServiceVarietyName.VSO)
    )

    if (category) {
      this.selectedCategory = category.name
      this.selectableProducts = category.products
    } else {
      this.selectedCategory = this.productCategories[0].name
      this.selectableProducts = this.productCategories[0].products
    }
  }

  isCheapestCategory (index: number): boolean {
    const cheapestPrice = this.getCheapestHourRate(index)
    return this.productCategories.every((cat, i) =>
      i === index || this.getCheapestHourRate(i) >= cheapestPrice
    )
  }

  formatHourRate (hourRate : number) : string {
    return `€ ${(hourRate / 100).toFixed(2).replace('.', ',')}`
  }

  async onFeatureInfoCancelled () : Promise<void> {
    this.showFeatureInfo = false
    await new Promise(resolve => setTimeout(resolve, 200))
    this.featureTitle = ''
    this.featureDescription = ''
  }

  onFeatureInfoClicked (title : string, description: string) : void {
    this.featureTitle = title
    this.featureDescription = description
    this.showFeatureInfo = true
  }

  onCategorySelected (index : number) {
    this.selectedCategory = this.productCategories[index].name
    this.selectableProducts = this.productCategories[index].products.sort((a, b) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime())
  }

  getCheapestHourRate (index : number): number {
    const smallestPricePerHourProduct = this.productCategories[index].products.reduce((lowest, product) => {
      return (lowest.pricePerHour < product.pricePerHour) ? lowest : product
    })
    return smallestPricePerHourProduct.pricePerHour
  }
}

import IConfigurationState, { STATE } from './IConfigurationState'
import Vue from 'vue'
import { MutationTree } from 'vuex'
import PlanSettings from '@/models/configuration/PlanSettings'

export const MUTATIONS = {
  setConfiguration: 'setConfiguration',
  setPlanSettings: 'setPlanSettings'
}

export function mutations (): MutationTree<IConfigurationState> {
  return {
    setConfiguration (state: IConfigurationState, configuration : Record<string, any>) { // eslint-disable-line @typescript-eslint/no-explicit-any
      Vue.set(state, STATE.configuration, configuration)
    },
    setPlanSettings (state: IConfigurationState, planSettings: PlanSettings) {
      Vue.set(state, STATE.planSettings, planSettings)
    }
  }
}


import { Component, Prop, Vue } from 'vue-property-decorator'
import PartouStepper from '@/components/PartouComponents/PartouStepper.vue'
import EmailVerificationContent from './StepperContent/EmailVerificationContent.vue'
import ContractSignContent from './StepperContent/ContractSignContent.vue'
import MandateSignContent from './StepperContent/MandateSignContent.vue'
import ReservationBookingCompletedContent from './StepperContent/ReservationBookingCompletedContent.vue'
import WaitingListBookingCompletedContent from './StepperContent/WaitingListBookingCompletedContent.vue'
import ReservationBookingContent from './ReservationBookingContent.vue'
import WaitingListBookingContent from './WaitingListBookingContent.vue'
import BookingStatusErrorContent from './StepperContent/BookingStatusErrorContent.vue'
import { GetBookingStatusOutput } from '@/models'
import IBookingService from '@/services/BookingService/IBookingService'
import container, { SERVICE_IDENTIFIERS } from '@/init/container'
import IEmailService from '@/services/EmailService/IEmailService'
import { NAMESPACES, STATE, ACTIONS } from '@/store'
import { namespace } from 'vuex-class'
import IContactDetailsFormState from '@/store/modules/contactDetails/IContactDetailsFormState'
import PartouLoader from '@/components/PartouComponents/PartouLoader.vue'
import { ROUTES } from '@/router/routes'
import BookingStatusError from '@/pages/BookingFlow/StepperContent/BookingStatusError'
import i18n from '@/plugins/i18n'
import Page from '../Page'

const contactDetailsModule = namespace(NAMESPACES.contactDetails)

@Component({
  components: { WaitingListBookingContent, ReservationBookingContent, WaitingListBookingCompletedContent, PartouStepper, ContractSignContent, ReservationBookingCompletedContent, MandateSignContent, EmailVerificationContent, BookingStatusErrorContent, PartouLoader },
  metaInfo () {
    return {
      title: (this as any).pageTitle, // eslint-disable-line @typescript-eslint/no-explicit-any
      meta: [
        { property: 'title', content: (this as any).pageTitle }, // eslint-disable-line @typescript-eslint/no-explicit-any
        { name: 'robots', content: 'noindex, nofollow' }
      ]
    }
  }
})

export default class BookingStatusPage extends Vue implements Page {
  pageTitle = i18n.t('pageTitles.bookingStatus').toString()

  @Prop({ required: true })
  bookingHash!: string

  @Prop({ required: false, default: 'false' })
  isSigned?: string

  isLoading = true
  bookingStatusError : BookingStatusError = BookingStatusError.none
  BookingStatusError = BookingStatusError
  bookingService! : IBookingService
  currentBookingStatus? : GetBookingStatusOutput
  emailService! : IEmailService

  @contactDetailsModule.State(STATE.contactDetails.formState)
  contactDetailsFormState!: IContactDetailsFormState

  @contactDetailsModule.Action(ACTIONS.contactDetails.setEmail)
  setEmailAsync!: (email :string) => Promise<void>

  created () : void {
    this.emailService = container.get<IEmailService>(SERVICE_IDENTIFIERS.IEmailService)
    this.bookingService = container.get<IBookingService>(SERVICE_IDENTIFIERS.IBookingService)
    this.fetchBookingAsync()
  }

  async fetchBookingAsync () : Promise<void> {
    this.currentBookingStatus = await this.bookingService.getBookingStatusByHashAsync(this.isSigned === 'true', { hash: this.bookingHash })
    if (this.currentBookingStatus && !this.currentBookingStatus.isCancelled && !this.currentBookingStatus.isError && !this.currentBookingStatus.isContractDeclined) {
      if (this.currentBookingStatus.emailAddress) {
        this.setEmailAsync(this.currentBookingStatus.emailAddress)
      }
    } else {
      this.bookingStatusError = this.currentBookingStatus.isContractDeclined ? BookingStatusError.contractDeclined : BookingStatusError.cancelled
    }
    Vue.set(this, 'isLoading', false)
  }

  async onResendEmailClicked () : Promise<void> {
    if (!await this.emailService.resendEmailConfirmation({ bookingUrlHash: this.bookingHash })) {
      await this.fetchBookingAsync()
    }
  }

  onNavigateBackToHomeClicked () : void {
    this.$router.push({ name: ROUTES.selectionGuide })
  }
}

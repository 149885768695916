
import { Component, Vue } from 'vue-property-decorator'
import PartouButton from '@/components/PartouComponents/Buttons/PartouButton.vue'

@Component({
  components: { PartouButton }
})
export default class MandateSignContent extends Vue {
  OnStartSignClicked () : void {
    this.$emit('onStartSignClicked')
  }
}


import { Vue, Component, Prop, Emit } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { NAMESPACES, STATE } from '@/store'
import SchoolLocalitySelector from '@/components/SchoolSelection/SchoolLocalitySelector.vue'
import SchoolSelector from '@/components/SchoolSelection/SchoolSelector.vue'
import SchoolGroupSelector from '@/components/SchoolSelection/SchoolGroupSelector.vue'
import PartouNotify from '@/components/PartouComponents/PartouNotify.vue'
import PartouSocials from '@/components/PartouComponents/PartouSocials.vue'
import PartouButton from '@/components/PartouComponents/Buttons/PartouButton.vue'
import '@/utils/string.extensions'

const configurationModule = namespace(NAMESPACES.configuration)

@Component({
  components: { SchoolLocalitySelector, SchoolSelector, SchoolGroupSelector, PartouNotify, PartouSocials, PartouButton }
})
export default class SchoolSelectionForm extends Vue {
  @configurationModule.State(STATE.configuration.configuration)
  configuration! : Record<string, any> // eslint-disable-line @typescript-eslint/no-explicit-any

  @Prop({ required: false, default: 306 })
  popUpWidth! : number

  @Emit('onBackToLocationOverviewClicked')
  onBackToLocationOverviewClicked () : void {
    // emits onBackToLocationOverviewClicked event
  }

  showSchoolInformationHint = false
  showSchoolNotAvailableHint = false
  showSchoolNotAvailableForCurrentLocationHint = false
  showInvalidSchoolSelectedUsingChildBenefitCalculator = false

  toggleShowInvalidSchoolSelectedUsingChildBenefitCalculatorHint () : void {
    this.showInvalidSchoolSelectedUsingChildBenefitCalculator = !this.showInvalidSchoolSelectedUsingChildBenefitCalculator
  }

  toggleShowSchoolNotAvailableForCurrentLocationHint () : void {
    this.showSchoolNotAvailableForCurrentLocationHint = !this.showSchoolNotAvailableForCurrentLocationHint
  }

  toggleShowSchoolNotAvailableHint () : void {
    this.showSchoolNotAvailableHint = !this.showSchoolNotAvailableHint
  }

  onInfoClicked () : void {
    this.showSchoolInformationHint = true
  }
}

